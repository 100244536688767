import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';
import { FilterHelper } from '../helpers/FilterHelper';
import * as _ from 'lodash';
import { NavigationHelper } from '../helpers/NavigationHelper';

// Network API - contains method to fetch users and their types from the server
const usersNetworkAPI = NetworkManager.getUsersNetworkAPI();

// Asynchrounous methods waiting for HTTP request to complete and then update Store data
export const fetchDistributors = createAsyncThunk(
    'distributors/fetchDistributors',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingDistributorsStatus(true));
       
        const distributorsData = await usersNetworkAPI.fetchDistributors();

        dispatch(updateFetchedDistributors(distributorsData));
        dispatch(updateLoadingDistributorsStatus(false));
    }
);

// Filters distributors  records by text
export const filterDistributorsByPhrase = createAsyncThunk(
    'distributors/filterDistributorsByPhrase',
    async (phrase: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { distributors } = thunkAPI.getState() as any;

        const filteredDistributors = FilterHelper.filterRecordsUsingPhrase(phrase, distributors.fetchedDistributors,
            ["DistributorNo", "Name"]);
        dispatch(updateShownDistributors(filteredDistributors));
    }
);

export const createNewDistributor = createAsyncThunk(
    'distributors/createNewDistributor',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateEditedDistributor({Name: "", WelcomeText: "", IntegratorNo: null, DistributorLogo: null}));
        dispatch(fetchRelatedIntegratorsToDistributor());
        NavigationHelper.toAppUrl("/distributors/edit/")
    }
);

export const editDistributor = createAsyncThunk(
    'distributors/editDistributor',
    async (id: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { distributors } = thunkAPI.getState() as any;
        const fetchedDistributors: any[] = distributors.fetchedDistributors;

        // Find edited Sound DataRow
        let editedDistributor = fetchedDistributors.find(endC => endC.DistributorNo === id);
        if(editedDistributor === undefined)
            throw new Error("editedDistributor to edit was not found in fetched sounds.");

        editedDistributor = _.cloneDeep(editedDistributor);
        if(editedDistributor.DistributorLogo === "") {
            editedDistributor.DistributorLogo = null;
        }
       
        // Update edited sound data to the store
        dispatch(updateEditedDistributor(editedDistributor));
        dispatch(fetchRelatedIntegratorsToDistributor());

        // Finally navigate to Edit Form
        NavigationHelper.toAppUrl(`/distributors/edit/`);       
    }
);

export const fetchRelatedIntegratorsToDistributor = createAsyncThunk(
    'distributors/fetchRelatedIntegratorsToDistributor',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const fetchedIntegrators = await usersNetworkAPI.fetchIntegratorsAssociatedToDistributor();
        dispatch(updateRelatedIntegrators(fetchedIntegrators));
    }
);

export const saveDistributor = createAsyncThunk(
    'distributors/saveDistributor',
    async (distributorData: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingDistributorsStatus(true));       
        try {
            distributorData = _.cloneDeep(distributorData);
            if(distributorData.DistributorLogo instanceof File) {
                distributorData.DistributorLogo = await usersNetworkAPI.saveDistributorLogo(distributorData.DistributorLogo);
            } else  {
                if(distributorData.DistributorLogo === null) {
                    if(distributorData.DistributorNo !== undefined) {
                        await usersNetworkAPI.deleteDistributorLogo(distributorData.DistributorNo);
                    }
                    delete distributorData.DistributorLogo;
                } else {
                    delete distributorData.DistributorLogo;
                }
            }
            await usersNetworkAPI.saveDistributor(distributorData);
            NavigationHelper.toAppUrl("/distributors");   
        }
        catch(e) {
            console.dir(e);
        }
        finally {
            dispatch(updateLoadingDistributorsStatus(false));
        }
    }
);

export const deleteDistributor = createAsyncThunk(
    'distributors/deleteDistributor',
    async (distributorNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingDistributorsStatus(true));       
            await usersNetworkAPI.deleteDistributor(distributorNo);
        }
        finally {
            dispatch(updateLoadingDistributorsStatus(false));
        }
        dispatch(fetchDistributors())
    }
);

// Initial Distributors Store State
const INITIAL_STATE = {
    shownDistributors: [],
    fetchedDistributors: [],
    loadingDistributors: false,
    editedDistributor: {},
    relatedIntegrators: []
}

// Redux Slice for Distributors Module
export const distributorsSlice = createSlice({
    name: 'distributors',
    initialState: INITIAL_STATE,
    reducers: {
        updateLoadingDistributorsStatus: (state, action) => {
            state.loadingDistributors = action.payload;
        },
        updateFetchedDistributors: (state, action) => {
            state.fetchedDistributors = action.payload;
            state.shownDistributors = _.clone(action.payload);
        },
        updateShownDistributors: (state, action) => {
            state.shownDistributors = action.payload;
        },
        updateEditedDistributor: (state, action) => {
            state.editedDistributor = action.payload;
        },
        updateRelatedIntegrators: (state, action) => {
            state.relatedIntegrators = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateLoadingDistributorsStatus,
    updateFetchedDistributors,
    updateShownDistributors,
    updateEditedDistributor,
    updateRelatedIntegrators
} = distributorsSlice.actions

export default distributorsSlice.reducer
