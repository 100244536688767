/* eslint-disable @typescript-eslint/no-unused-vars */
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { FormikTextField } from "../../forms/formik";
import { NetworkManager } from "../../network/NetworkManager";

export interface NonEditableSoundOwnerControlProps {}

const soundAPI = NetworkManager.getSoundNetworkAPI();

const NonEditableSoundOwnerControl = (props: NonEditableSoundOwnerControlProps) => {

    const [integratorField, integratorMeta, integratorHelper] = useField("IntegratorOwner");
    const [distributorField, distributorMeta, distributorHelper] = useField("DistributorOwner");
    const [endCustomerField, endCustomerMeta, endCustomerHelper] = useField("EndCustomerOwner");

    const [,, helperIntegratorName] = useField("IntegratorName");
    const [,, helperDistributorName] = useField("DistributorName");
    const [,, helperEndCustomerName] = useField("EndCustomerName");


    const resolveEntityNames = async () => {
        if(typeof integratorField.value === "string" && integratorField.value.trim().length > 0) {
            let integratorName = await soundAPI.getEntityName(integratorField.value, "Integrator");
            helperIntegratorName.setValue(integratorName);
            helperDistributorName.setTouched(true);
        }
        if(typeof distributorField.value === "string" && distributorField.value.trim().length > 0) {
            let distributorName = await soundAPI.getEntityName(distributorField.value, "Distributor");
            helperDistributorName.setValue(distributorName);
        }
        if((typeof endCustomerField.value === "string") && endCustomerField.value.trim().length > 0) {
            let endCustomerName = await soundAPI.getEntityName(endCustomerField.value, "End Customer");
            helperEndCustomerName.setValue(endCustomerName);
        }
    }

    useEffect(() => {
        resolveEntityNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <FormikTextField label="Integrator" name="IntegratorName" disabled  />
            <FormikTextField label="Distributor" name="DistributorName"  disabled  />
            <FormikTextField label="End Customer" name="EndCustomerName"  disabled  />
        </>
    );
}

export default NonEditableSoundOwnerControl;
