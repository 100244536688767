import React from "react"
import { Route, Redirect } from "react-router"
import { CSSTransition } from "react-transition-group";
import { useAuth } from "./Auth";

// Import animation styles
import "./AnimatedRoute.scss";
import AccessDeniedPage from "./AccessDeniedPage";

// Required animation timeout
const SCALE_ANIMATION_TIMEOUT = 350;
const FADE_ANIMATION_TIMEOUT = 250;

// Function for resolving CSS animation class
const getAnimationTypeCssClass = (type: string) => {
    return type === "fade" ? "View-Fade" :  "View-Scale";
}

const getAnimationDuration = (type: string) => {
    return type === "fade" ? FADE_ANIMATION_TIMEOUT :  SCALE_ANIMATION_TIMEOUT;
}

// Component for non-guarded animated routes
export const AnimatedRoute = ({component, type, ...rest}: any) => {
    const Component = component;
    const animatonCssClass = getAnimationTypeCssClass(type);
    const animationDuration = getAnimationDuration(type);

    return (
        <Route  {...rest} children={({ match, ...rest }: any) => {
            return (
                <CSSTransition in={match != null}  timeout={animationDuration} classNames={animatonCssClass} 
                    unmountOnExit appear={true} >
                  <div>
                    <Component />                    
                  </div>
                </CSSTransition>
            );
        }} /> 
    );
}

// Component for guarded animated route
export const GuardedAnimatedRoute = ({component, type, userRoles, ...rest}: any) => {
    const auth: any = useAuth();

    const Component = component;
    const UserRoles = userRoles;
    
    const animatonCssClass = getAnimationTypeCssClass(type);
    const animationDuration = getAnimationDuration(type);

    return (
        <Route exact {...rest} children={({ match, location, history, ...rest }: any) => {
            if(match === null) return null;
            const isLoggedIn = auth.isLoggedIn();
            const isAllowedUserRole = isLoggedIn ? UserRoles.indexOf(auth.getUserRole()) >= 0 : false;
        
            if(isLoggedIn === true && isAllowedUserRole === true) {
                return (
                    <CSSTransition 
                            in={match != null}  
                            timeout={animationDuration} 
                            classNames={animatonCssClass} 
                            appear={true} >
                        <div>
                            { (<Component />) }
                        </div> 
                    </CSSTransition>
                );   
            } else if(isLoggedIn === true && isAllowedUserRole === false) {
                return (
                    <CSSTransition 
                            in={match != null}  
                            timeout={animationDuration} 
                            classNames={animatonCssClass} 
                            unmountOnExit 
                            appear={true} >
                        <div>
                            <AccessDeniedPage />
                        </div> 
                    </CSSTransition>
                );   
            } else {
                return ( <Redirect path="*" to={{ pathname: "/login"}} />);
            }
        }} />
    );
}
