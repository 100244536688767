import { UnitsNetworkHelper } from "./ajax/UnitsNetworkHelper";
import { SoundsNetworkHelper } from "./ajax/SoundsNetworkHelper";
import { UnitGroupsNetworkHelper } from "./ajax/UnitGroupsNetworkHelper";
import { AjaxHelper } from "./ajax/AjaxHelper";
import { UsersNetworkHelper } from "./ajax/UsersNetworkHelper";
import { CupuTypesHelper } from "./ajax/CupuTypesHelper";
import { FirmwareNetworkHelper } from "./ajax/FirmwareNetworkHelper";
import { OrdersNetworkHelper } from "./ajax/OrdersNetworkHelper";

export type UploadSoundAction = "edit" | "upload";

export class NetworkHelper {

    private static unitsHelper = new UnitsNetworkHelper();
    private static soundsHelper = new SoundsNetworkHelper();
    private static unitGroupsHelper = new UnitGroupsNetworkHelper();
    private static usersHelper = new UsersNetworkHelper();
    private static cupuTypesHelper = new CupuTypesHelper();
    private static firmwareHelper = new FirmwareNetworkHelper();
    private static ordersHelper = new OrdersNetworkHelper();

    static get Orders() {
        return this.ordersHelper;
    }

    static get Users() {
        return this.usersHelper;
    }

    static get Units() {
        return this.unitsHelper;
    }

    static get Sounds() {
        return this.soundsHelper;
    }

    static get UnitGroups() {
        return this.unitGroupsHelper;
    }

    static get CupuTypes() {
        return this.cupuTypesHelper;
    }

    static get Firmware() {
        return this.firmwareHelper;
    }

    static convertToFormData(data: any): FormData {
        const formData = new FormData();
        for(let key in data) {
            formData.append(key, data[key]);
        }
        return formData;
    }

    static parseRelatedUsers(response: any) {
        const data = AjaxHelper.parseAjaxResponse(response, ["all_related_to"], "EndCustomerNo");
        return data.map((user: any) => {
            return {
                userNo: parseInt(user.EndCustomerNo),
                userName: user.Name
            }
        });        
    }

    static parseUnitStatisticsResponse(response: any): any[] {
        return [];
    }


    static parseUnitsLogResponse(response: any): any[] {
        return [];
    }

    static parseUnitGroupsResponse(response: any): any[] {
        return [];
    }

    static formatSaveUnitRequest(data: any): any {
        return {...data};
    }

    static formatUpdatePlaylistRequest(data: any): any {
        return {};
    }

    static formatUpdatePlaylistSchedulesRequest(data: any): any {
        return {};
    }

    static formatUpdateUnitsInGroupRequest(data: any): any {
        return {};
    }

    static formatPlaylistForGroupRequest(data: any): any {
        return {};        
    }

    static formatPlaylistSchedulesForGroupRequest(data: any): any {
        return {};        
    }

    static parsePlaylistListResponse(data: any): any[] {
        return [];
    }

    static parsePlaylistSchedulesResponse(data: any): any[] {
        return [];
    }
  
}