import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { confirmDialog } from "primereact/confirmdialog";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../common/AppSearchBox";
import LoadingProgress from "../common/LoadingProgress";
import ActionsDataList, { DataListActions } from "../forms/ActionsDataList";
import { NetworkManager } from "../network/NetworkManager";

import "./SupportPage.scss";
import { deleteManual, fetchManuals, filterManuals, showManualUploadForm } from "./SupportReduxSlice";

const manualsAPI = NetworkManager.getManualsAPI();

const SupportPageHeader = () => {
    const dispatch = useDispatch();
    const handleFilterChanged = (phrase: string) => {
        dispatch(filterManuals(phrase));
    }

    const handleUploadPDF = () => {
        dispatch(showManualUploadForm());
    }

    return (
        <div className="SupportPage--Header">
            <SearchBox placeholder="Search PDF documents..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <div />
            <Button label="Upload PDF" className="p-button-success" 
                onClick={e => handleUploadPDF()}/>
        </div>
    );
}

const PDF_DOCUMENT_ACTIONS: DataListActions = [
    {label: "Download", name: "download", className: "p-button-secondary"},
    {label: "Delete", name: "delete", className: "p-button-danger"}
]

const SupportPageDataTable = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchManuals());
    }, []);

    const confirmDelete = (id: string) => {
        confirmDialog({
            message: 'Are you sure you want to delete this PDF file?',
            header: 'Delete PDF Manual',
            icon: 'pi pi-exclamation-triangle',
            style: {'width': '30rem'},
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            rejectClassName: 'p-button-secondary',
            accept: () => dispatch(deleteManual(id))
        });
    }

    const handleManualDownload = async (filename: string) => {
        let manualContent = await manualsAPI.downloadManual(filename, true);
        const objectUrl = window.URL.createObjectURL(manualContent);
        const domLink = document.createElement("a");
        domLink.href = objectUrl;
        domLink.download = filename;
        domLink.click();
    }  

    const handleActionClick = (data: {name: string, id: any}) => {
        if(data.name === "delete") {
            confirmDelete(data.id);
        } else if(data.name === "download") {
            handleManualDownload(data.id);
        }
    }

    const manuals = useSelector((state: any) => state.support.shownManuals);

    const nameTemplate = (rowData: any) => {

        const handleManualOpen = async (event: React.MouseEvent) => {
            event.preventDefault();
            const manualContent = await manualsAPI.downloadManual(rowData["name"], false);
            const objectURL = URL.createObjectURL(manualContent);
            window.open(objectURL, "_blank");
        }

        return (
            <a href="#/" onClick={handleManualOpen} title="Open document in the new tab."  >{rowData["name"]}</a>
        );
    }

    return (
        <ActionsDataList value={manuals} dataKey="name" actions={PDF_DOCUMENT_ACTIONS} actionColumnWidth="16rem"
                       onAction={handleActionClick} stripedRows  >
            <Column body={nameTemplate} header="Name" field="name" sortable style={{width: "auto", textAlign: "left"}} />
            <Column header="Time of Last Change" field="date" sortable style={{width: "15rem", textAlign: "center"}} />
            <Column header="Size" field="formattedSize" sortable sortField="size" style={{width: "15rem", textAlign: "center"}} />
        </ActionsDataList>
    );
}

const SupportPage = () => {
    const isLoading = useSelector((state: any) => state.support.isLoading);

    return (
        <div className="SupportPage LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <SupportPageHeader />
            <div className="SupportPage--Separator" />
            <SupportPageDataTable />
        </div>
    );
}

export default SupportPage;
