import * as $ from "jquery";
import { object } from "yup";
import { showExceptionDialog } from "../../AppReduxSlice";

export type HTTPVerb = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";
 
export type AjaxDataType = "json" | "text";

export type AJAX_RESPONSE_VERIFICATION = "Verify" | "Bypass";

export interface AjaxRequest {
    type: HTTPVerb,
    dataType: AjaxDataType | undefined,
    url: string,
    data: any,  
    processData: boolean | undefined,
    contentType: string | false | undefined,
    success: ((response: any) => void) | undefined,
    error: ((...data: any[]) => void) | undefined,
    complete: ((...data: any[]) => void) | undefined
}

export abstract class CupuNetworkAPIBase {

    constructor(protected baseUrl: string) {}

    protected subsetRequestProperties(requestData: any, validProperties: string[]) :any {
        let validRequestData: any = {};
        for(const property of validProperties) {
            validRequestData[property] = requestData[property];
        }
        return validRequestData;
    }

    protected createAjaxRequest(verb: HTTPVerb, url: string, data: any, 
                dataType: AjaxDataType | undefined, trimIgnoreFields: string[] = []): AjaxRequest {
        if(data !== undefined && data !== null && typeof data === "object") {
            data = this.trimSpacesInFields(data, trimIgnoreFields);
        }
        return {
            type: verb,
            dataType: dataType,
            data: data,
            url: url,
            success: undefined,
            error: undefined,
            complete: undefined,
            processData: undefined,
            contentType: undefined
        };
    }

    protected createUploadAjaxRequest(verb: HTTPVerb, url: string, formData: FormData, dataType: AjaxDataType): AjaxRequest {
        return {
            type: verb,
            dataType: dataType,
            data: formData,
            url: url,
            success: undefined,
            error: undefined,
            complete: undefined,
            processData: false,
            contentType: false
        };
    }

    protected createUploadFormData(data: any, uploadedFile: File, fileName = "file"): FormData {
		let formData = new FormData();                  
        formData.append(fileName, uploadedFile);
        for(const key in data) {
            formData.append(key, data[key]);
        }
        return formData;
    }

    protected async callAjax(ajaxRequest: AjaxRequest, verification: AJAX_RESPONSE_VERIFICATION = "Verify"): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            if(ajaxRequest.url[0] !== "/") {
                ajaxRequest.url = this.baseUrl + ajaxRequest.url;
            }

            ajaxRequest.error = (jqXHR, textStatus, errorThrown) => {
                this.handleAjaxError(jqXHR, textStatus, errorThrown);
                reject("UNHANDLED AJAX NETWORK EXCEPTION");
            }
            
            ajaxRequest.success = async (response: any) => {
                if(verification === "Bypass") {
                    resolve(response);
                } else {
                    const verificationResult = await this.verifyResponse(response);
                    if(verificationResult === true) {
                        resolve(response);
                    } else {
                        reject("UNHANDLED AJAX NETWORK EXCEPTION");
                    }   
                }

            }

            $.ajax(ajaxRequest);
        });       
    }

    private async handleAjaxError(jqXHR: JQueryXHR, textStatus: string, errorThrown: string) {  
        const store = await import("../../Store");
        const isHtmlResponse = jqXHR.responseText !== undefined ? 
            jqXHR.responseText.indexOf("<!DOCTYPE html>") >= 0 : false;

        let errorMessage = {};
        if(isHtmlResponse) {
            errorMessage = {html: jqXHR.responseText};
        } else {
            errorMessage = {text: jqXHR.responseText}
        }

        store.default.dispatch(showExceptionDialog({
            title: "Error connecting server",
            message: "The application has encountered a network error when connecting to server.",
            ...errorMessage
        }));
    }

    private async verifyResponse(response: any): Promise<boolean> {
        if(response.result !== "RESPONSE_OK") {
            let title = "";
            let message = ""
            let text = "";
            if(response.result === "RESPONSE_ERROR" && response.description !== undefined) {
                title = "Error Server Response";
                message= "The server has returned the error response.";
                text = response.description.error === undefined ? response.description : response.description.error
            } else {
                title = "Error Server Response";
                message = "The server has returned an unidentified error response.";
                text = JSON.stringify(response);
            }
            const store = await import("../../Store");
            store.default.dispatch(showExceptionDialog({ title, message, text }));
            return false;
        } else {
            return true;
        }
    }

    private trimSpacesInFields(jsonData: any, ignoreFields: string[] = []): any {
        for(let key in jsonData) {
            if(ignoreFields.includes(key) === false) {
                if(typeof jsonData[key] === "string") {
                    jsonData[key] = (jsonData[key] as string).trim();
                }
            }
        }
        return jsonData;
    }
}
