/* eslint-disable @typescript-eslint/no-unused-vars */
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { FormikDropdownField } from "../../forms/formik";
import { UserType } from "../../network/NetworkInterfaces";
import { NetworkManager } from "../../network/NetworkManager";

const soundAPI = NetworkManager.getSoundNetworkAPI();

export interface EditableSoundOwnerControlProps {
    onSoundOwnerChanged: (entityType: UserType | null, entityNo: string | null) => void;
}

const EditableSoundOwnerControl = (props: EditableSoundOwnerControlProps) => {

    const [integratorField, integratorMeta, integratorHelper] = useField("IntegratorOwner");
    const [distributorField, distributorMeta, distributorHelper] = useField("DistributorOwner");
    const [endCustomerField, endCustomerMeta, endCustomerHelper] = useField("EndCustomerOwner");

    const [integrators, setIntegrators] = useState([]);
    const [distributors, setDistributors] = useState([]);
    const [endCustomers, setEndCustomers] = useState([]);

    const isEmpty = (value: any) => {
        // eslint-disable-next-line eqeqeq
        return value === null || value === undefined || value == "0";
    }

    const fetchIntegrators = async () => {
        const data = await soundAPI.queryIntegrators() as any;
        setIntegrators(data);
    }

    const fetchIntegratorEntities = async (integratorNo: string) => {
        const data = await soundAPI.queryIntegratorEntities(integratorNo) as any;
        setDistributors(data.distributors);
        setEndCustomers(data.endCustomers);
    }

    const fetchDistributorEntities = async (distributorNo: string) => {
        const data = await soundAPI.queryDistributorEntities(distributorNo) as any;
        setEndCustomers(data);
    }

    const handleIntegratorChanged = (integratorNo: string) => {
        integratorHelper.setTouched(true, true);
        if(isEmpty(integratorNo)) {
            setDistributors([]);
            setEndCustomers([]);
            props.onSoundOwnerChanged(null, null)
        } else {
            distributorHelper.setValue(null);
            endCustomerHelper.setValue(null);
            fetchIntegratorEntities(integratorNo);    
            props.onSoundOwnerChanged("Integrator", integratorNo);
        }
    }

    const handleDistributorChanged = (distributorNo: string) => {
        distributorHelper.setTouched(true, true);
        if(isEmpty(distributorNo)) {
            endCustomerHelper.setValue(null);
            fetchIntegratorEntities(integratorField.value);
            props.onSoundOwnerChanged("Integrator", integratorField.value);
        } else {
            endCustomerHelper.setValue(null);
            fetchDistributorEntities(distributorNo);    
            props.onSoundOwnerChanged("Distributor", distributorNo);
        }
    }

    const handleEndCustomerChanged = (endCustomerNo: string) => {
        endCustomerHelper.setTouched(true, true);
        if(isEmpty(endCustomerNo)) {
            if(isEmpty(distributorField.value)) {
                props.onSoundOwnerChanged("Integrator", integratorField.value);
            } else {
                props.onSoundOwnerChanged("Distributor", distributorField.value);
            }
        } else {
            props.onSoundOwnerChanged("End Customer", endCustomerNo);
        }
    }

    const performInitialFetch = async () => {
        await fetchIntegrators();
        if(! isEmpty(integratorField.value)) {
            await fetchIntegratorEntities(integratorField.value);
            if(! isEmpty(distributorField.value)) {
                await fetchDistributorEntities(distributorField.value);
            }
        }
    }

    useEffect(() => {
        performInitialFetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <FormikDropdownField label="Integrator" name="IntegratorOwner" showClear
                    options={integrators} onChange={(e: any) => handleIntegratorChanged(e.value)} />
            <FormikDropdownField label="Distributor" name="DistributorOwner" showClear
                    options={distributors} onChange={(e: any) => handleDistributorChanged(e.value)}  />
            <FormikDropdownField label="End Customer" name="EndCustomerOwner" showClear
                    options={endCustomers} onChange={(e: any) => handleEndCustomerChanged(e.value)} />
        </>
    );
}

export default EditableSoundOwnerControl;
