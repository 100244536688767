import React from "react";
import {MultiSelect} from "primereact/multiselect";
import { Button } from "primereact/button";
import * as _ from "lodash";

import "./UnitsFilter.scss";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { applyDistributorSelectionInFilter, applyIntegratorSelectionInFilter, fetchDistributorsForFilter, fetchEndCustomersForFilter, fetchIntegratorsForFilter, filterUnits, filterUnitsByPhrase, updateSearchedPhrase } from "./UnitsReduxSlice";
import { useState } from "react";
import { SearchBox } from "../common/AppSearchBox";
import { useUpdateEnumSelection, useSelectorEnum } from "../helpers/HookHelpers";
import { UnitFilterOptions } from "../network/NetworkInterfaces";
import { useLocation, useParams } from "react-router";

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  
const UnitsFilter = () => {
    const query = useQuery();
    const search = query.get("search");

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(fetchIntegratorsForFilter(null));
        dispatch(fetchDistributorsForFilter([]));
        dispatch(fetchEndCustomersForFilter({integrators: [], distributors: []}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(search !== undefined && search !== null) {
            dispatch(filterUnitsByPhrase(search));
        }
    }, [search]);

    const [selectedIntegrators, selectIntegrators] = useState([]);
    const [selectedDistributors, selectDistributors] = useState([]);
    const [selectedEndCustomers, selectEndCustomers] = useState([]);

    const integrators = useSelectorEnum((state: any) => state.units.integrators);
    const distributors = useSelectorEnum((state: any) => state.units.distributors);
    const endCustomers = useSelectorEnum((state: any) => state.units.endCustomers);
    useUpdateEnumSelection(selectedDistributors, distributors, (sel: any) => selectDistributors(sel));
    useUpdateEnumSelection(selectedEndCustomers, endCustomers, (sel: any) => selectEndCustomers(sel));

    const handleIntegratorsSelected = (value: any) => {
        selectIntegrators(value);
        selectDistributors([]);
        selectEndCustomers([]);
        dispatch(applyIntegratorSelectionInFilter(value));
    }

    const handleDistributorsSelected = (value: any) => {
        selectDistributors(value);
        selectEndCustomers([]);
        if(value === undefined || value === null || value.length === 0) {
            dispatch(applyIntegratorSelectionInFilter(selectedIntegrators));
        } else {
            dispatch(applyDistributorSelectionInFilter({integrators: selectedIntegrators, distributors: value}));
        }
    }

    const handleFilterClicked = () => {
        const filterOptions: UnitFilterOptions = {
            integrators: selectedIntegrators,
            distributors: selectedDistributors,
            endCustomers: selectedEndCustomers
        };
        dispatch(filterUnits(filterOptions));
    }

    const FILTER_FUNCTION_DEBOUNCE = 200;
    const invokeUnitFiltering = _.debounce((phrase: string) => {
        dispatch(filterUnitsByPhrase(phrase));
    }, FILTER_FUNCTION_DEBOUNCE);

    return (
        <div className="UnitsFilter">
            <div className="UnitsFilter--FirstRow">
                <div>
                <MultiSelect options={integrators} placeholder="--- All Integrators ---" showClear display="chip"  maxSelectedLabels={100}
                    value={selectedIntegrators} onChange={e => handleIntegratorsSelected(e.value)} />
                </div>
                <div>
                <MultiSelect options={distributors} placeholder="--- All Distributors ---" showClear display="chip" maxSelectedLabels={100}
                        value={selectedDistributors} onChange={e => handleDistributorsSelected(e.value)}   />
                </div>
            </div>
            <div className="UnitsFilter--SecondRow">
                <div>
                    <SearchBox placeholder="Search units..." onChange={(value: string) => invokeUnitFiltering(value)}   />
                </div>
                <div>
                <MultiSelect options={endCustomers} placeholder="--- All End Customers ---" showClear display="chip" maxSelectedLabels={100}
                        value={selectedEndCustomers} onChange={e => selectEndCustomers(e.value)} />
                </div>
                <div>
                    <Button label="Filter Units" className="p-button-info p-button-raised" onClick={handleFilterClicked} />
                </div>
            </div>
        </div> 
    )

}

export default UnitsFilter;
