import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";

import { useDispatch, useSelector } from "react-redux";
import { createNewEndCustomer, deleteEndCustomer, editEndCustomer, fetchEndCustomers, filterEndCustomersByPhrase } from "./EndCustomerReduxSlice";
import { SearchBox } from "../common/AppSearchBox";

import "./EndCustomerDataList.scss";
import AvatarColumn from "../forms/AvatarColumn";
import ActionsDataList, { DataListAction } from "../forms/ActionsDataList";
import LoadingProgress from "../common/LoadingProgress";
import { confirmDialog } from "primereact/confirmdialog";

// Wrapper Component for full-text search
const EndCustomerFilterHeader = () => {
    // Handler for filtering users - dispatches filtering in the Store
    const dispatch = useDispatch();
    const handleFilterChanged = (phrase: string) => {
        dispatch(filterEndCustomersByPhrase(phrase));
    }

    // Handler for creating new end customer
    const handleCreateNewEndCustomer = () => {
        dispatch(createNewEndCustomer());
    }

    // Render DOM
    return (
        <div className="EndCustomerDataList--Header">
            <SearchBox placeholder="Search end customers..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <div />
            <Button label="New End Customer" className="p-button-success" 
                onClick={e => handleCreateNewEndCustomer()}/>
        </div>
    );
}

// Definition of Action Buttons for the EndCustomerDataTable
const EndCustomerTableAtions: DataListAction[] = [
    {name: "edit", label: "Edit", className: "p-button-secondary"},
    {name: "delete", label: "Delete", className: "p-button-danger"}
];

// Component for configuring the DataList Table component itself
const EndCustomerDataListTable = () => {
    // Code for invoking the initial fetching of data from the server
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchEndCustomers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Select the filtered list of users from the Redux Store    
    const endCustomerData = useSelector((state: any) => state.endCustomers.shownEndCustomers);

    // Delete Confirmation Function
    const confirmDelete = (id: string) => {
        confirmDialog({
            message: 'Are you sure you want to delete this end customer?',
            header: 'Delete End Customer',
            icon: 'pi pi-exclamation-triangle',
            style: {'width': '30rem'},
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            rejectClassName: 'p-button-secondary',
            accept: () => dispatch(deleteEndCustomer(id))
        });
    }    


    // Code for handler for receiving clicks on Action Buttons
    const handleActionClick = (data: {name: string, id: any}) => {
        switch(data.name) {
            case "edit": dispatch(editEndCustomer(data.id)); break;
            case "delete": confirmDelete(data.id); break;
        }
    }

    const AvatarColumnTemplate = (dataRow: any) => {
        return (<AvatarColumn downloadUrlPrefix="/uploads/endcustomer_logo/" src={dataRow.EndcustomerLogo} />);
    }

    // Render the final DOM of Users Data List
    return (
        <ActionsDataList value={endCustomerData} dataKey="EndCustomerNo" actions={EndCustomerTableAtions} actionColumnWidth="15rem"
                       onAction={handleActionClick} stripedRows  >
            <Column header="Id" field="EndCustomerNo" sortable style={{maxWidth: "5rem", textAlign: "center"}} />
            <Column header="Name" field="Name" sortable style={{maxWidth: "30rem", textAlign: "left"}} />
            <Column header="End Customer Logo" field="EndcustomerLogo" style={{maxWidth: "auto", textAlign: "center"}} 
                       body={AvatarColumnTemplate} />
        </ActionsDataList>
    );
}


const EndCustomerDataList = () => {
    // Fetch isLoading status from the Store
    const isLoading = useSelector((state: any) => state.endCustomers.loadingEndCustomers);

    // Render DOM
    return (
        <div className="EndCustomerDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <EndCustomerFilterHeader />
            <div className="EndCustomerDataListHeader--Separator" />
            <EndCustomerDataListTable />
        </div>
    );
}

export default EndCustomerDataList;
