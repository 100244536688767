// React Core Imports
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import * as $ from "jquery";

// Global Style Import
import './index.scss';

// Global Application Component
import Main from './Main';

// Import FontAwesome Icons
import "../node_modules/@fortawesome/fontawesome-free/js/all";

// Import CSS styles of PrimeReact Library
import "primereact/resources/themes/saga-blue/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"

// Redux Store Imports
import store from './Store';
import { Provider } from 'react-redux'
import { Loader, LoaderOptions } from 'google-maps';
import config from "./app-version.json";
import { config as configRTG } from 'react-transition-group'
import { EnvironmentHelper } from './helpers/EnvironmentHelper';

if(EnvironmentHelper.isAnimationEnabled() === false) {
  configRTG.disabled = true
}

// Note: Check the URL is ending with the forward slash with the supplied root directory
const pathname = window.location.pathname;
if(/old\/?$/.test(pathname)) {
  window.location.href = "/old/index.php";
}

// Disable AJAX cache
$.ajaxSetup({
  cache: true
});

// Adjust page zoom by device pixel ratio - mainly Windows OS Issue Text Height Settings
let zoomValue = `${1/window.devicePixelRatio}`;
document.body.style.setProperty("zoom", zoomValue);
document.body.style.setProperty("--original-zoom", window.devicePixelRatio.toString());

// Bootstrap Google Maps, then run the application
const options: LoaderOptions = {/* todo */};
const googleMapLoader = new Loader(config.apiKey, options);
googleMapLoader.load().then((google: any) => {
  window.google = google;

  // React App Bootstrapping Code
  ReactDOM.render(
      <Provider store={store}>
        <Main />
      </Provider>, document.getElementById('root')
  );
}); 

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
