import React from "react";
import CupuTypesDataList from "./cuputypes/CupuTypesDataList";
import EditCupuTypeForm from "./cuputypes/forms/EditCupuTypeForm";
import DistributorDataList from "./distributors/DistributorDataList";
import EditDistributorForm from "./distributors/forms/EditDistributorForm";
import EndCustomerDataList from "./end-customers/EndCustomerDataList";
import EditEndCustomerForm from "./end-customers/forms/EditEndCustomerForm";
import FirmwareDataList from "./firmwares/FirmwareDataList";
import UploadFirmwareForm from "./firmwares/forms/UploadFirmwareForm";
import HomeViewScreen from "./home/HomeViewScreen";
import EditIntegratorForm from "./integrators/forms/EditIntegratorForm";
import IntegratorDataList from "./integrators/IntegratorDataList";
import ApiLogDataList from "./logs/ApiLogDataList";
import AddNewEventForm from "./logs/forms/AddNewEventForm";
import ServiceLogDataList from "./logs/ServiceLogDataList";
import UnitMap from "./map/UnitMap";
import { UserType } from "./network/NetworkInterfaces";
import EditOrderForm from "./orders/forms/EditOrderForm";
import InProductionDataList from "./orders/InProductionDataList";
import OrdersDataList from "./orders/OrdersDataList";
import EditSoundForm from "./sounds/forms/EditSoundForm";
import UploadSoundForm from "./sounds/forms/UploadSoundForm";
import SoundDataList from "./sounds/SoundDataList";
import UploadManualForm from "./support/forms/UploadManualForm";
import SupportPage from "./support/SupportPage";
import EditUnitGroupForm from "./unitgroups/forms/EditUnitGroupForm";
import UnitGroupDataList from "./unitgroups/UnitGroupDataList";
import EditUnitForm from "./units/forms/EditUnitForm";
import PlaylistForm from "./units/forms/PlaylistForm";
import UnitsDataList from "./units/UnitsDataList";
import EditUserForm from "./users/forms/EditUserForm";
import UsersDataList from "./users/UsersDataList";

export type UserRoles = UserType[];

export const ALL_ROLES_ACCESS: UserRoles = ["Admin", "Distributor", "End Customer", "Integrator"];

export interface ApplicationRoute {
    path: string;
    component: any;
    roles: UserRoles;
}

export type ApplicationNavigation = ApplicationRoute[];

export const APPLICATION_ROUTING: ApplicationNavigation = [
    // Google Map
    {path: "/app/map", component: UnitMap, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},
    
    // Firmwares
    {path: "/app/firmware/upload", component: UploadFirmwareForm, roles: ["Admin"]},
    {path: "/app/firmware", component: FirmwareDataList, roles: ["Admin"]},
    
    // Cupu Types
    {path: "/app/cuputypes/edit", component: EditCupuTypeForm, roles: ["Admin"]},
    {path: "/app/cuputypes", component: CupuTypesDataList, roles: ["Admin"]},

    // End Customers
    {path: "/app/end-customers/edit", component: EditEndCustomerForm, roles: ["Admin", "Integrator", "Distributor"]},
    {path: "/app/end-customers", component: EndCustomerDataList, roles: ["Admin", "Integrator", "Distributor"]},

    // Integrators
    {path: "/app/integrators/edit", component: EditIntegratorForm, roles: ["Admin"]},
    {path: "/app/integrators", component: IntegratorDataList, roles: ["Admin"]},

    // Distributors
    {path: "/app/distributors/edit", component: EditDistributorForm, roles: ["Admin", "Integrator"]},
    {path: "/app/distributors", component: DistributorDataList, roles: ["Admin", "Integrator"]},

    // Users
    {path: "/app/users/edit", component: EditUserForm, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},
    {path: "/app/users", component: UsersDataList, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},

    // Sounds
    {path: "/app/sounds", component: SoundDataList, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},
    {path: "/app/sounds/upload", component: UploadSoundForm, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},
    {path: "/app/sounds/edit", component: EditSoundForm, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},

    // Unit Groups
    {path: "/app/unit-groups", component: UnitGroupDataList, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},
    {path: "/app/unit-groups/detail", component: EditUnitGroupForm, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},

    // Units
    {path: "/app/units/edit/:serialNumber", component: EditUnitForm, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},
    {path: "/app/units/playlist/:serialNumber", component: PlaylistForm, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},
    {path: "/app/units/apilog/:serialNumber", component: ApiLogDataList, roles: ["Admin"]},
    {path: "/app/units/servicelog/:serialNumber/add-event", component: AddNewEventForm, roles: ["Admin"]},
    {path: "/app/units/servicelog/:serialNumber", component: ServiceLogDataList, roles: ["Admin"]},
    {path: "/app/units", component: UnitsDataList, roles: ["Admin", "Integrator", "Distributor", "End Customer"]},

    // Orders
    { path: "/app/orders/create", component: EditOrderForm, roles: ["Admin"]},
    { path: "/app/orders", component: OrdersDataList, roles: ["Admin"]}, 
    { path: "/app/production", component: InProductionDataList, roles: ["Admin"]},

    // Support Page
    { path: "/app/support/upload", component: UploadManualForm, roles: ["Admin", "Distributor", "Integrator"]},
    { path: "/app/support", component: SupportPage, roles: ["Admin", "Distributor", "Integrator"]},

    // Support Page
    { path: "/app/home", component: HomeViewScreen, roles: ["Admin", "Distributor", "Integrator", "End Customer"]}
];
