import React, { useEffect, useRef } from "react";
import GenericContentForm from "../../forms/GenericContentForm";
import LoadingProgress from "../../common/LoadingProgress";

// Formik and Yup Schema Imports
import { Formik, Form, ErrorMessage } from "formik";
import  { object, array } from "yup";

// Formik Adaptation Components
import { FormikWeekDayDropdownField, FormikClocklet, FormikCheckboxField, FormikDropdownField } from "../../forms/formik";
import { useDispatch, useSelector } from "react-redux";
import { NavigationHelper } from "../../helpers/NavigationHelper";

// Style Import
import "./PlaylistForm.scss";
import PlaylistPickList from "./controls/PlaylistPickList";
import { fetchUnitGroups, savePlaylistData } from "./PlaylistReduxSlice";
import { triggerPlaylists } from "../UnitsReduxSlice";
import { useParams } from "react-router";
import { Button } from "primereact/button";

// Let's create form validation schema

// Validation check playlists are set for all sounds
const containsUnsetPlaylists = (playlists: any[]) => {    
    if(playlists === undefined || playlists === null) return false;
    return playlists.find(p => p.InPlaylist1 === null && p.InPlaylist2 === null) !== undefined;
}

// Validation that at least one sound in present in playlist 1
const isInPlaylist1AnySound = (playlists: any[]) => {
    if(playlists === undefined || playlists === null) return false;
    return playlists.find(p => p.InPlaylist1 !== null) !== undefined;
}

// Validation that at least one sound in present in playlist 2
const isInPlaylist2AnySound = (playlists: any[]) => {
    if(playlists === undefined || playlists === null) return false;
    return playlists.find(p => p.InPlaylist2 !== null) !== undefined;
}


// THE MAIN FORM VALIDATION OBJECT
const FORM_VALIDATION_SCHEMA = object().shape({
    playlists: array()
        .max(10, "There can be maximum 10 sounds in the playlists.")
        .test('all-playlists-set', "Please, set the playlists for all sounds.", (playlists: any) => {
            return containsUnsetPlaylists(playlists) === false;
        })
        .test("one-sound-in-playlist1", "Please, select at least one sound for playlist 1.", (playlists: any) => {
            return isInPlaylist1AnySound(playlists) === true;
        })
        .test("one-sound-in-playlist2", "Please, select at least one sound for playlist 2.", (playlists: any) => {
            return isInPlaylist2AnySound(playlists) === true;
        }),
    schedules: object().test('schedule-interval', "Schedule start time must be earlier than stop time.", (schedules) => {
        return schedules.FromDayOfWeek === schedules.ToDayOfWeek ? 
            parseInt(schedules.FromHourOfDay) < parseInt(schedules.ToHourOfDay) : schedules.FromDayOfWeek < schedules.ToDayOfWeek;
    })
});


const PlaylistSoundPickList = () => {
    const sounds = useSelector((state: any) => state.playlist.sounds);
    const playlists = useSelector((state: any) => state.playlist.playlists);

    return (
        <div className="PlaylistForm--PicklistWrapper">
            <PlaylistPickList sounds={sounds} playlists={playlists} name="playlists" />            
        </div>
    )
}

const PlaylistSchedules = () => {

    return (
        <div>
            <h2>Playlist Schedules</h2>
            <div className="PlaylistForm--Schedules">
                <label>Playlist 2 plays from</label>
                <div className="PlaylistForm--Schedules--TimePeriod">
                    <FormikClocklet   label={undefined} name="schedules.FromHourOfDay" />
                    <FormikWeekDayDropdownField label={undefined} name="schedules.FromDayOfWeek" />
                </div>
                <label>until</label>
                <div className="PlaylistForm--Schedules--TimePeriod">
                    <FormikClocklet   label={undefined} name="schedules.ToHourOfDay" />
                    <FormikWeekDayDropdownField label={undefined} name="schedules.ToDayOfWeek" />
                </div>
                <div>
                    <div className="Form--FormField--ErrorMessage--Wrapper" style={{top: "-0.65rem", height: "1rem"}}>
                        <div className="Form--FormField--ErrorMessage">
                            <ErrorMessage name="schedules" component="div" ></ErrorMessage>
                        </div>
                    </div>
                    <label>Playlist 1 plays the remaining time</label>
                </div>
            </div>
        </div>
    );
}

const MuteTime = () => {
    return (
        <div>
            <h2 style={{marginBottom: "0px"}}>Mute Time</h2> 
            <FormikCheckboxField name="EnableDailyMuteTime" label="Enable daily mute time from 22 until 7" />
        </div>
    )
}

const UnitGroupsEnumeration = () => {

    const unitGroupsList = useSelector((state: any) => state.playlist.unitGroupEnum)
            .map((unitGroup: any) => { return {label: unitGroup.name, value: unitGroup.id}});

    return (
        <div>
            <FormikDropdownField name="SelectedUnitGroup" options={unitGroupsList}
                    placeholder="--- Select Unit Group ---" />
        </div>
    );
}

const PlaylistForm = () => {
    const {serialNumber} = useParams<any>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(triggerPlaylists(serialNumber));
        dispatch(fetchUnitGroups(serialNumber));
    }, [])

    const isLoading=  useSelector((state: any) => state.playlist.loadingCounter > 0);

    const serialNo = useSelector((state: any) => state.playlist.serialNo);
    const EnableDailyMuteTime = useSelector((state: any) => state.playlist.EnableDailyMuteTime);
    const schedules = useSelector((state: any) => state.playlist.schedules);

    const initialValues = {serialNo, EnableDailyMuteTime, schedules};
    
    const unitGroupsList = useSelector((state: any) => state.playlist.unitGroupEnum);

    // Submit Form Handler
    const handleSubmit = (values: any) => {
        dispatch(savePlaylistData(values));
    }

    const handleReject = () => {
        NavigationHelper.goBack();
    }

    const FormBody = () => {
        return (
            <div className="PlaylistFormBody">
                <PlaylistSoundPickList />
                <div className="PlaylistFormBody--RightPart"> 
                    <PlaylistSchedules />
                    <MuteTime />
                    {unitGroupsList.length > 0 && (
                        <UnitGroupsEnumeration />
                    )}
                    <div />
                </div>
            </div>
        );
    }

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        return (
            <div className="PlaylistForm--FormFooter">
                <div />
                <div className="PlaylistForm--FormFooter--Buttons">
                    <Button icon="pi pi-times" label="Cancel" className="p-button-secondary" 
                                onClick={handleReject}  />
                    {unitGroupsList.length > 0 && (
                        <Button icon="pi pi-check" label="Update on entire group" className="p-button-success" type="submit"
                        onClick={() => formRef.current.handleSubmit()} />
                    
                        )}
                    <Button icon="pi pi-check" label="Update" className="p-button-success" type="submit"
                        onClick={() => formRef.current.handleSubmit()} />
                </div>
            </div>
        );
    }

    return (
        <GenericContentForm title="Unit Playlists" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={initialValues} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default PlaylistForm;
