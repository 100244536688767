import { Form, Formik } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { number, object, string } from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { FormikDatePickerField, FormikDropdownField, FormikNumberField, FormikTextField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { DateHelper } from "../../helpers/DateHelper";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { saveNewOrder } from "../OrdersReduxSlice";

import "./EditOrderForm.scss";

const FORM_VALIDATION_SCHEMA = object().shape({
    IntegratorNo: string().nullable(true).required("Please, select the integrator."),
    ReceivedDate: string().required("Please, select the received date."),
    IntegratorOrderNo: string().required("Please, fill in the integrator order number."),
    QuantityOrdered: number().required("Please, fill in the quatity.")
});


const EditOrderForm = () => {

    const INITIAL_VALUE = {
        IntegratorNo: null,
        ReceivedDate: DateHelper.formatDateToISO(new Date(Date.now())),
        IntegratorOrderNo: "",
        Description: "",
        QuantityOrdered: 1        
    }

    // Submit Form Handler
    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        dispatch(saveNewOrder(values));
    }

    const handleReject = () => {
        NavigationHelper.goBack();
    }

    const isLoading = useSelector((state: any) => state.orders.loadingOrders);
    const orderIntegrators = useSelector((state: any) => state.orders.orderIntegrators).map((integrator: any) => {
        return {value: integrator.IntegratorNo, label: integrator.Name}
    });

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        const acceptLabel = "Create";
        return (
            <ContentFormStandardButtons
                acceptLabel={acceptLabel} onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const FormBody = () => {  

        return (
                <div className="EditOrderForm--Body">
                        <FormikDropdownField label="Associated Integrator:" name="IntegratorNo"
                                    placeholder="--- Select Integrator ---" options={orderIntegrators}  />
                        <FormikDatePickerField label="Received Date:" name="ReceivedDate"
                            placeholder="Enter received date..." />
                        <FormikTextField label="Integrator Order Number:" name="IntegratorOrderNo" 
                            placeholder="Enter integrator order number..." ></FormikTextField>
                        <FormikTextField label="Description:" name="Description" 
                            placeholder="Enter description..." ></FormikTextField>
                        <FormikNumberField label="Quantity Ordered:" name="QuantityOrdered" min={1} max={1000}
                            placeholder="Enter quantity you want to order..." />
                </div>
        );
    }

    return (
        <GenericContentForm title="Create New Order" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={INITIAL_VALUE} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef}  >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditOrderForm;
