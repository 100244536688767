import React, { useRef } from "react";
import GenericContentForm, {ContentFormStandardButtons} from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import LoadingProgress from "../../common/LoadingProgress";

// Formik and Yup Schema Imports
import { Formik, Form } from "formik";
import  { object, string } from "yup";
import { FormikTextField  } from "../../forms/formik";

import "./EditSoundForm.scss";

import { useDispatch, useSelector } from "react-redux";
import { loadEntityTree, updateSound } from "../SoundReduxSlice";
import EntityTreeView from "../controls/EntityTreeView";
import SoundOwnerControl from "../controls/SoundOwnerControl";
import { NetworkManager } from "../../network/NetworkManager";

const loginAPI = NetworkManager.getLoginNetworkAPI();

// Let's create SoundDetailForm validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    IntegratorOwner: string().nullable(true).test("soundWOwner", "Please, fill in the sound owner", (value) => {
        return loginAPI.getLoggedUserType() === "Admin" ? (value !== undefined && value !== null && value != "0") 
                : true;
    }),
    description: string().required("Please, fill in the sound description."),
    crcCode: string().nullable()
});


// The EditSoundForm Component Itself
const EditSoundForm = () => {
    const dispatch = useDispatch();

    const initialValues = useSelector((state: any) => state.sounds.editedSound);      
    const loading = useSelector((state: any) => state.sounds.loadingSoundDetails > 0);

    const handleSubmit = (values: any) => {
        dispatch(updateSound(values));
    }

    const handleReject = () => {
        NavigationHelper.toAppUrl("/sounds");
    }

    const formRef = useRef<any>(null);
    const FormFooter = () => {
        return (
            <ContentFormStandardButtons  
                acceptLabel="Update" onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const handleSoundOwnerChanged = (entityType: string | null, entityNo: string | null) => {
        dispatch(loadEntityTree({entityType, entityNo}));
    }
    
    const FormBody = () => {
        return (
                <div className="EditSoundForm--Body">
                    <div className="EditSoundForm--Body--LeftPart">
                        <span>Sound Availability:</span>
                        <EntityTreeView />
                    </div>
                    <div className="EditSoundForm--Body--RightPart">
                        <SoundOwnerControl onSoundOwnerChanged={handleSoundOwnerChanged} />
                        <FormikTextField label="Sound Description:" name="description" 
                            placeholder="Enter description..." ></FormikTextField>
                        <FormikTextField label="CRC:" name="crcCode" disabled />
                        <div />
                    </div>
                </div>
        );
    }

    return (
        <GenericContentForm title="Edit Sound" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={loading} />
            <Formik initialValues={initialValues} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditSoundForm;
