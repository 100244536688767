import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';
import { FilterHelper } from '../helpers/FilterHelper';
import * as _ from 'lodash';
import { NavigationHelper } from '../helpers/NavigationHelper';

const firmwareNetworkAPI = NetworkManager.getFirmwareNetworkAPI();

export interface FilterFirmwareOptions {
    phrase?: string;
    hardwareCode?: string | null;
}

export const fetchFirmwares = createAsyncThunk(
    'firmwares/fetchFirmwares',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateFirmwareLoadingStatus(true));       
            
            // Fetch hardware code aka cupu types
            const hardwareCodes = await firmwareNetworkAPI.getHardwareCodes();
            dispatch(updateCupuTypes(hardwareCodes));

            // Fetch firmwares
            const firmwareData = await firmwareNetworkAPI.fetchFirmwares();
            dispatch(updateFetchedFirmwares(firmwareData));

            // Filter firmwares by the last filter settings
            dispatch(filterFirmwares(null)); 

            // Resolve the factory-default firmware
            const defaultFirwmare = firmwareData.filter(f => f.FactoryDefault === "1").map(f => {
                return {
                    HardwareCode: f.HardwareCode,
                    FirmwareNo: f.FirmwareNo
                }
            });

            if(defaultFirwmare === undefined) {
                dispatch(updateDefaultFirmware([]));
            } else {
                dispatch(updateDefaultFirmware(defaultFirwmare));
            }   
        }
        finally {
            dispatch(updateFirmwareLoadingStatus(false));           
        }
    }
);

export const filterFirmwares = createAsyncThunk(
    'firmwares/filterFirmwares',
    async (payload: FilterFirmwareOptions | null, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { firmwares } = thunkAPI.getState() as any;

        // Update last filter settings
        if(payload === null) {
            payload = firmwares.lastFilterSettings;
        } else {
            dispatch(updateLastFilterSettings(payload));
        }

        // Get firmwares out of store
        let filteredFirmwares: any[] = _.clone(firmwares.fetchedFirmwares);

        // Filter by hardware code if requested
        if(payload?.hardwareCode !== undefined && payload.hardwareCode !== null) {
            filteredFirmwares = filteredFirmwares.filter((firmware: any) => firmware.HardwareCode === payload?.hardwareCode);
        }

        // Filter by phrase if requested
        if(payload?.phrase !== undefined && payload.phrase !== null) {
            filteredFirmwares = FilterHelper.filterRecordsUsingPhrase(payload.phrase, filteredFirmwares, 
                ["FirmwareNo", "HardwareCode", "FileName", "CRC", "Description"]);
        }

        dispatch(updateShownFirmwares(filteredFirmwares));
    }
);

export const deleteFirmware = createAsyncThunk(
    'firmwares/deleteFirmware',
    async (firmwareNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateFirmwareLoadingStatus(true));
            await firmwareNetworkAPI.deleteFirmware(firmwareNo);
            dispatch(fetchFirmwares());
        }
        finally {
            dispatch(updateFirmwareLoadingStatus(false));
        }
    }
);

export const showUploadFirmwareForm = createAsyncThunk(
    'firmwares/showUploadFirmwareForm',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateEditedFirmware({HardwareCode: null, FirmwareNo: "", Description: "", CRC: "", myfile: null}))
        NavigationHelper.toAppUrl("/firmware/upload");
    }
);

export const uploadFirmware = createAsyncThunk(
    'firmwares/uploadFirmware',
    async (firmwareData: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateFirmwareLoadingStatus(true));
            await firmwareNetworkAPI.uploadFirmware(firmwareData);
            NavigationHelper.goBack();
        }
        finally {
            dispatch(updateFirmwareLoadingStatus(false));
        }
    }
);


export const setDefaultFirmware = createAsyncThunk(
    'firmwares/setDefaultFirmware',
    async (payload: {FirmwareNo: string, HardwareCode: string}, thunkAPI) => {
        const {dispatch} = thunkAPI;

        try {
            dispatch(updateFirmwareLoadingStatus(true));
            await firmwareNetworkAPI.setDefaultFirmware(payload.HardwareCode, payload.FirmwareNo);
            dispatch(fetchFirmwares());
        }
        finally {
            dispatch(updateFirmwareLoadingStatus(false));
        }
    }
);

const INITIAL_STATE = {
    firmwareLoadingStatus: false,
    fetchedFirmwares: [],
    shownFirmwares: [],
    lastFilterSettings: {},
    cupuTypes: [],
    defaultFirmware: [],    
    editedFirmware: {},
    showAssignFirmwareForm: false
}


export const firmwaresSlice = createSlice({
    name: 'firmwares',
    initialState: INITIAL_STATE,
    reducers: {
        updateFirmwareLoadingStatus: (state, action) => {
            state.firmwareLoadingStatus = action.payload;
        },
        updateFetchedFirmwares: (state, action) => {
            state.fetchedFirmwares = action.payload;
            state.shownFirmwares = _.clone(action.payload);
        },
        updateShownFirmwares: (state, action) => {
            state.shownFirmwares = action.payload;
        },
        updateDefaultFirmware: (state, action) => {
            state.defaultFirmware = action.payload;
        },
        updateEditedFirmware: (state, action) => {
            state.editedFirmware = action.payload;
        },
        updateCupuTypes: (state, action) => {
            state.cupuTypes = action.payload;
        },
        updateLastFilterSettings: (state, action) => {
            state.lastFilterSettings = action.payload;
        },
        showAssignFirmwareForm: (state, action) => {
            state.showAssignFirmwareForm = action.payload;
        }
    }
});

export const {
    updateFirmwareLoadingStatus,
    updateFetchedFirmwares,
    updateShownFirmwares,
    updateDefaultFirmware,
    updateEditedFirmware,
    updateCupuTypes,
    updateLastFilterSettings,
    showAssignFirmwareForm
} = firmwaresSlice.actions

export default firmwaresSlice.reducer
