import { useEffect } from "react";
import { Switch, useRouteMatch, useHistory } from "react-router-dom";
import { NavigationHelper } from "../helpers/NavigationHelper";

const NotFoundPage = () => {
  useEffect(() => {
        NavigationHelper.toAppUrl("page-not-found");
    }, []);
  return null;
}

 const AppSwitch = (props: any) => {
    const { children } = props;
    return (
        <Switch>
            {children}
            <NotFoundPage />
        </Switch>
    );
}

export default AppSwitch;
