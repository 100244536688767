import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NavigationHelper } from '../../helpers/NavigationHelper';
import { NetworkManager } from '../../network/NetworkManager';
import _ from "lodash";

// Async Thunk Actions
const loginAPI= NetworkManager.getLoginNetworkAPI();
const soundAPI = NetworkManager.getSoundNetworkAPI();
const unitsAPI = NetworkManager.getUnitsNetworkAPI();

 // Async Thunk Actions wiring unit filter facilities
 export const showPlaylistForm = createAsyncThunk(
    'playlist/showPlaylistForm',
    async (dto: any, thunkAPI) => {
        const {dispatch} = thunkAPI;

        dispatch(updateUnitData({SerialNo: dto.SerialNo, EnableDailyMuteTime: dto.EnableDailyMuteTime === "1"}));
        dispatch(fetchSoundEnum(null));
        dispatch(fetchPlaylistEnum(dto.SerialNo));
        dispatch(fetchPlaylistSchedules(dto.SerialNo));
    }
);

export const fetchSoundEnum = createAsyncThunk(
    'playlist/fetchSoundEnum',
    async (dto: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true));
        const loggedUserType = await loginAPI.getLoggedUserType();
        const sounds = await soundAPI.fetchSounds(loggedUserType);
        dispatch(updateSounds(sounds));
        dispatch(updateLoadingStatus(false));
    }
);

export const fetchPlaylistEnum = createAsyncThunk(
    'playlist/fetchPlaylistEnum',
    async (serialNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true));
        const playlists = await unitsAPI.fetchUnitPlaylists(serialNo);

        // DEBUG - Note: in test database there are duplicate records of sounds in the playlists
        let data = _.cloneDeep(playlists);
        data = _.uniqBy(data, "FileNo");
        dispatch(updatePlaylists(data));
        dispatch(updateLoadingStatus(false));
    }
);

export const fetchPlaylistSchedules = createAsyncThunk(
    'playlist/fetchPlaylistSchedules',
    async (serialNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true));
        try {
            const schedules = await unitsAPI.fetchUnitPlaylistSchedules(serialNo);
            dispatch(updateSchedules(schedules));   
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);

export const fetchUnitGroups = createAsyncThunk(
    "playlist/fetchUnitGroups",
    async (serialNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true));
        try {
        // Fetch unit group enumeration
        const unitGroups  = await unitsAPI.fetchUnitGroups(serialNo);
            dispatch(updateUnitGroupEnum(unitGroups));
        } finally {
            dispatch(updateLoadingStatus(false));
        }
    }
)

export const savePlaylistData = createAsyncThunk(
    'playlist/savePlaylistData',
    async (formData: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true));
        try {
            await unitsAPI.updateUnitDailyMuteTime(formData.serialNo, formData.EnableDailyMuteTime);
            await unitsAPI.updatePlaylists(formData.serialNo, formData.playlists, formData.EnableDailyMuteTime);
            await unitsAPI.updatePlaylistSchedules(formData.serialNo, formData.schedules);
        } finally {
            dispatch(updateLoadingStatus(false));
        }
        NavigationHelper.goBack();
    }
);

// Initial State
const INITIAL_STATE = {
    loadingCounter: 0,
    sounds: [],
    playlists: [],
    schedules: {},
    serialNo: "",
    EnableDailyMuteTime: false,
    unitGroupEnum: []
}

// Redux Slice for Units Module
export const playlistSlice = createSlice({
    name: 'playlist',
    initialState: INITIAL_STATE,
    reducers: {
        updateLoadingStatus: (state, action) => {
            if(action.payload === true) {
                state.loadingCounter++;
            } else {
                state.loadingCounter--;
            }
        },
        updateSounds: (state, action) => {
            state.sounds = action.payload;
        },
        updatePlaylists: (state, action) => {
            state.playlists = action.payload;
        },
        updateSchedules: (state, action) => {
            state.schedules = action.payload;
        },
        updateUnitData: (state, action) => {
            state.serialNo = action.payload.SerialNo;
            state.EnableDailyMuteTime = action.payload.EnableDailyMuteTime;
        },
        updateUnitGroupEnum: (state, action) => {
            state.unitGroupEnum = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateLoadingStatus,
    updateSounds,
    updatePlaylists,
    updateSchedules,
    updateUnitData,    
    updateUnitGroupEnum
} = playlistSlice.actions

export default playlistSlice.reducer
