import React from "react";
import { Card } from 'primereact/card';
import { Button } from "primereact/button";

import "./GenericContentForm.scss";

const ContentFormHeader = (props: any) => {
    return (
        <div className="GenericContentForm--Header">
            <Button icon="fas fa-angle-double-left" onClick={e => props.onCancel()}
                    className="p-button-secondary" tooltip="Navigate back" tooltipOptions={{position: "bottom"}}   ></Button>
            <span>{props.title}</span>
        </div>
    );
}

// Helper Component providing Accept and Reject buttons - this is the most common use case in forms
export const ContentFormStandardButtons = (props: any) => {
    return (
        <div className="GenericContentForm--StandardButtons">
            <div style={{display: "flex", gap: "5px"}} >
                {props.buttons }
            </div>
            <Button icon="pi pi-times" label="Cancel" className="p-button-secondary" 
                        onClick={e => props.onReject()}  />
            <Button icon="pi pi-check" label={props.acceptLabel} className="p-button-success" type="submit"
                onClick={e => props.onAccept()} />
        </div>
    );
}


const GenericContentForm = (props: any) => {
    const GenericContentFormHeader = (<ContentFormHeader title={props.title} onCancel={() => props.onCancel()} />)

    return (
        <div className="GenericContentForm--Wrapper">
            <Card header={GenericContentFormHeader} className="GenericContentForm" >
                <div className="GenericContentForm--Content" >
                    <div className="GenericContentForm--Body" >
                        <div>
                            { props.children }                
                        </div>
                    </div>
                    <div className="GenericContentForm--Footer" >
                        { props.footer() }
                    </div>
                </div>
            </Card>
        </div>
    );   
}

export default GenericContentForm;
