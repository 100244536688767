import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as _ from "lodash";
import { DateHelper } from '../helpers/DateHelper';
import { FileHelper } from '../helpers/FileHelper';
import { FilterHelper } from '../helpers/FilterHelper';
import { NavigationHelper } from '../helpers/NavigationHelper';
import { NetworkManager } from '../network/NetworkManager';

const manualsAPI = NetworkManager.getManualsAPI();

const formatManualRecords = (data: any[]): any[] => {
    data = _.cloneDeep(data);
    return data.map(file => {
        return {
            ...file, 
            id: file.name,
            formattedSize: FileHelper.formatFileSize(file.size),
            date: DateHelper.formatDateToISO(new Date(file.date * 1000))
        }
    });
}

export const fetchManuals = createAsyncThunk(
    'support/fetchManuals',
    async (payload: undefined, thunkAPI) => {        
        const {dispatch} = thunkAPI;
        dispatch(uploadManualLoadingStatus(true));

        try {
            let data = await manualsAPI.fetchManuals();
            data = formatManualRecords(data);
            dispatch(updateManuals(data));
        }
        finally {
            dispatch(uploadManualLoadingStatus(false));
        }
    }
);

export const filterManuals = createAsyncThunk(
    'support/filterManuals',
    async (phrase: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const {support } = thunkAPI.getState() as any;
        const { fetchedManuals } = support;

        const filteredManuals = FilterHelper.filterRecordsUsingPhrase(phrase, fetchedManuals, ["name", "date", "formattedSize"])
        dispatch(updateShownManuals(filteredManuals));
    }
);

export const showManualUploadForm = createAsyncThunk(
    'support/showUploadForm',
    async (payload: undefined, thunkAPI) => {
        NavigationHelper.toAppUrl("support/upload");
    }
);

export const uploadManual = createAsyncThunk(
    'support/uploadManual',
    async (data: any, thunkAPI) => {
        const {dispatch} = thunkAPI;

        dispatch(uploadManualLoadingStatus(true));
        try {
            await manualsAPI.uploadManual(data);
        }
        finally {
            dispatch(uploadManualLoadingStatus(false));
        }
        dispatch(fetchManuals());
        NavigationHelper.goBack();
    }
);

export const deleteManual = createAsyncThunk(
    'support/deleteManual',
    async (filename: string, thunkAPI) => {
        const {dispatch} = thunkAPI;

        dispatch(uploadManualLoadingStatus(true));
        try {
            await manualsAPI.deleteManual(filename);
        }
        finally {
            dispatch(uploadManualLoadingStatus(false));
        }
        dispatch(fetchManuals());
    }
);

const INITIAL_STATE = {
    isLoading: false,
    fetchedManuals: [],
    shownManuals: []
}

export const supportSlice = createSlice({
    name: 'support',
    initialState: INITIAL_STATE,
    reducers: {
        updateManuals: (state, action) => {
            state.fetchedManuals = action.payload;
            state.shownManuals = _.cloneDeep(action.payload);
        },
        updateShownManuals: (state, action) => {
            state.shownManuals = action.payload;
        },
        uploadManualLoadingStatus: (state, action) => {
            state.isLoading = action.payload;
        }
    }
});

export const { 
    updateManuals,
    updateShownManuals,
    uploadManualLoadingStatus
} = supportSlice.actions

export default supportSlice.reducer;
