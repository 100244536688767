

export class AjaxHelper {

    static parseAjaxResponse(response: any, dataProps: string[], idProperty: string[] | string) {
        let response_data = response.response_data;
        if(response_data === undefined)
            response_data = response;
        if(dataProps.length === 0) {
            return response_data.map((item: object) => {
                const id = this.getRecordId(item, idProperty);
                return { id, ...item }
            });   
        }
        for(const dataToken of dataProps) {
            if(response_data[dataToken] !== undefined) {
                if(response_data[dataToken] instanceof Array) {
                    return response_data[dataToken].map((item: object) => {
                        const id = this.getRecordId(item, idProperty);
                        return { id, ...item }
                    });   
                } else {
                    return response_data[dataToken];
                }
            }
        }
        throw new Error(`Server Response does not contain any of passed data properties.`);
    }

    private static getRecordId(record: any, idProperty: string[] | string) {
        if(typeof idProperty === "string") {
            return record[idProperty];
        } else {
            for(const id of idProperty) {
                if(record[id] !== undefined) {
                    return record[id];
                }
            }
            return undefined;   
        }
    }

    static formatAjaxRequest(dto: any, fields: string[]) {
        const ajaxData: any = {};
        for(const field of fields) {
            ajaxData[field] = dto[field];
        }
        return ajaxData;
    }

    static parseAjaxEnumResponse(response: any, dataProps: string[], idProperty: string, nameProperty: string) {
        const data = this.parseAjaxResponse(response, dataProps, idProperty);
        return data.map((item: any) => {
            return {id: item[idProperty], name: item[nameProperty]};
        });
    }
}
