import * as _ from "lodash";

export class FilterHelper {

    static filterRecordsUsingPhrase(phrase: string, records: any[], fields: string[]): any[] {
        phrase = phrase.trim().toLocaleLowerCase();        
        if(phrase === "") {
            return  _.clone(records);
        } else {
            return records.filter(item => { return FilterHelper.doesRecordMatch(phrase, item, fields); });
        }
    }

    private static doesRecordMatch(phrase: string, record: any, fields: string[]): boolean {
        let isMatch = false;        
        for(let field of fields)  {
            let fieldValue = record[field] as string;
            if(fieldValue === undefined || fieldValue === null)
                continue;
            isMatch = isMatch || ((fieldValue.toLocaleLowerCase()).indexOf(phrase) >= 0);
        }
        return isMatch;
    }
}
