import { Formik } from "formik";
import React, { useRef } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { mixed, object } from "yup";
import { FormikFileUploadField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { uploadManual } from "../SupportReduxSlice";

import "./UploadManualForm.scss";

const INITIAL_VALUES = {
    myfile: null,
};

const FILE_SIZE = 8 * 1024 * 1024;
const SUPPORTED_FORMATS: string[] = ["application/pdf"];

const FORM_VALIDATION_SCHEMA = object().shape({
    myfile: mixed()
    .nullable(false)
    .required("Please, select the PDF you want to upload")
    .test("size", "Selected file is too big. Maximum size is 8MB.",  value => !value || (value && value.size <= FILE_SIZE))
    .test("type", "File has unsupported format Supported file formats are only PDF files.",
        value => !value || (value && SUPPORTED_FORMATS.includes(value.type)))
});

const UploadManualForm = () => {

    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        dispatch(uploadManual(values));
    }

    const handleReject = () => {
        NavigationHelper.goBack();
    }

    const formRef = useRef<any>(null);
    const FormFooter = () => {
        const acceptLabel = "Upload";
        return (
            <ContentFormStandardButtons
                acceptLabel={acceptLabel} onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const FormBody = () => {  

        return (
                <div className="UploadManualForm--Body">
                    <FormikFileUploadField label="PDF File to Upload:" name="myfile"
                        chooseLabel="Select PDF File to Upload"  customUpload  />
                </div>
        )
    }
    return (
        <GenericContentForm title="Upload PDF Manual" footer={FormFooter} onCancel={handleReject} >
            <Formik initialValues={INITIAL_VALUES} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef}  >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default UploadManualForm;
