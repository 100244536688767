import React, { useEffect, useState } from "react";
import { UserType } from "../../network/NetworkInterfaces";
import { NetworkManager } from "../../network/NetworkManager";
import EditableSoundOwnerControl from "./EditableSoundOwnerControl";
import NonEditableSoundOwnerControl from "./NonEditableSoundOwnerControl";

import "./SoundOwnerControl.scss";

const loginAPI = NetworkManager.getLoginNetworkAPI();

export interface SoundOwnerControlProps {
    onSoundOwnerChanged: (entityType: UserType | null, entityNo: string | null) => void;
}

const SoundOwnerControl = (props: SoundOwnerControlProps) => {
   
    const [isEditable, setIsEditable] = useState(false);
    const [userType, setUserType] = useState("");
    const [companyName, setCompanyName] = useState("");

    const resolveEditableControlVersion = (userType: UserType, companyName: string) => {
        switch(userType) {
            case "Admin": 
                setIsEditable(true); 
                break;
            default:
                setUserType(userType);
                setCompanyName(companyName);
                break;
        }
    }

    const probeEditableVersion = async () => {
        const companyName = await loginAPI.getCompanyName();
        const userType = loginAPI.getLoggedUserType();
        resolveEditableControlVersion(userType, companyName);
    }

    useEffect(() => {
        probeEditableVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="SoundOwnerControl">
            { 
                isEditable 
                ? (<EditableSoundOwnerControl onSoundOwnerChanged={props.onSoundOwnerChanged} /> )
                : ( <NonEditableSoundOwnerControl /> )
            }
        </div>
    );
}

export default SoundOwnerControl;
