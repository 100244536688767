import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";

import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../common/AppSearchBox";

import AvatarColumn from "../forms/AvatarColumn";
import ActionsDataList, { DataListAction } from "../forms/ActionsDataList";
import LoadingProgress from "../common/LoadingProgress";
import { confirmDialog } from "primereact/confirmdialog";
import { createNewIntegrator, deleteIntegrator, editIntegrator, fetchIntegrators, filterIntegratorsByPhrase } from "./IntegratorReduxSlice";

import "./IntegratorDataList.scss";

// Wrapper Component for full-text search
const IntegratorFilterHeader = () => {
    const dispatch = useDispatch();
    const handleFilterChanged = (phrase: string) => {
        dispatch(filterIntegratorsByPhrase(phrase));
    }

    const handleCreateNewIntegrator = () => {
        dispatch(createNewIntegrator());
    }

    return (
        <div className="IntegratorDataList--Header">
            <SearchBox placeholder="Search integrators..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <div />
            <Button label="New Integrator" className="p-button-success" 
                onClick={e => handleCreateNewIntegrator()}/>
        </div>
    );
}

// Definition of Action Buttons for the EndCustomerDataTable
const IntegratorTableAtions: DataListAction[] = [
    {name: "edit", label: "Edit", className: "p-button-secondary"},
    {name: "delete", label: "Delete", className: "p-button-danger"}
];

// Component for configuring the DataList Table component itself
const IntegratorDataListTable = () => {
    // Code for invoking the initial fetching of data from the server
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchIntegrators());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Select the filtered list of users from the Redux Store    
    const integratorData = useSelector((state: any) => state.integrators.shownIntegrators);

    // Delete Confirmation Function
    const confirmDelete = (id: string) => {
        confirmDialog({
            message: 'Are you sure you want to delete this integrator?',
            header: 'Delete Integrator',
            icon: 'pi pi-exclamation-triangle',
            style: {'width': '30rem'},
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            rejectClassName: 'p-button-secondary',
            accept: () => dispatch(deleteIntegrator(id))
        });
    }    


    // Code for handler for receiving clicks on Action Buttons
    const handleActionClick = (data: {name: string, id: any}) => {
        switch(data.name) {
            case "edit": dispatch(editIntegrator(data.id)); break;
            case "delete": confirmDelete(data.id); break;
        }
    }

    const AvatarColumnTemplate = (dataRow: any) => {
        return (<AvatarColumn downloadUrlPrefix="/uploads/Integrator_logo/" src={dataRow.IntegratorLogo} />);
    }

    return (
        <ActionsDataList value={integratorData} dataKey="IntegratorNo" actions={IntegratorTableAtions} actionColumnWidth="15rem"
                       onAction={handleActionClick} stripedRows  >
            <Column header="Id" field="IntegratorNo" sortable style={{maxWidth: "5rem", textAlign: "center"}} />
            <Column header="Name" field="Name" sortable style={{maxWidth: "15rem", textAlign: "left"}} />
            <Column header="Integrator Logo" field="IntegratorLogo" style={{maxWidth: "auto", textAlign: "center"}} 
                       body={AvatarColumnTemplate} />
            <Column header="Login Text" field="LoginText" style={{maxWidth: "15rem", textAlign: "left"}} />
        </ActionsDataList>
    );
}


const IntegratorDataList = () => {
    // Fetch isLoading status from the Store
    const isLoading = useSelector((state: any) => state.integrators.loadingIntegrators);

    return (
        <div className="IntegratorDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <IntegratorFilterHeader />
            <div className="IntegratorDataListHeader--Separator" />
            <IntegratorDataListTable />
        </div>
    );
}

export default IntegratorDataList;
