import { Formik, Form, useField } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import  { object, string, mixed } from "yup";
import * as Yup from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { FormikAvatarUploadField, FormikDropdownField, FormikPasswordField, FormikTextField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { fetchRelatedUsers, saveUser } from "../UsersReduxSlice";

import "./EditUserForm.scss";
import { NetworkManager } from "../../network/NetworkManager";
import { UserType } from "../../network/NetworkInterfaces";

const loginAPI = NetworkManager.getLoginNetworkAPI();

const USER_TYPES = [
    {label: "Admin", value: "Admin"},
    {label: "Integrator", value: "Integrator"},
    {label: "Distributor", value: "Distributor"},
    {label: "End Customer", value: "End Customer"}
]

function filterOutUserTypes(userType: UserType): any[] {
    if(userType === "Admin") {
        return  USER_TYPES;
    } else if(userType ==="Integrator") {
        return USER_TYPES.filter(ut => ut.value !== "Admin")
    } else if(userType === "Distributor") {
        return USER_TYPES.filter(ut => ["Admin", "Integrator"].indexOf(ut.value) < 0);
    } else if(userType === "End Customer") {
        return USER_TYPES.filter(ut => ["Admin", "Integrator", "Distributor"].indexOf(ut.value) < 0);
    } else {
        return [];
    }
}

const FILE_SIZE = 8 * 1024 * 1024; 
const SUPPORTED_FORMATS: string[] = ["image/jpeg", "image/png", "image/gif"]; 

const FORM_VALIDATION_SCHEMA = object().shape({
    Username: string().required("Please, fill in the user name."),
    Password: string().when("ID", {
        is: (value: any) => value === undefined,
        then: Yup.string().required("Please, enter the user password")
    }),
    Email: string().required("Please, fill in the user email."),
    RelatedTo: string().nullable()
            .when("Type", {
                is: (value: any) => value !== "Admin" && value !== null,
                then: Yup.string().nullable(true).required("Please, fill in the assocated user.")
            }),
    Type: string().nullable().required("Please, select the user type."),
    UserPicture: mixed()
        .nullable(true)
        .test("size", "Selected file is too big. Maximum size is 8MB.",  
            (value) => typeof value === "string" || (!value || (value && value.size <= FILE_SIZE)))
        .test("type", "File has unsupported format Supported file formats are only images.",
            value => typeof value === "string" || (!value || (value && SUPPORTED_FORMATS.includes(value.type))))
});

const EditUserForm = () => {
    // Submit Form Handler
    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        dispatch(saveUser(values));
    }

    const handleReject = () => {
        NavigationHelper.goBack();
    }

    const editedUser = useSelector((state: any) => state.users.editedUser);
    
    const isLoading = useSelector((state: any) => state.users.loadingUsers);
    const relatedUsers = useSelector((state: any) => state.users.relatedUsers);
    console.dir(editedUser);
    console.dir(relatedUsers);
    const [userTypes, setUserTypes] = useState<any[]>([]);

    const dialogTitle = editedUser.ID === undefined ?
                "Create New User" : "Edit Existing User";

    useEffect(() => {
        const loggedUserType = loginAPI.getLoggedUserType();
        const userTypes = filterOutUserTypes(loggedUserType);
        setUserTypes(userTypes);
        dispatch(fetchRelatedUsers(editedUser.Type));
    }, []);

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        const acceptLabel = editedUser.ID === undefined ? "Create" : "Update";
        return (
            <ContentFormStandardButtons
                acceptLabel={acceptLabel} onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const FormBody = () => {  
        const [logoUrl] = useField<string>("UserPicture");
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [userType, metaType, helpersType] = useField<string>("Type");
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [relatedTo, meta, helpersRelatedTo] = useField<string | null>("RelatedTo");

        const isRelatedToDisabled = userType.value === null || userType.value === undefined
                        || userType.value === "Admin";

        const handleUserTypeChange = (value: any) => {
            dispatch(fetchRelatedUsers(value));            
            helpersRelatedTo.setValue(null, false);
        }

        return (
                <div className="EditUser--Body">
                    <div className="EditUser--Body--Left-Part" >
                        <FormikTextField label="User Name:" name="Username" 
                            placeholder="Enter user name..." ></FormikTextField>
                        <FormikPasswordField label="Password:" name="Password"
                            placeholder="Entering a password will trigger an email to the user with credentials" />                            
                        <FormikTextField label="Email:" name="Email" 
                            placeholder="Enter user email..." ></FormikTextField>
                        <FormikDropdownField label="User Type:" name="Type"
                            placeholder="--- Select User Type ---" options={userTypes} 
                            onChange={(e: any) => handleUserTypeChange(e.value) }/>
                        <FormikDropdownField label="Related To:" name="RelatedTo" disabled={isRelatedToDisabled}
                            placeholder="--- Select Related To User ---" options={relatedUsers}
                            optionLabel="name" optionValue="id" />
                    </div>
                    <div>
                        <FormikAvatarUploadField label="User Picture" name="UserPicture" 
                                    downloadUrlPrefix="/uploads/userlogos/" value={logoUrl.value}  />
                    </div>
                </div>
        );
    }

    return (
        <GenericContentForm title={dialogTitle} footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={editedUser} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form autoComplete="off" >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditUserForm;
