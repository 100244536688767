import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';

const unitsNetworkAPI = NetworkManager.getUnitsNetworkAPI();

const transformUnitDataForMap = (units: any[]): any[] => {
    units = units.filter(unit => unit.SerialNo !== "") // HOTFIX: Unit with no SerialNo in DB
    units = units.filter(unit => unit.Lat !== "0" && unit.Lng !== "0") // HOTFIX: Filter out units with no coordinates
    return units.map(unit => {
        return {
            SerialNo: unit.SerialNo,
            Lat: unit.Lat,
            Lng: unit.Lng
        }
    });
}

export const fetchUnitsInMap = createAsyncThunk(
    'map/fetchUnitsInMap',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingStatus(true));       
            const gmapData: any = await unitsNetworkAPI.fetchUnitsForGMap();
            const unitsDataForMap = transformUnitDataForMap(gmapData.Units);
            dispatch(updateUnits(unitsDataForMap));
            dispatch(updateErrorCodes(gmapData.ErrorCodes))
        }
        catch(e) {
            console.dir(e)
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);

const INITIAL_STATE = {
    units: [],
    errorCodes: [],
    loadingUnits: false
}

export const unitMapSlice = createSlice({
    name: 'map',
    initialState: INITIAL_STATE,
    reducers: {
        updateLoadingStatus: (state, action) => {
            state.loadingUnits = action.payload;
        },
        updateUnits: (state, action) => {
            state.units = action.payload;
        },
        updateErrorCodes: (state, action) => {
            state.errorCodes = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateLoadingStatus,
    updateUnits,
    updateErrorCodes
} = unitMapSlice.actions

export default unitMapSlice.reducer
