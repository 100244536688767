import React from "react";
import {GMap} from "primereact/gmap";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUnitsInMap } from "./UnitMapReduxSlice";
import LoadingProgress from "../common/LoadingProgress";
import { NavigationHelper } from "../helpers/NavigationHelper";
import { useRef } from "react";



const UnitMap = () => {

    const dispatch = useDispatch();
    const handleMapReady = () => {
        dispatch(fetchUnitsInMap());
    }

    const mapRef = useRef<any>(null);

    const unitsInMap = useSelector((state: any) => state.map.units);
    const unitErrorCodes = useSelector((state: any) =>  state.map.errorCodes);

    const isLoading = useSelector((state: any) => state.map.loadingUnits);

    let bounds = new google.maps.LatLngBounds();

    const googleMapMarkers = unitsInMap.filter((unit: any) => {
        const lat = parseFloat(unit.Lat);
        const lng = parseFloat(unit.Lng);
        return isNaN(lat) === false && isNaN(lng) === false &&  lat !== 0 && lng !== 0;
    }).map((unit: any) => {
        const lat = parseFloat(unit.Lat);
        const lng = parseFloat(unit.Lng);
        const marker = new google.maps.Marker({position: {lat: lat, lng: lng}});
        
        
        if(unitErrorCodes[unit.SerialNo] === 0) {
            marker.setIcon("https://maps.google.com/mapfiles/ms/icons/green-dot.png");
        } else {
            marker.setIcon("https://maps.google.com/mapfiles/ms/icons/red-dot.png");
        }

        const markerPosition = marker.getPosition();    
        if(markerPosition !== null && markerPosition !== undefined) {
            bounds.extend(markerPosition);
        }
        var infoWindow = new google.maps.InfoWindow({content: 'SerialNo: ' + unit.SerialNo});
        marker.addListener("mouseover", () => {
            infoWindow.open(mapRef.current, marker);
        });
        marker.addListener("mouseout", () => {
            infoWindow.close();
        });
        marker.addListener("dblclick", () => {
            NavigationHelper.toAppUrl(`/units/edit/${unit.SerialNo}`);
        });
        return marker;
    });

    if(mapRef.current !== null) {
        mapRef.current.getMap().fitBounds(bounds);
    }

    const options = {
        center: {lat: 55.6979808, lng: 12.4234662},
        zoom: 12
    };

    return (
        <div className="LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <div style={{height: "100%"}} >
                <GMap ref={mapRef} options={options}  overlays={googleMapMarkers} style={{height: "100%"}}                
                         onMapReady={e => handleMapReady()} />
            </div>
        </div>
    );
}

export default UnitMap;
