import React from "react"
import { Dialog } from 'primereact/dialog';
import { Button } from "primereact/button";
import { useDispatch, useSelector } from "react-redux";
import { closePlaySoundDialog } from "../SoundReduxSlice";

const PlaySoundForm = (props: any) => {

    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(closePlaySoundDialog(null));
    }

    const playSoundFileName = useSelector((state: any) => state.sounds.soundFileToPlay);

    const playSoundUrl = `/uploads/${playSoundFileName}`;

    const DialogFooter = (
        <div style={{textAlign: "right"}}>
            <Button label="Close" className="p-button-success" onClick={handleClose} />
        </div>
    )

    return (
        <Dialog header="Play Sound" visible={true} style={{width: "40rem"}} footer={DialogFooter}
                   onHide={handleClose}  >
            <div style={{textAlign: "center", paddingTop: "1rem"}}>
                <audio style={{width: "100%", boxShadow: "1px 1px 5px 1px gray", borderRadius: "2rem"}}  controls autoPlay src={playSoundUrl} />
            </div>
        </Dialog>
    )
}

export default PlaySoundForm;
