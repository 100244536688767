import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NavigationHelper } from '../../helpers/NavigationHelper';
import { UserType } from '../../network/NetworkInterfaces';
import { NetworkManager } from '../../network/NetworkManager';

// Units Network API
const loginAPI = NetworkManager.getLoginNetworkAPI();
const unitsNetworkAPI = NetworkManager.getUnitsNetworkAPI();

// Async Thunk Actions

 // Async Thunk Actions wiring unit filter facilities
 export const editUnit = createAsyncThunk(
    'editUnit/editUnit',
    async (dto: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateOpenedUnit(dto));
        dispatch(fetchFirmware(dto.HardwareCode));
        dispatch(fetchIntegrators(null));
    }
);

export const fetchIntegrators = createAsyncThunk(
    'editUnit/fetchIntegrators',
    async (dto: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true));
        const integrators = await unitsNetworkAPI.fetchIntegrators();
        dispatch(updateIntegrators(integrators));
        dispatch(updateLoadingStatus(false));
    }
);

export const fetchDistributors = createAsyncThunk(
    'editUnit/fetchDistributors',
    async (integratorNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const distributors = await unitsNetworkAPI.fetchAssociatedDistributors(integratorNo);
        console.log("DISTRIBUTORS");
        console.dir(distributors);
        dispatch(updateDistributors(distributors));
    }
);

export const fetchIntegratorEndCustomers = createAsyncThunk(
    'editUnit/fetchIntegratorEndCustomers',
    async (integratorNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const endCustomers = await unitsNetworkAPI.fetchAssociatedEndCustomers([integratorNo]);
        console.log("END CUSTOMERS");
        console.dir(endCustomers);
        dispatch(updateEndCustomers(endCustomers));
    }
);

export const fetchDistributorEndCustomers = createAsyncThunk(
    'editUnit/fetchDistributorEndCustomers',
    async (payload: {integratorNo: string, distributorNo: string}, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const userType: UserType = await loginAPI.getLoggedUserType();
        const endCustomers = await unitsNetworkAPI.fetchAssocEndCustomersToDistributors(
            userType, [payload.integratorNo], payload.distributorNo);
        console.log("END CUSTOMERS");
        console.dir(endCustomers);
        dispatch(updateEndCustomers(endCustomers));
    }
  );

export const fetchFirmware = createAsyncThunk(
    'editUnit/fetchFirmware',
    async (hardwareCode: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true));
        const firmware = await unitsNetworkAPI.fetchFirmwareList(hardwareCode);
        dispatch(updateFirmware(firmware));
        dispatch(updateLoadingStatus(false));
    }
);

export const saveUnit = createAsyncThunk(
    'editUnit/saveUnit',
    async (dto: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingStatus(true));
            await unitsNetworkAPI.updateUnit(dto);   
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
        NavigationHelper.goBack();
    }
);

export const deleteUnit = createAsyncThunk(
    'units/deleteUnit',
    async (unitNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingStatus(true));
            await unitsNetworkAPI.deleteUnit(unitNo);
            NavigationHelper.goBack();
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);

// Initial State
const INITIAL_STATE = {
    openedUnit: {},
    isLoadingCounter: 0,
    firmware: [],
    integrators: [],
    distributors: [],
    endCustomers: []
};

// Redux Slice for Units Module
export const editUnitSlice = createSlice({
    name: 'editUnit',
    initialState: INITIAL_STATE,
    reducers: {
        updateOpenedUnit: (state, action) => {
            state.openedUnit = action.payload;
        },
        updateLoadingStatus: (state, action) => {
            if(action.payload === true) {
                state.isLoadingCounter++;
            } else {
                state.isLoadingCounter--;
            }
        },
        updateFirmware: (state, action) => {
            state.firmware = action.payload;
        },
        updateIntegrators: (state, action) => {
            state.integrators = action.payload;
        },
        updateDistributors: (state, action) => {
            console.dir(action.payload);
            state.distributors = action.payload;
        },
        updateEndCustomers: (state, action) => {
            state.endCustomers = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateLoadingStatus,
    updateOpenedUnit,
    updateFirmware,
    updateIntegrators,
    updateDistributors,
    updateEndCustomers
} = editUnitSlice.actions

export default editUnitSlice.reducer
