import { NetworkHelper } from "../../helpers/NetworkHelper";
import type { UnitDataRow, UnitsNetworkAPI, UserType } from "../NetworkInterfaces";
import { RestrictAccess } from "../NetworkSecurity";
import { CupuNetworkAPIBase } from "./CupuNetworkAPIBase";

export class CupuUnitsNetworkAPI extends CupuNetworkAPIBase implements UnitsNetworkAPI {

     // Methods supporting units filtering
    //////////////////////////////////////////////////////////
    
    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchIntegrators(): Promise<any[]> {
        const data = {};
        const ajaxRequest = super.createAjaxRequest("POST", "Integrator/get_Integrators", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseIntegrators(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async filterDistributorsByIntegrators(integrators: string[]): Promise<any[]> {
        const data = { integrators: integrators };
        const ajaxRequest = super.createAjaxRequest("POST", "Distributor/get_distributor_associated_to_integrators",
                           data, "json");
       const response = await super.callAjax(ajaxRequest);
       return NetworkHelper.Units.parseDistributors(response);
   }

   @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
   async filterEndCustomersByDistributors(distributors: string[]): Promise<any[]> {
        const data = { distributors: distributors };
        const ajaxRequest = super.createAjaxRequest("POST", "Endcustomer/get_endcustomer_associated_to_distributors",
                        data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseEndCustomers(response);
    }

   @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
   async filterEndCustomers(integrators: string[], distributors: string[]): Promise<any[]> {
       const data = {
           integrators : integrators,
           distributors: distributors            
       };
       const ajaxRequest = super.createAjaxRequest("POST", 
                   "Endcustomer/get_endcustomers_associated_to_integrators_and_distributors",
                   data, "json");
       const response = await super.callAjax(ajaxRequest);
       return NetworkHelper.Units.parseEndCustomers(response);
   }

     // Methods supporting core CRUD Units functionality
    //////////////////////////////////////////////////////////

    @RestrictAccess(["Admin"])
    async deleteUnit(serialNo: string): Promise<void> {
        const data = {SerialNo: serialNo};
        const ajaxRequest = super.createAjaxRequest("POST", "units/delete_unit", data, "json");
        const response = await super.callAjax(ajaxRequest);
    }
    
    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async updateUnit(unitDTO: any): Promise<void> {
        const data = NetworkHelper.Units.formatUpdateRequest(unitDTO);
        const ajaxRequest = super.createAjaxRequest("POST", "units/update_unit", data, "json");
        await super.callAjax(ajaxRequest);
    }
    
    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchUnits(): Promise<UnitDataRow[]> {
        const data = {};
        const ajaxRequest = super.createAjaxRequest("GET", "units/get_units", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseUnits(response);
    }        

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
   async filterUnits(searchOptions: any): Promise<UnitDataRow[]> {
       const data = NetworkHelper.Units.formatSearchRequest(searchOptions);
       const ajaxRequest = super.createAjaxRequest("GET", "units/get_filtered_units", data, "json");
       const response = await super.callAjax(ajaxRequest);
       return NetworkHelper.Units.parseUnits(response);
   }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchEndCustomers(): Promise<any[]> {
        const data = {};
        const ajaxRequest = super.createAjaxRequest("POST", "Endcustomer/get_Endcustomers", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseEndCustomers(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchAssociatedEndCustomers(intergratorNumbers: string[]): Promise<any[]> {
        const data = {integrators: intergratorNumbers};
        const ajaxRequest = super.createAjaxRequest("POST", 
                "Endcustomer/get_endcustomer_associated_to_integrators", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseEndCustomers(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchAssocEndCustomersToDistributors(loggedUserType: UserType, 
                    intergratorNumbers: string[], distributorNo: string | undefined): Promise<any[]> {
        if(distributorNo === null) distributorNo = undefined;
        const data = {
            integrators: intergratorNumbers,
            DistributorNo: distributorNo
        };
        let requestUrl = "Endcustomer/get_Endcustomers_of_Distributor";
        if(loggedUserType === "Integrator" && (distributorNo == null || distributorNo == undefined)) {
            requestUrl = "Endcustomer/get_endcustomer_associated_to_integrators";
        }
        const ajaxRequest = super.createAjaxRequest("POST", requestUrl, data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseEndCustomers(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchDistributors(): Promise<any[]> {
        const data = {};
        const ajaxRequest = super.createAjaxRequest("POST", "Distributor/get_Distributors", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseDistributors(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchAssociatedDistributors(integratorNo: string): Promise<any[]> {
        const data = {IntegratorNo: integratorNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Distributor/get_Distributor_of_integrator",
                                data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseDistributors(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchFirmwareList(hardwareCode: string): Promise<any[]> {
        const data = {HardwareCode: hardwareCode};
        const ajaxRequest = super.createAjaxRequest("POST", "Firmware/get_Requestedfirmwareno", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseFirmware(response);
    }

     // GMap Methods
    ////////////////////////////////////////////////////////////////////////////////////////

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchUnitsForGMap(): Promise<any[]> {
        const data = {};
        const ajaxRequest = super.createAjaxRequest("GET", "WebApi/GMap", data, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        return response.response;
    }


     // Playlist Methods
    ////////////////////////////////////////////////////////////////////////////////////////

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchUnitPlaylists(serialNo: string): Promise<any[]> {
        const data = {SerialNo: serialNo};
        const ajaxRequest = super.createAjaxRequest('POST', "files/get_all_playlist", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parsePlaylists(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchUnitPlaylistSchedules(serialNo: string): Promise<any> {
        const data = {SerialNo: serialNo};
        const ajaxRequest = super.createAjaxRequest('POST', "files/get_playlist_schedules", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Units.parseSchedules(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async updateUnitDailyMuteTime(serialNo: string, enabled: boolean): Promise<void> {
        const data = {SerialNo: serialNo, EnableDailyMuteTime: enabled ? 1 : 0};
        const ajaxRequest = super.createAjaxRequest("POST", "units/update_unit", data, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async updatePlaylistSchedules(serialNo: string, schedules: object): Promise<void> {
        const data = {SerialNo: serialNo, PlaylistNo: 2, ...schedules};
        const ajaxRequest = super.createAjaxRequest("POST", "files/update_playlist_schedules", data, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async updatePlaylists(serialNo: string, playlists: any[], dailyMuteEnabled: boolean): Promise<void> {
        const data = NetworkHelper.Units.formatPlaylistUpdateRequest(playlists);
        const ajaxData = {SerialNo: serialNo, EnableDailyMuteTime: dailyMuteEnabled ? 1 : 0, ...data};
        const ajaxRequest = super.createAjaxRequest("POST", "files/update_playlist", ajaxData, "json");
        await super.callAjax(ajaxRequest);
    }

     // Unit Group Methods
    ////////////////////////////////////////////////////////////////////////////////////////

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchUnitGroups(serialNo: string): Promise<any[]> {
        const data = {SerialNo: serialNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Units/get_unit_group", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.UnitGroups.parseUnitGroupsEnumeration(response);
    }

     // METHODS FOR FUTURE USE
    ////////////////////////////////////////////////////////////////////////////////////////

    @RestrictAccess([])
    async fetchUnitStatistics(serialNo: string): Promise<any[]> {
        const data = {SerialNo: serialNo};
        const ajaxRequest = super.createAjaxRequest("POST", "units/get_unit_stats", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.parseUnitStatisticsResponse(response);
    }

    @RestrictAccess([])
    async saveUnit(unitDTO: any): Promise<void> {
        const data = NetworkHelper.formatSaveUnitRequest(unitDTO);
        const ajaxRequest = super.createAjaxRequest("POST", "units/save_unit", data, "json");
        const response = await super.callAjax(ajaxRequest);
        // TODO: Evaluate possible error in response
    }


    @RestrictAccess([])
    async fetchUnitsLog(serialNo: string): Promise<any> {
        const data = {SerialNo: serialNo};
        const ajaxRequest = super.createAjaxRequest("GET", "units/get_log", data, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.parseUnitsLogResponse(response);
    }

    @RestrictAccess([])
    async updateUnitPlaylist(playlistDTO: any): Promise<void> {
        const data = NetworkHelper.formatUpdatePlaylistRequest(playlistDTO);
        const ajaxRequest = super.createAjaxRequest("POST", "files/update_playlist", data, "json");
        const response = await super.callAjax(ajaxRequest);
        // TODO: Evaluate possible error in response
    }

    @RestrictAccess([])
    async updateUnitPlaylistSchedules(playlistDTO: any): Promise<void> {
        const data = NetworkHelper.formatUpdatePlaylistSchedulesRequest(playlistDTO);
        const ajaxRequest = super.createAjaxRequest("POST", "files/update_playlist_schedules", data, "json");
        const response = await super.callAjax(ajaxRequest);
        // TODO: Evaluate possible error in response
    }

    @RestrictAccess([])
    async updateUnitsInGroup(unitsInGroupDTO: any): Promise<void> {
        const data = NetworkHelper.formatUpdateUnitsInGroupRequest(unitsInGroupDTO);
        const ajaxRequest = super.createAjaxRequest("POST", "units/update_units_in_group", data, "json");
        const response = await super.callAjax(ajaxRequest);
        // TODO: Evaluate possible error in response
    }

    @RestrictAccess([])
    async updatePlaylistForGroup(playlistDTO: any): Promise<void> {
        const data = NetworkHelper.formatPlaylistForGroupRequest(playlistDTO);
        const ajaxRequest = super.createAjaxRequest("POST", "files/update_playlist_on_group", data, "json");
        const response = await super.callAjax(ajaxRequest);
        // TODO: Evaluate possible error in response
    }

    @RestrictAccess([])
    async updatePlaylistSchedulesForGroup(playlistSchedulesDTO: any): Promise<void> {
        const data = NetworkHelper.formatPlaylistSchedulesForGroupRequest(playlistSchedulesDTO);
        const ajaxRequest = super.createAjaxRequest("POST", "files/update_playlist_schedules_on_group", data, "json");
        const response = await super.callAjax(ajaxRequest);
        // TODO: Evaluate possible error in response
    }
}
