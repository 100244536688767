import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DataListAction } from "../forms/ActionsDataList";
import ActionsDataList from "../forms/ActionsDataList";
import { Column } from "primereact/column";
import LoadingProgress from "../common/LoadingProgress";
import * as _ from "lodash";
import { SearchBox } from "../common/AppSearchBox";
import {Button} from "primereact/button";
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method

// Import StyleSheet
import "./UnitGroupDataList.scss";
import { createNewUnitGroup, deleteUnitGroup, editUnitGroup, fetchUnitGroups, filterUnitGroups } from "./UnitGroupsReduxSlice";

// Unit Group Header Component
const FILTER_FUNCTION_DEBOUNCE = 200;

const UnitGroupdDataListHeader = () => {
    // Dispatching Unit Group Filtering
    const dispatch = useDispatch();
    const invokeSoundFiltering = _.debounce((phrase: string) => {
        dispatch(filterUnitGroups(phrase));
    }, FILTER_FUNCTION_DEBOUNCE);

    return (
        <div className="UnitGroupDataListHeader">
            <SearchBox placeholder="Search unit groups..." 
                onChange={(value: string) => invokeSoundFiltering(value)} ></SearchBox>
            <div></div>
            <Button label="New Unit Group" className="p-button-success"
                  onClick={e => dispatch(createNewUnitGroup())}  ></Button>
        </div>
    )
}

// Unit Group Table Component
const UnitGroupActions: DataListAction[] = [
    {name: "edit", label: "Edit", className: "p-button-secondary"},
    {name: "delete", label: "Delete", className: "p-button-danger"}
];

const UnitGroupDataListTable = () => {
    // Invoke the first fetch of unit groups
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUnitGroups(undefined));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const unitGroups = useSelector((state: any) => state.unitGroups.shownUnitGroups);

    // Delete Confirmation Function
    const confirmDelete = (id: number) => {
        confirmDialog({
            message: 'Are you sure you want to delete this unit group?',
            header: 'Delete Unit Group',
            icon: 'pi pi-exclamation-triangle',
            style: {'width': '30rem'},
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            rejectClassName: 'p-button-secondary',
            accept: () => dispatch(deleteUnitGroup(id))
        });
    }    

    // Handle Action Click
    const handleActionClick = (data: {name: string, id: number}) => {
        switch(data.name) {
            case "edit": dispatch(editUnitGroup(data.id)); break;
            case "delete": confirmDelete(data.id); break;
            default: throw new Error(`Action Handler not defined for ${data.name}`);
        }
    }

    return (
        <ActionsDataList value={unitGroups} dataKey="id" actions={UnitGroupActions} actionColumnWidth="14rem"
                       onAction={handleActionClick} >
            <Column header="Id" field="Id" sortable style={{maxWidth: "7rem", textAlign: "center"}} />
            <Column header="Name" field="name" sortable style={{maxWidth: "auto", textAlign: "left"}} />
            <Column header="End Customer" field="endCustomerName" sortable style={{maxWidth: "40%", textAlign: "left"}} />
        </ActionsDataList>
    );
}

// Unit Group Page Component
const UnitGroupDataList = () => {
    const isLoading = useSelector((state: any) => state.unitGroups.loadingUnitGroups);

    return (
        <div className="UnitGroupDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <UnitGroupdDataListHeader />
            <div className="UnitGroupDataListHeader--Separator" />
            <UnitGroupDataListTable />
        </div>

    );
};

export default UnitGroupDataList;
