

export class DateHelper {

    static formatDateToISO(date: Date) {
        let year = date.getFullYear();
        let month = this.pad0(date.getMonth() + 1);
        let day = this.pad0(date.getDate());
        let hour = this.pad0(date.getHours());
        let minute = this.pad0(date.getMinutes());
        let seconds = this.pad0(date.getSeconds());
        return `${year}-${month}-${day} ${hour}:${minute}:${seconds}`;
    }

    private static pad0(value: any) {
        const str_value = String(value);
        return str_value.length === 1 ? `0${str_value}` : str_value;
    }
}