import React from "react";

import "./NotImplementedPage.scss"

const NotImplementedPage = () => {
    return (
        <div className="NotImplementedPage">
            <span>You have tried to access a non-existent page.</span>
        </div>
    )
}

export default NotImplementedPage;