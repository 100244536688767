import { NetworkHelper } from "../../helpers/NetworkHelper";
import { FirmwareNetworkAPI } from "../NetworkInterfaces";
import { RestrictAccess } from "../NetworkSecurity";
import { CupuNetworkAPIBase } from "./CupuNetworkAPIBase";

export class CupuFirmwareNetworkAPI extends CupuNetworkAPIBase implements FirmwareNetworkAPI {

    @RestrictAccess(["Admin"])
    async fetchFirmwares() {
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "firmware/get_files", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Firmware.parseFirmwareResponse(response);
    }

    @RestrictAccess(["Admin"])
    async getHardwareCodes() {
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("POST", "Cuputypes/get_Hardwarecode", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Firmware.parseHardwareCodeResponse(response);
    }

    @RestrictAccess(["Admin"])
    async getRequestedFirmwareNumber(hardwareCode: string) {
        const requestData = {HardwareCode: hardwareCode};
        const ajaxRequest = super.createAjaxRequest("POST", "Firmware/get_Requestedfirmwareno", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Firmware.parseRequestedFirmwareNumberResponse(response);
    }

    @RestrictAccess(["Admin"])
    async setDefaultFirmware(hardwareCode: string, firmwareNo: string) {
        const requestData = {HardwareCode: hardwareCode, FirmwareNo: firmwareNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Firmware/set_default_firmware", requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    // TODO: rework to base createAjaxUploadRequest
    @RestrictAccess(["Admin"])
    async uploadFirmware(firmwareData: any) {
		var form_data = new FormData();
        for(let key in firmwareData) {
            if(key === "CRC")
                continue;
            form_data.append(key, firmwareData[key]);
        }
        const ajaxRequest = super.createAjaxRequest("POST", "firmware/save_file", form_data, "json");
        ajaxRequest.processData = false;
        ajaxRequest.contentType = false;
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin"])
    async deleteFirmware(firmwareNo: string) {
        const requestData = {FirmwareNo: firmwareNo};
        const ajaxRequest = super.createAjaxRequest("POST", "firmware/delete_file", requestData, "json");
        await super.callAjax(ajaxRequest);
    }
}
