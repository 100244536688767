import React from "react";
import { Dialog } from "primereact/dialog";
import { Button} from "primereact/button";
import { InputTextarea } from "primereact/inputtextarea";
import { useDispatch, useSelector } from "react-redux";

import "./ExceptionDialog.scss";
import { hideExceptionDialog } from "../AppReduxSlice";

const ExceptionDialog = (props: any) => {
    const exceptionInfo = useSelector((state: any) => state.app.exceptionInfo);

    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(hideExceptionDialog(null));
    }

    const DialogFooter = () => {
        return (
            <div className="ExceptionDialog-Footer">
                <Button label="Close" className="p-button-danger"  onClick={e => handleClose()} />
            </div>
        )
    }
    
    return (
        <Dialog modal visible header={exceptionInfo.title} style={{width: "45rem"}}
                footer={DialogFooter} onHide={() => handleClose()}  >
            <div className="ExceptionDialog-Body">
                <span>{exceptionInfo.message}</span>
                { exceptionInfo.text && (
                    <InputTextarea readOnly value={exceptionInfo.text} rows={10} />
                ) } 
                { exceptionInfo.html && (
                    <div dangerouslySetInnerHTML={{__html: exceptionInfo.html}}></div>
                )}
            </div>
        </Dialog>
    );
}

export default ExceptionDialog;
