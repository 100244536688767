import React from "react"

const ActionLink = (props: any) => {
    return (
        <a href="#/" className={props.className} onClick={e => {e.preventDefault(); props.onClick();}}>
            {props.children}
        </a>
    )
}

export default ActionLink;
