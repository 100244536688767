import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingProgress from "../common/LoadingProgress";
import {Button} from "primereact/button";

import "./ServiceLogDataList.scss";
import { NavigationHelper } from "../helpers/NavigationHelper";
import { useParams } from "react-router";
import { fetchServiceLogs } from "./LogsReduxSlice";
import ActionsDataList from "../forms/ActionsDataList";
import { Column } from "primereact/column";

const ServiceLogHeader = () => {

    const {serialNumber} = useParams<any>();

    const handleGoBack = () => {
        NavigationHelper.goBack();
    }

    const handleCreateNewEvent = () => {
        NavigationHelper.toAppUrl(`/units/servicelog/${serialNumber}/add-event`)
    }

    return (
        <div className="ServiceLogHeader">
            <Button icon="fas fa-angle-double-left" onClick={e => handleGoBack()}
                    className="p-button-secondary" tooltip="Navigate back" tooltipOptions={{position: "bottom"}}   ></Button>
            <div>Service Log</div>
            <Button label="Add New Event" className="p-button-success"
                onClick={handleCreateNewEvent} />
        </div>
    )
}

const ServiceLogDataListTable = () => {
    const {serialNumber} = useParams<any>();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchServiceLogs(serialNumber));
    }, []);

    const handleActionClick = (data: {name: string, id: any}) => {}

    const records: any[] = useSelector((state: any) => state.logs.serviceLogs);

    return (
        <ActionsDataList value={records} actions={null} actionColumnWidth="0rem"
                       onAction={handleActionClick} stripedRows >
            <Column header="SerialNo" field="SerialNo" style={{width: "33%", textAlign: "left"}} />
            <Column header="Timestamp" field="Timestamp"  style={{width: "33%", textAlign: "left"}} />
            <Column header="Event Text" field="EventText" style={{width: "auto", textAlign: "left"}} />
        </ActionsDataList>
    );
}


const ServiceLogDataList = () => {
    const isLoading = useSelector((state: any) => state.logs.loadingLogs);

    return (
        <div className="ServiceLogDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <ServiceLogHeader />
            <div className="ServiceLogDataList--Separator" />
            <div className="ServiceLogDataList--Body">
                <ServiceLogDataListTable />
            </div>
        </div>
    )
}

export default ServiceLogDataList;
