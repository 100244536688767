import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';
import { FilterHelper } from '../helpers/FilterHelper';
import * as _ from "lodash";
import { NavigationHelper } from '../helpers/NavigationHelper';

// Network API for Orders agenda
const ordersAPI = NetworkManager.getOrdersAPI();

// Load orders from the server
export const fetchOrders = createAsyncThunk(
    'orders/fetchOrders',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateOrderLoadingStatus(true));
       
        const ordersData = await ordersAPI.fetchOrders();

        dispatch(updateFetchedOrders(ordersData));
        dispatch(updateOrderLoadingStatus(false));
    }
);

// Fulltext filtering of orders
export const filterOrdersByPhrase = createAsyncThunk(
    'orders/filterOrdersByPhrase',
    async (phrase: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { orders } = thunkAPI.getState() as any;

        const filteredOrders = FilterHelper.filterRecordsUsingPhrase(phrase, orders.fetchedOrders,
            ["OrderNo", "IntegratorName", "IntegratorOrderNo", "Description", "QuantityOrdered"]);
        dispatch(updateShownOrders(filteredOrders));
    }
);

// Set Order in Production
export const setOrderInProduction = createAsyncThunk(
    'orders/setOrderInProduction',
    async (OrderNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        await ordersAPI.setOrderInProduction(OrderNo);
        dispatch(fetchOrders());
    }    
);

// Fetch Integrator list for Create Order Form
export const fetchOrderIntegrators = createAsyncThunk(
    'orders/fetchOrderIntegrators',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const integrators = await ordersAPI.getAllIntegrators();
        dispatch(updateOrderIntegrators(integrators));
    }    
);

// UseCase implementation for showing CreateOrder Form
export const createNewOrder = createAsyncThunk(
    'orders/createNewOrder',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(fetchOrderIntegrators());
        NavigationHelper.toAppUrl("/orders/create");
    }    
);

// UseCase implementation for SaveOrder
export const saveNewOrder = createAsyncThunk(
    'orders/saveNewOrder',
    async (orderData: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        await ordersAPI.saveOrder(orderData);
        dispatch(fetchOrders());
        NavigationHelper.goBack();
    }    
);

// UseCase implementation of Fetching Units in Production
export const fetchUnitsInProduction = createAsyncThunk(
    'orders/fetchUnitsInProduction',
    async(payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateOrderLoadingStatus(true));

        try {
            const unitsInProduction = await ordersAPI.getInProductionOrdersNumber();
            if(unitsInProduction > 0) {
                const orderInProduction = await ordersAPI.getInProductionOrder();
                const units = await ordersAPI.getUnitsOfOrder(orderInProduction.OrderNo);
                console.dir(units);
                const qtyOrdered = parseInt(orderInProduction.QuantityOrdered);                
                const qtyRemaining = qtyOrdered - units.length;

                dispatch(updateQuantities({qtyOrdered, qtyRemaining}));               
                dispatch(updateUnitsInProduction(units));
            } else {
                dispatch(updateQuantities({qtyOrdered: 0, qtyRemaining: 0}));               
                dispatch(updateUnitsInProduction([]));
            }
        }
        finally {
            dispatch(updateOrderLoadingStatus(false));
        }

    }
);

// UseCase implementation of Fetching Units in Production
export const filterUnitsInProduction = createAsyncThunk(
    'orders/filterUnitsInProduction',
    async(phrase: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { orders } = thunkAPI.getState() as any;

        const filteredUnits = FilterHelper.filterRecordsUsingPhrase(phrase, orders.unitsInProduction,
            ["SerialNo", "TestDate", "StatusTimestamp", "StatusText"]);
        dispatch(updateShownUnitsInProduction(filteredUnits));
    }
);

// Initial Orders Agenda State
const INITIAL_STATE = {
    shownOrders: [],
    fetchedOrders: [],
    loadingOrders: false,
    openedOrder: {},
    orderIntegrators: [],
    orderInProductionNo: null,
    unitsInProduction: [],
    shownUnitsInProduction: [],
    qtyOrdered: 0,
    qtyRemaining: 0
}

// Redux Slice for Distributors Module
export const ordersSlice = createSlice({
    name: 'orders',
    initialState: INITIAL_STATE,
    reducers: {
        updateOrderLoadingStatus: (state, action) =>{
            state.loadingOrders = action.payload;
        },
        updateFetchedOrders: (state, action) => {
            state.fetchedOrders = action.payload;
            state.shownOrders = _.cloneDeep(action.payload);
            const orderInProduction = action.payload.find((order: any) => order.InProduction === "1");
            state.orderInProductionNo = orderInProduction === undefined ? null : orderInProduction.OrderNo;
        },
        updateShownOrders: (state, action) => {
            state.shownOrders = action.payload;
        },
        updateOpenedOrder: (state, action) => {
            state.openedOrder = action.payload;
        },
        updateOrderIntegrators: (state, action) => {
            state.orderIntegrators = action.payload;
        },
        updateUnitsInProduction: (state, action) => {
            state.unitsInProduction = action.payload;
            state.shownUnitsInProduction = _.cloneDeep(action.payload);
        },
        updateShownUnitsInProduction: (state, action) => {
            state.shownUnitsInProduction = action.payload;
        },
        updateQuantities: (state, action) => {
            state.qtyOrdered = action.payload.qtyOrdered;
            state.qtyRemaining = action.payload.qtyRemaining;
        }
    }
});

export const {
    updateFetchedOrders,
    updateOpenedOrder,
    updateOrderIntegrators,
    updateOrderLoadingStatus,
    updateShownOrders,
    updateShownUnitsInProduction,
    updateUnitsInProduction,
    updateQuantities
} = ordersSlice.actions

export default ordersSlice.reducer
