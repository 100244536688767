import { NetworkHelper } from "../../helpers/NetworkHelper";
import type { UnitGroupDataRow, UnitGroupDTO, UnitGroupNetworkAPI, UnitsInGroupDTO } from "../NetworkInterfaces";
import { RestrictAccess } from "../NetworkSecurity";
import { CupuNetworkAPIBase } from "./CupuNetworkAPIBase";

export class CupuUnitGroupNetworkAPI extends CupuNetworkAPIBase implements UnitGroupNetworkAPI {

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchUnitGroups(): Promise<UnitGroupDataRow[]> {
        const ajaxRequest = super.createAjaxRequest("POST", "Unit_groups/get_unit_groups", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.UnitGroups.parseUnitGroups(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchAvailUnitsForEndCustomer(id: number): Promise<UnitsInGroupDTO[]> {
        const ajaxData = { EndCustomerNo: id.toString() };
        const ajaxRequest = super.createAjaxRequest("POST", "Units/get_endcustomers_associated_units", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.UnitGroups.parseEndCustomerUnits(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchGroupUnits(id: number): Promise<UnitsInGroupDTO[]> {
        const ajaxData = {UnitGroupNo: id};
        const ajaxRequest = super.createAjaxRequest("POST", "Unit_groups/get_groups_units", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.UnitGroups.parseUnitGroupUnits(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async saveUnitGroup(field: UnitGroupDTO): Promise<void> {
        const ajaxData = NetworkHelper.UnitGroups.formatUnitGroupSaveRequest(field);
        const ajaxRequest = super.createAjaxRequest("GET", "Unit_groups/save_unit_group", ajaxData, "json");
         await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async deleteUnitGroup(id: number): Promise<void> {
        const ajaxData = {UnitGroupNo: id};
        const ajaxRequest = super.createAjaxRequest("POST", "Unit_groups/delete_units_group", ajaxData, "json");
        await super.callAjax(ajaxRequest);
    }
}
