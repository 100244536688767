import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';
import { FilterHelper } from '../helpers/FilterHelper';
import * as _ from 'lodash';
import { NavigationHelper } from '../helpers/NavigationHelper';

// Network API - contains method to fetch users and their types from the server
const usersNetworkAPI = NetworkManager.getUsersNetworkAPI();

// Asynchrounous methods waiting for HTTP request to complete and then update Store data
export const fetchIntegrators = createAsyncThunk(
    'integrators/fetchIntegrators',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingIntegratorsStatus(true));
       
        const integratorsData = await usersNetworkAPI.fetchIntegrators();

        dispatch(updateFetchedIntegrators(integratorsData));
        dispatch(updateLoadingIntegratorsStatus(false));
    }
);

// Filters integrators  records by text
export const filterIntegratorsByPhrase = createAsyncThunk(
    'integrators/filterIntegratorsByPhrase',
    async (phrase: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { integrators } = thunkAPI.getState() as any;

        const filteredIntegrators = FilterHelper.filterRecordsUsingPhrase(phrase, integrators.fetchedIntegrators,
            ["IntegratorNo", "Name", "LoginText"]);
        dispatch(updateShownIntegrators(filteredIntegrators));
    }
);

export const createNewIntegrator = createAsyncThunk(
    'integrators/createNewIntegrator',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateEditedIntegrator({Name: "", LoginText: "", WelcomeText: "", IntegratorLogo: null}));
        NavigationHelper.toAppUrl("/integrators/edit/")
    }
);

export const editIntegrator = createAsyncThunk(
    'integrators/editIntegrator',
    async (id: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { integrators } = thunkAPI.getState() as any;
        const fetchedIntegrators: any[] = integrators.fetchedIntegrators;

        // Find edited Sound DataRow
        let editedIntegrator = fetchedIntegrators.find(endC => endC.IntegratorNo === id);
        if(editedIntegrator === undefined)
            throw new Error("editedIntegrator to edit was not found in fetched sounds.");        

        // Update edited sound data to the store
        editedIntegrator = _.cloneDeep(editedIntegrator);
        if(editedIntegrator.IntegratorLogo === "") {
            editedIntegrator.IntegratorLogo = null;
        }

        dispatch(updateEditedIntegrator(editedIntegrator));

        // Finally navigate to Edit Form
        NavigationHelper.toAppUrl(`/integrators/edit/`);       
    }
);

export const saveIntegrator = createAsyncThunk(
    'integrators/saveIntegrator',
    async (integratorData: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingIntegratorsStatus(true));       
        try {
            if(integratorData.IntegratorLogo instanceof File) {
                integratorData.IntegratorLogo = await usersNetworkAPI.saveIntegratorLogo(integratorData.IntegratorLogo);
            } else {
                if(integratorData.IntegratorLogo === null) {
                    if(integratorData.IntegratorNo !== undefined) {
                        await usersNetworkAPI.deleteIntegratorLogo(integratorData.IntegratorNo);
                    }
                    delete integratorData.IntegratorLogo;
                } else {
                    delete integratorData.IntegratorLogo;
                }
            }
            
            await usersNetworkAPI.saveIntegrator(integratorData);
            NavigationHelper.toAppUrl("/integrators");   
        }
        finally {
            dispatch(updateLoadingIntegratorsStatus(false));
        }
    }
);

export const deleteIntegrator = createAsyncThunk(
    'integrators/deleteIntegrator',
    async (integratorNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingIntegratorsStatus(true));       
            await usersNetworkAPI.deleteIntegrator(integratorNo);
        }
        finally {
            dispatch(updateLoadingIntegratorsStatus(false));
        }
        dispatch(fetchIntegrators())
    }
);

// Initial Integrators Store State
const INITIAL_STATE = {
    shownIntegrators: [],
    fetchedIntegrators: [],
    loadingIntegrators: false,
    editedIntegrator: {}
}

// Redux Slice for Integrators Module
export const integratorsSlice = createSlice({
    name: 'integrators',
    initialState: INITIAL_STATE,
    reducers: {
        updateLoadingIntegratorsStatus: (state, action) => {
            state.loadingIntegrators = action.payload;
        },
        updateFetchedIntegrators: (state, action) => {
            state.fetchedIntegrators = action.payload;
            state.shownIntegrators = _.clone(action.payload);
        },
        updateShownIntegrators: (state, action) => {
            state.shownIntegrators = action.payload;
        },
        updateEditedIntegrator: (state, action) => {
            state.editedIntegrator = action.payload;
        },
    }
});

// Action creators are generated for each case reducer function
export const {
    updateLoadingIntegratorsStatus,
    updateFetchedIntegrators,
    updateShownIntegrators,
    updateEditedIntegrator
} = integratorsSlice.actions

export default integratorsSlice.reducer
