import React, { useRef } from "react";
import GenericContentForm, {ContentFormStandardButtons} from "../../forms/GenericContentForm";
import LoadingProgress from "../../common/LoadingProgress";
import { useDispatch, useSelector } from "react-redux";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { fetchEndCustomerUnits, updateUnitGroup } from "../UnitGroupsReduxSlice";

// Formik and Yup Schema Imports
import { Formik, Form } from "formik";
import  { number, object, string, array } from "yup";

// Adapted Components to Formik
import { FormikTextField, FormikDropdownField, FormikPickListField } from "../../forms/formik";

// Style Import
import "./EditUnitGroupForm.scss";
import { useSelectorEnum } from "../../helpers/HookHelpers";

// Let's create form validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    name: string().required("Please, fill in the name of unit group."),
    endCustomerId: number().nullable().required("Please, select the end customer."),
    selectedUnits: array().min(1, "Please, select any units for the unit group.")
});

const EditUnitGroupForm = () => {
    const dispatch = useDispatch();

    // Select data out of store
    const initialValues = useSelector((state: any) => state.unitGroups.editedUnitGroup); 

    // Select enumerations
    const endCustomerOptions = useSelector((state: any) => state.unitGroups.relatedUsers).map((user: any) => {
        return {label: user.userName, value: user.userNo}; });
    const tempAvailableUnits = useSelectorEnum((state: any) => state.unitGroups.availableUnits);
    const associatedUnits = useSelectorEnum((state: any) => state.unitGroups.associatedUnits);

    // Remove from available unit list the associated units
    const availableUnits = tempAvailableUnits.filter((availUnit: any) => {
        return associatedUnits.find((u: any) => u.value === availUnit.value) === undefined;
    });
    const isLoading = useSelector((state: any) => state.unitGroups.loadingUnitGroupForm);

    // Event Handlers
    const handleSubmit = (values: any) => {
        dispatch(updateUnitGroup(values));
    }
    const handleReject = () => {
        NavigationHelper.toAppUrl("/unit-groups");
    }
    const handleEndCustomerChange = (e: any) => {
        dispatch(fetchEndCustomerUnits(e.value));
    }

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        const acceptLabel = initialValues.id === undefined || initialValues.id === null ? "Create" : "Update";
        return (
            <ContentFormStandardButtons  
                acceptLabel={acceptLabel} onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const FormBody = () => {
        return (
            <div className="EditUnitGroupForm-Body">
                <div className="EditUnitGroupForm-LeftPane" >
                    <FormikTextField label="Name of Unit Group:" name="name" 
                        placeholder="Enter name of unit group..." ></FormikTextField>
                    <FormikDropdownField label="Associated To:" name="endCustomerId"  options={endCustomerOptions}
                        placeholder="--- Select end customer ---"  appendTo="self" 
                        onChange={(e: any) => handleEndCustomerChange(e)}  />
                </div>
                <div className="EditUnitGroupForm-PickList">
                    <FormikPickListField source={availableUnits} target={associatedUnits} name="selectedUnits"
                             sourceHeader="Available Units:" targetHeader="Units in Group:"   />
                </div>
            </div>
        );
    };
    
    return (
        <GenericContentForm title="Unit Group Details" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={initialValues} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditUnitGroupForm;
