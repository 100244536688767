import { Form, Formik } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { object, string } from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { FormikTextAreaField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { saveServiceEvent } from "../LogsReduxSlice";

import "./AddNewEventForm.scss";

const FORM_VALIDATION_SCHEMA = object().shape({
    EventText: string().required("Please, enter the service event description.")
});


const AddNewEventForm = () => {
    const {serialNumber} = useParams<any>();
    const isLoading = useSelector((state: any) => state.logs.loadingLogs);

    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        const data = {
            SerialNo: serialNumber,
            EventText: values.EventText,
            Timestamp: new Date().toLocaleString( 'sv', { timeZoneName: 'short' })
        }
        dispatch(saveServiceEvent(data));
    }

    const handleReject = () => {
        NavigationHelper.goBack();
    }

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        return (
            <ContentFormStandardButtons  
                acceptLabel="Create" onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const FormBody = () => {
        return (
            <div style={{"display": "grid"}}>
                <div className="AddNewFormEventForm--Body">
                    <FormikTextAreaField label="Description:" placeholder="Enter service event description...."
                            name="EventText" rows={15} />
                </div>
            </div>
        );
    }

    return (
        <GenericContentForm title="Create New Service Event" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={{}} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default AddNewEventForm;
