import React from "react";
import { useAuth } from "./Auth";
import { Route, Redirect } from "react-router"

const MasterRoute = ({component, ...rest}: any) => {
    const auth: any = useAuth();
    const isLoggedIn = auth.isLoggedIn();

    const Component = component;

    return (
        <Route {...rest} children={({ match, ...rest }: any) => {
            if(match === null) return null;

            if(isLoggedIn) {
                return (
                    <div>
                        <Component />
                    </div>
                );
            } else {
                return ( <Redirect path="*" to={{ pathname: "/login"}} />);
            }
        }} />
    );
}

export default MasterRoute;
