import React, { useState } from "react"
import { Slider } from "primereact/slider";

// Import SoundVolume Component Style
import "./VolumeControl.scss";

// Sound Volume Control Component
const VolumeControl = (props: any) => {
    const [value, setValue] = useState(props.value);

    const updateValue = (value: any) => {
        setValue(value);
        if(typeof props.onChange === "function")
            props.onChange(value);
    }

    return (
        <div className="VolumeControl" style={{"position": "relative"}}>    
            <span><i className="fas fa-2x fa-volume-down"></i></span>            
            <Slider min={props.min} max={props.max} value={value}
                    onChange={e => updateValue(e.value as any)} ></Slider>
            <span><i className="fas fa-2x fa-volume-up"></i></span>            
        </div>
    );
}

export default VolumeControl;
