import { AjaxHelper } from "./AjaxHelper";

export class OrdersNetworkHelper {

    parseOrdersResponse(response: any) {
        return AjaxHelper.parseAjaxResponse(response, ["Orders"], "OrderNo");
    }

    parseIntegrators(response: any) {
        return AjaxHelper.parseAjaxResponse(response, ["Integrators"], "IntegratorNo");
    }

    parseUnitsInProductionResponse(response: any) {
        return response.response_data;
    }
}
