import { defaultDefaultOptions } from "clocklet/es/options";
import { SoundDTO } from "../../network/NetworkInterfaces";
import { UploadSoundAction } from "../NetworkHelper";
import { AjaxHelper } from "./AjaxHelper";

export class SoundsNetworkHelper {

    parseSounds(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["files"], "FileNo");
    }

    parseSoundsEnum(response: any): any[] {
        return AjaxHelper.parseAjaxEnumResponse(response, [], "FileNo", "Description");
    }

    private parseSoundFile(response: any, file: any) {
        return {
            id: parseInt(file.FileNo),
            fileNumber: file.FileNo,
            description: file.Description,
            fileName: file.FileName, 
            CRC: file.CRC,
            factoryDefaults: file.FactoryDefault !== "0",
            endCustomerId: parseInt(file.EndCustomerNo),
            PlaylistPopularity: file.PlaylistPopularity,
            fileIndex: response.response_data.indexed_files[file.FileNo]
        }
    }

    formatSoundUploadRequest(dto: any, action: UploadSoundAction, oldFileName?: string): any {
        return { ...dto, 
            hidfileno: dto.FileNo,
            hidaction: action,
            hidoldfile: oldFileName, 
            myfile: dto.fileContent,
        };
    }

    formatSoundUpdateRequest(dto: any): any {
        return { ...dto, 
            hidfileno: dto.FileNo,
            hidaction: "edit",
            hidoldfile: dto.FileName,
            myfile: new Blob()
        };
    }
}
