import { useState } from "react";
import { useEffect } from "react";

// Import the CSS classes for styling
import "./AvatarColumn.scss";

// Generic image column component properties
export interface ImageColumnProps {
    downloadUrlPrefix: string;
    src: string;
    emptySrc: string;
    maxWidth: number;
    maxHeight: number;
}

const isImageDefined = (src: any) => {
    return src instanceof File || ! ( src === null || src === undefined || src.trim() === "");
}

function formatImageUrl(downloadUrlPrefix: string, imageUrl: string | File) {
    if(imageUrl instanceof File) {
        return URL.createObjectURL(imageUrl);
    }
    if(downloadUrlPrefix.endsWith("/")) 
        downloadUrlPrefix = downloadUrlPrefix.slice(0, downloadUrlPrefix.length - 1);
    if(imageUrl.startsWith("/")) imageUrl = imageUrl.slice(1);
    if(downloadUrlPrefix.trim().length === 0) return imageUrl;
    return downloadUrlPrefix + "/" + imageUrl;
}

// Generic image column component
const ImageColumn = (props: ImageColumnProps) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);

    useEffect(() => {
        if(isImageDefined(props.src) === false) {
            setImageSrc(props.emptySrc);
        } else {
            const fullImageUrl = formatImageUrl(props.downloadUrlPrefix, props.src);
            setImageSrc(fullImageUrl);
        }
    }, [props.src]);

    const styleImageElement = {
        "maxWidth": `${props.maxWidth}px`,
        "maxHeight": `${props.maxHeight}px`,
    }

    const styleLoadingProgress = {
        "width": `${props.maxWidth}px`,
        "height": `${props.maxHeight}px`,
    }


    return (
        <div className="ImageColumn"> 
            { 
                imageSrc !== null ? 
                ( 
                    <img className="ImageColumn--Image" 
                         style={styleImageElement} 
                         alt="none" src={imageSrc}
                         onError={() => { setImageSrc(props.emptySrc) }} /> 
                ) : (
                    <div style={styleLoadingProgress} className="ImageColumn--Loading-Progress" >
                        <i  className="fa fa-5x fa-spinner fa-pulse"></i>
                    </div> 
                )
            }
        </div>
    );
}


// Set the maximum default dimensions and empty avatar image
const MAX_AVATAR_WIDTH = 100;
const MAX_AVATAR_HEIGHT = 100;
const EMPTY_AVATAR_PATH = "empty-avatar.jpeg";

export interface AvatarColumnProps {
    downloadUrlPrefix: string;
    src: string;
    maxImageSize?: number;
}

// This component is used for displaying a user's avatar. If no image present, then 
// the default placeholder image is used.
const AvatarColumn = (props: AvatarColumnProps) => {
    const maxWidth = props.maxImageSize === undefined ? MAX_AVATAR_WIDTH : props.maxImageSize;
    const maxHeight = props.maxImageSize === undefined ? MAX_AVATAR_HEIGHT : props.maxImageSize;
    
    return (
        <ImageColumn downloadUrlPrefix={props.downloadUrlPrefix} src={props.src} emptySrc={EMPTY_AVATAR_PATH} 
                maxWidth={maxWidth} maxHeight={maxHeight} />
    );
}

export default AvatarColumn;
