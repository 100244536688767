import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NavigationHelper } from '../helpers/NavigationHelper';
import { ApiLogFetchOptions } from '../network/NetworkInterfaces';
import { NetworkManager } from '../network/NetworkManager';

const logsNetworkAPI = NetworkManager.getLogsNetworkAPI();

export const fetchApiLogCount = createAsyncThunk(
    'logs/fetchApiLogCount',
    async (serialNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingStatus(true));
            const count = await logsNetworkAPI.queryApiLogCount(serialNo);
            dispatch(updateApiLogCount(count));
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);

export const fetchApiLogs = createAsyncThunk(
    'logs/fetchApiLogs',
    async (payload: {serialNo: string, options: ApiLogFetchOptions}, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingStatus(true));
            const data = await logsNetworkAPI.fetchApiLogs(payload.serialNo, payload.options);
            dispatch(updateApiLogs(data));
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);

export const fetchGraphApiLogs = createAsyncThunk(
    'logs/fetchGraphApiLogs',
    async (serialNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingStatus(true));
            const data = await logsNetworkAPI.fetchApiLogsLast30Days(serialNo);
            dispatch(updateGraphApiLogs(data));
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);


export const fetchServiceLogs = createAsyncThunk(
    'logs/fetchServiceLogs',
    async (serialNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingStatus(true));
            const data = await logsNetworkAPI.fetchServiceLogs(serialNo);
            dispatch(updateServiceLogs(data));
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);

export const saveServiceEvent = createAsyncThunk(
    'logs/saveServiceEvent',
    async (data: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingStatus(true));
            await logsNetworkAPI.saveServiceEvent(data);
            NavigationHelper.goBack();
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);


const INITIAL_STATE = {
    graphApiLogs: [],
    apiLogs: [],
    serviceLogs: [],
    apiLogsCount: 0,
    loadingLogs: false,
    showGraphDialog: false
}

export const logsSlice = createSlice({
    name: 'logs',
    initialState: INITIAL_STATE,
    reducers: {
        updateLoadingStatus: (state, action) => {
            state.loadingLogs = action.payload;
        },
        updateApiLogCount: (state, action) => {
            state.apiLogsCount = action.payload;
        },
        updateApiLogs: (state, action) => {
            state.apiLogs = action.payload;
        },
        updateServiceLogs: (state, action) => {
            state.serviceLogs = action.payload;
        },
        updateGraphApiLogs: (state, action) => {
            state.graphApiLogs = action.payload;
        },
        showGraphDialog: (state, action) => {
            state.showGraphDialog = action.payload;
        }
    }
});

export const {
    updateApiLogs,
    updateApiLogCount,
    updateLoadingStatus,
    updateServiceLogs,
    updateGraphApiLogs,
    showGraphDialog
} = logsSlice.actions

export default logsSlice.reducer
