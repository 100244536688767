import { NetworkHelper } from "../../helpers/NetworkHelper";
import { CupuTypesNetworkAPI } from "../NetworkInterfaces";
import { RestrictAccess } from "../NetworkSecurity";
import { CupuNetworkAPIBase } from "./CupuNetworkAPIBase";

export class CupuTypesNetworkAPIImpl extends CupuNetworkAPIBase implements CupuTypesNetworkAPI {

    @RestrictAccess(["Admin"])
    async fetchCupuTypes() {
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "cuputypes/get_Cuputypes", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.CupuTypes.parseCupuTypes(response);
    }

    @RestrictAccess(["Admin"])
    async getUnitLog(serialNo: string) {
        const requestData = {SerialNo: serialNo};
        const ajaxRequest = super.createAjaxRequest("GET", "units/get_log", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return [];
    }

    @RestrictAccess(["Admin"])
    async saveCupuType(requestData: any) {
        const url = requestData.HardwareCode === undefined ? "cuputypes/save_cuputype" : "cuputypes/update_cuputype";
        const ajaxRequest = super.createAjaxRequest("POST", url, requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    async deleteCupuType(hardwareCode: string) {
        throw new Error("DELETING OF CUPU TYPES NOT SUPPORTED YET BY BACKEND.");
    }
}
