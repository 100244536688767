import { NetworkManager } from "../../network/NetworkManager";

const loginNetworkAPI = NetworkManager.getLoginNetworkAPI();
const soundNetworkAPI = NetworkManager.getSoundNetworkAPI();

export class SoundDataFormatHelper {

    static async migrateToFormFormat(serverDTO: any): Promise<any> {
        const formDTO = {
            id: serverDTO.FileNo,
            description: serverDTO.Description,
            filename: serverDTO.FileName,
            crcCode: serverDTO.CRC
        }
        await this.migrateSoundOwnerToForm(serverDTO, formDTO);
        const entityTree = await this.fetchEntityTree(serverDTO);
        return [formDTO, entityTree];
    }

    static migrateToServerFormat(formDTO: any): any {
        const serverDTO = {
            FileNo: formDTO.id,
            Description: formDTO.description,
            FileName: formDTO.soundFile !== undefined ? formDTO.soundFile.name : formDTO.filename,
            fileContent: formDTO.soundFile
        }
        this.migrateSoundOwnerToServer(formDTO, serverDTO);
        return serverDTO;
    }
   
    private static migrateSoundOwnerToServer(formDTO: any, serverDTO: any) {
        serverDTO.EndCustomerNo = "0";
        serverDTO.DistributorNo = "0";
        serverDTO.IntegratorNo = "0";
        if(! this.isEmpty(formDTO.EndCustomerOwner)) {
            serverDTO.EndCustomerNo = formDTO.EndCustomerOwner;
        } else if(! this.isEmpty(formDTO.DistributorOwner)) {
            serverDTO.DistributorNo = formDTO.DistributorOwner;
        } else if(! this.isEmpty(formDTO.IntegratorOwner)) {
            serverDTO.IntegratorNo = formDTO.IntegratorOwner;
        }
    }
       
    private static async migrateSoundOwnerToForm(serverDTO: any, formDTO: any) {
        formDTO.IntegratorOwner = undefined;
        formDTO.DistributorOwner = undefined;
        formDTO.EndCustomerOwner = undefined;
    
        const [entityType, entityNo] = this.resolveEntityTypeAndNumber(serverDTO);
        if(entityType !== null && entityNo !== null) {
            const inheritance = await soundNetworkAPI.fetchEntityInheritance(entityType, entityNo)
            
            const assignOwnerValue = (value: any) => {
                return this.isEmpty(value) ? undefined : value;
            }
            formDTO.IntegratorOwner = assignOwnerValue(inheritance.IntegratorNo);
            formDTO.DistributorOwner = assignOwnerValue(inheritance.DistributorNo);
            formDTO.EndCustomerOwner = assignOwnerValue(inheritance.EndCustomerNo);
        }
    }
    
    private static async fetchEntityTree(serverDTO: any) {
        const userType = await loginNetworkAPI.getLoggedUserType();
        let entityTree = null;
        if(userType === "Admin") {
            const [entityType, entityNo] = this.resolveEntityTypeAndNumber(serverDTO);
            if(entityType !== null && entityNo !== null) {  
                const entityInheritance = await soundNetworkAPI.fetchEntityInheritance(entityType, entityNo);
                entityTree = await soundNetworkAPI.fetchEntityHierarchy("Integrator", entityInheritance.IntegratorNo);
            }
        } else {
            entityTree = await soundNetworkAPI.fetchEntityHierarchy();
        }
        return entityTree;
    }

    private static resolveEntityTypeAndNumber(serverDTO: any) {
        let entityType = null;
        let entityNo = null;
        if(! this.isEmpty(serverDTO.EndCustomerNo)) {
            entityType = "End Customer"; 
            entityNo = serverDTO.EndCustomerNo;
        } else if(! this.isEmpty(serverDTO.DistributorNo)) {
            entityType = "Distributor"
            entityNo = serverDTO.DistributorNo;
        } else if(! this.isEmpty(serverDTO.IntegratorNo)) {
            entityType = "Integrator";
            entityNo = serverDTO.IntegratorNo;
        } 
        return [entityType, entityNo];
    }

    private static isEmpty(value: any) {
        return value === undefined || value === null || value == "0";
    }  
}
