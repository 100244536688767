import { LoginNetworkAPI, SoundNetworkAPI, UnitGroupNetworkAPI, UnitsNetworkAPI, UsersNetworkAPI, CupuTypesNetworkAPI, FirmwareNetworkAPI, LogsNetworkAPI, OrdersAPI, PDFManualsAPI } from "./NetworkInterfaces";
import { CupuNetworkAPI } from "./CupuAPI/CupuNetworkAPI";

// const API_BASE_URL = "/old/";
const API_BASE_URL = "/";

export class NetworkManager {

    private static cupuAPI = new CupuNetworkAPI(API_BASE_URL);

    static getLoginNetworkAPI(): LoginNetworkAPI {
        return this.cupuAPI.getLoginAPI();
    }

    static getSoundNetworkAPI(): SoundNetworkAPI {
        return  this.cupuAPI.getSoundAPI();
    }

    static getUnitGroupNetworkAPI(): UnitGroupNetworkAPI {
        return  this.cupuAPI.getUnitGroupAPI();
    }

    static getUnitsNetworkAPI(): UnitsNetworkAPI {
        return this.cupuAPI.getUnitsAPI();
    }

    static getUsersNetworkAPI(): UsersNetworkAPI {
        return this.cupuAPI.geUsersAPI();
    }

    static getCupuTypesAPI(): CupuTypesNetworkAPI {
        return this.cupuAPI.getCupuTypesAPI();
    }

    static getFirmwareNetworkAPI(): FirmwareNetworkAPI {
        return this.cupuAPI.getFirmwareAPI();
    }

    static getLogsNetworkAPI(): LogsNetworkAPI {
        return this.cupuAPI.getLogsAPI();
    }

    static getOrdersAPI(): OrdersAPI {
        return this.cupuAPI.getOrdersAPI();
    }

    static getManualsAPI(): PDFManualsAPI {
        return this.cupuAPI.getManualsAPI();
    }
}
