import { createSlice } from '@reduxjs/toolkit'

// Exported interfaces
export interface ExceptionInfo {
    title: string;
    message: string;
    details: string;
}


// Initial State
const INITIAL_STATE = {
    exceptionDialogVisible: false,
    exceptionInfo: {}
}

// Redux Slice for Application
export const applicationSlice = createSlice({
    name: 'app',
    initialState: INITIAL_STATE,
    reducers: {
        showExceptionDialog: (state, action) => {
            state.exceptionInfo = action.payload;
            state.exceptionDialogVisible = true;
        },
        hideExceptionDialog: (state, action) => {
            state.exceptionDialogVisible = false;
            state.exceptionInfo = {};
        }
    }
});

// Action creators are generated for each case reducer function
export const { 
    showExceptionDialog,
    hideExceptionDialog
} = applicationSlice.actions

export default applicationSlice.reducer
