import { configureStore } from '@reduxjs/toolkit'

// Import of Redux Slice's Reducers
import appReducer from "./AppReduxSlice";
import soundsReducer from "./sounds/SoundReduxSlice";
import unitGroupsReducer from "./unitgroups/UnitGroupsReduxSlice";
import unitsReducer from "./units/UnitsReduxSlice";
import editUnitReducer from "./units/forms/EditUnitReduxSlice";
import playlistReducer from "./units/forms/PlaylistReduxSlice";
import usersReducer from "./users/UsersReduxSlice";
import endCustomersReducer from "./end-customers/EndCustomerReduxSlice"
import integratorsReducer from "./integrators/IntegratorReduxSlice";
import distributorsReducer from "./distributors/DistributorReduxSlice";
import cupuTypesReducer from "./cuputypes/CupuTypesReduxSlice";
import firmwaresReducer from "./firmwares/FirmwareReduxSlice";
import unitMapReducer from "./map/UnitMapReduxSlice";
import logsReducer from "./logs/LogsReduxSlice";
import orderReducer from "./orders/OrdersReduxSlice";
import supportReducer from "./support/SupportReduxSlice";

// Global Store Configuration
export default configureStore({
    reducer: {
        app: appReducer,
        sounds: soundsReducer,
        unitGroups: unitGroupsReducer,
        units: unitsReducer,
        editUnit: editUnitReducer,
        playlist: playlistReducer,
        users: usersReducer,
        endCustomers: endCustomersReducer,
        integrators: integratorsReducer,
        distributors: distributorsReducer,
        cuputypes: cupuTypesReducer,
        firmwares: firmwaresReducer,
        map: unitMapReducer,
        logs: logsReducer,
        orders: orderReducer,
        support: supportReducer
    }
});
