import React from "react";
import {InputText} from "primereact/inputtext";

import "./AppSearchBox.scss";
import { useState } from "react";

const SearchBoxIcon = () => {
    return (
        <div className="SearchBox--Icon">
            <i className="fa fa-search"></i>
        </div>
    )
}

const ClearIcon = (props: any) => {
    return (
        <div className="ClearBox--Icon" onClick={e => props.onClick(e)} >
            <i className="fa fa-times"></i>
        </div>
    )
}

export const SearchBox = (props: any) => {
    const [state, setState] = useState("");

    const handleClearClicked = () => {
        setState("");
        props.onChange("");
    }

    const updateState = (value: string) => {
        setState(value);
        props.onChange(value);
    }

    const handleEnterKey = (event: any) => {
        if(event.which === 13) {
            if(typeof props.onSearch === "function") {
                props.onSearch(state);
                setState("");
            }
        }
    }

    const showClearIcon = state.trim() !== "";

    return (
        <div className="SearchBox--Wrapper" >
            <InputText value={state}  placeholder={props.placeholder} style={{width: "100%", background: "#eee"}} 
                   onChange={e => updateState(e.currentTarget.value)} 
                   onKeyPress={(e: any) => handleEnterKey(e)} ></InputText>
            { showClearIcon && ( <ClearIcon onClick={(e: any) => handleClearClicked()} /> )}
            <SearchBoxIcon></SearchBoxIcon>
        </div>
    );
}

export const UnitsSearchBox = (props: any) => {
    return (
        <SearchBox placeholder="Search units and press Enter..." onChange={(e: any) => {}}
                onSearch={props.onSearch} ></SearchBox>
    );
}
