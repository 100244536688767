// React and Formik Imports
import React, { useState } from "react";
import { Formik, Form } from "formik";

// PrimeReact and other imports
import { FormikTextField } from "../forms/formik";
import { Button } from "primereact/button";
import LoginFormHeader from "./LoginFormHeader";

import "./PasswordResetForm.scss";
import { object, string } from "yup";
import { NavigationHelper } from "../helpers/NavigationHelper";
import { useAuth } from "../common/Auth";

const PasswordResetFormFooter = () => {

    const handleGoBack = () => {
        NavigationHelper.toUrl("/login");
    }
    
    return (
        <div className="PasswordRestForm--Footer" >
            <Button type="button" label="Back" className="p-button-info LoginForm--Submit"
                       onClick={handleGoBack} ></Button>
            <div />
            <Button type="submit" label="Reset Password" className="p-button-success LoginForm--Submit"></Button>
        </div>
    );
}

const INITIAL_VALUES = {
    login: "",
}

// Let's create LoginForm validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    login: string().required("Please, enter your username.")
});

const ResetPasswordStatus = (props: {status: boolean | null}) => {
    if(props.status === true) {
        return (
            <div className="PasswordResetForm--Success">
                Success. Your new password has been sent to your email account.
            
            </div>
        );
    } else if(props.status === false) {
        return (
            <div className="PasswordResetForm--Invalid">
                Resetting password failed. Have you entered the correct username?
            </div>
        );
    } else {
        return null;
    }
}

const PasswordResetForm = () => {

    const auth: any = useAuth();
    const [resetPasswordStatus, setResetPasswordStatus] = useState(null);

    const handleSubmit = async (values: any) => {
        const username = values.login;
        const result = await auth.resetPassword(username);
        setResetPasswordStatus(result);
    }

    const PasswordFormBody = () => {
        return (
            <React.Fragment>
                <div className="PasswordFormBody">
                    <FormikTextField label="Username:" name="login" 
                            placeholder="Enter your username..." autoFocus></FormikTextField>
                    <ResetPasswordStatus status={resetPasswordStatus} />
                </div>
            </React.Fragment>
        );
    }

    return (
        <div className="LoginForm--Wrapper">
            <div className="LoginForm">
                <LoginFormHeader></LoginFormHeader>
                <Formik initialValues={INITIAL_VALUES} validationSchema={FORM_VALIDATION_SCHEMA}
                    onSubmit={handleSubmit}   >
                    <Form>
                        <PasswordFormBody /> 
                        <PasswordResetFormFooter />
                    </Form>
                </Formik>
            </div>
        </div>
    )
}

export default PasswordResetForm;
