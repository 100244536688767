import { AjaxHelper } from "./AjaxHelper";


export class FirmwareNetworkHelper {

    parseFirmwareResponse(response: any) {
        return AjaxHelper.parseAjaxResponse(response, ["files"], "FirmwareNo");
    }

    parseHardwareCodeResponse(response: any) {
        return AjaxHelper.parseAjaxEnumResponse(response, ["Hardwarecode"], "HardwareCode", "HardwareName");
    }

    parseRequestedFirmwareNumberResponse(response: any) {
        return AjaxHelper.parseAjaxResponse(response, ["Requestedfirmwareno"], "FirmwareNo");
    }

}