import { Formik, Form, useField } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { object, string, mixed } from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { FormikAvatarUploadField, FormikTextAreaField, FormikTextField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { saveIntegrator } from "../IntegratorReduxSlice";

import "./EditIntegratorForm.scss";

const FILE_SIZE = 8 * 1024 * 1024;
const SUPPORTED_FORMATS: string[] = ["image/jpeg", "image/png", "image/gif"];

// Let's create SoundDetailForm validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    Name: string().required("Please, fill in the integrator name."),
    LoginText: string().required("Please, fill in the login text."),
    WelcomeText: string().required("Please, fill in the welcome text."),
    IntegratorLogo: mixed()
        .nullable(true)
        .test("size", "Selected file is too big. Maximum size is 8MB.",  
            (value) => typeof value === "string" || (!value || (value && value.size <= FILE_SIZE)))
        .test("type", "File has unsupported format Supported file formats are only images.",
            value => typeof value === "string" || (!value || (value && SUPPORTED_FORMATS.includes(value.type))))
});

const EditIntegratorForm = () => {

    // Submit Form Handler
    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        dispatch(saveIntegrator(values));
    }

    const handleReject = () => {
        NavigationHelper.toAppUrl("/integrators"); 
    }

    const editedIntegrator = useSelector((state: any) => state.integrators.editedIntegrator);
    console.dir(editedIntegrator);
    const isLoading = useSelector((state: any) => state.integrators.loadingIntegrators);

    const dialogTitle = editedIntegrator.IntegratorNo === undefined ?
                "Create New Integrator" : "Edit Existing Integrator";

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        const acceptLabel = editedIntegrator.IntegratorNo === undefined ? "Create" : "Update";
        return (
            <ContentFormStandardButtons
                acceptLabel={acceptLabel} onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const FormBody = () => {  
        const [logoUrl] = useField<string>("IntegratorLogo");

        return (
                <div className="EditIntegrator--Body">
                    <div className="EditIntegrator--Body--Left-Part" >
                        <FormikTextField label="Name:" name="Name" 
                            placeholder="Enter integrator name..." ></FormikTextField>
                        <FormikTextField label="Login Text:" name="LoginText" 
                            placeholder="Enter login text..." ></FormikTextField>
                        <FormikTextAreaField label="Welcome Text" name="WelcomeText" rows={4}
                            placeholder="Enter welcome text..." />
                    </div>
                    <div>
                        <FormikAvatarUploadField label="Integrator Logo" name="IntegratorLogo" 
                                    downloadUrlPrefix="/uploads/Integrator_logo/" value={logoUrl.value}  />
                    </div>
                </div>
        );
    }

    return (
        <GenericContentForm title={dialogTitle} footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={editedIntegrator} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditIntegratorForm;
