import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import * as _ from "lodash";
import { FilterHelper } from '../helpers/FilterHelper';
import { NavigationHelper } from '../helpers/NavigationHelper';
import { NetworkManager } from '../network/NetworkManager';
import { EntityStateHelper } from './helpers/EntityStateHelper';
import { SoundDataFormatHelper } from './helpers/SoundDataFormatHelper';

const loginNetworkAPI = NetworkManager.getLoginNetworkAPI();
const soundNetworkAPI = NetworkManager.getSoundNetworkAPI();

export const fetchSounds = createAsyncThunk(
    'sounds/fetchSounds',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(notifySoundsLoadingStarted(null));
        try {
            const loggedUserType = await loginNetworkAPI.getLoggedUserType();
            let sounds = await soundNetworkAPI.fetchSounds(loggedUserType);
            sounds = sounds.map((sound: any) => {return {...sound, id: sound.FileNo}});
            dispatch(updateSounds(sounds));
            const defaultSound = await soundNetworkAPI.getFactoryDefaultSound();
            dispatch(updateFactoryDefaultSound(defaultSound));
        }
        catch(e) {
            dispatch(updateSounds([]));
        }
        finally {
            dispatch(notifySoundsLoadingFinished(null));
        }
    }
);

export const filterSounds = createAsyncThunk(
    'sounds/filterSounds',
    async (phrase: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const {sounds } = thunkAPI.getState() as any;
        const { fetchedSounds } = sounds;

        const filteredSounds = FilterHelper.filterRecordsUsingPhrase(phrase, fetchedSounds, 
                        ["FileNo", "Description", "FileName", "OwnerName"]);
        dispatch(updateShownSounds(filteredSounds));
    }
);

// Note: CANDIDATE TO REMOVE
// export const fetchRelatedUsers = createAsyncThunk(
//     'sounds/fetchRelatedUsers',
//     async (payload: any, thunkAPI) => {
//         const {dispatch} = thunkAPI;
//         dispatch(notifySoundDetailLoadingStarted(null));
//         try {
//             const relatedUsers = await loginNetworkAPI.getAllRelatedUsers();
//             dispatch(updateRelatedUsers(relatedUsers));
//         }
//         catch(e) {
//             // TODO: Handle Network Error
//         }
//         finally {
//             dispatch(notifySoundDetailLoadingFinsihed(null));
//         }
//     }
// )

export const playSound = createAsyncThunk(
    'sounds/playSound',
    async (id: number, thunkAPI) => {
        const {dispatch} = thunkAPI;

        const state: any = thunkAPI.getState();
        const fetchedSounds: any[] = state.sounds.fetchedSounds;
        const fileIndex = fetchedSounds.findIndex(s =>s.FileNo === id);
        const fileName = fetchedSounds[fileIndex].FileName;

        dispatch(showPlaySoundDialog(fileName));
    }
);

export const loadEntityTree = createAsyncThunk(
    'sounds/loadEntityTree',
    async (payload: {entityType: string | null, entityNo: string | null}, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { sounds } = thunkAPI.getState() as any;

        if(payload.entityType === null || payload.entityNo === null) {
            dispatch(updateRootEntity(null));   
        } else {
            const entityInheritance = await soundNetworkAPI.fetchEntityInheritance(payload.entityType, payload.entityNo);
            const entityTree = await soundNetworkAPI.fetchEntityHierarchy("Integrator", entityInheritance.IntegratorNo);

            const entityStateHelper = new EntityStateHelper(entityTree);
            entityStateHelper.updateSelectedUnits(sounds.availableSounds);
    
            dispatch(updateRootEntity(entityTree));       
        }
    }
);

export const showUploadSoundForm = createAsyncThunk(
    'sounds/showUploadSoundForm',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;

        dispatch(updateUploadSoundStatus(true));
        try {
            const userType = await loginNetworkAPI.getLoggedUserType();
            if(userType === "Admin") {
                dispatch(updateRootEntity(null));
            } else {
                const entityTree = await soundNetworkAPI.fetchEntityHierarchy();
                dispatch(updateRootEntity(entityTree));   
            }
            dispatch(updateAvailableSounds([]));
            NavigationHelper.toAppUrl("/sounds/upload");
        }
        finally {
            dispatch(updateUploadSoundStatus(false));
        }
    }
);
   
export const uploadNewSound = createAsyncThunk(
    'sounds/uploadNewSound',
    async (formDTO: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { sounds } = thunkAPI.getState() as any;
        const rootEntity = sounds.rootEntity;

        const serverDTO = SoundDataFormatHelper.migrateToServerFormat(formDTO);

        // Get selected available sound units
        const entityStateHelper = new EntityStateHelper(rootEntity);
        const selectedUnits = entityStateHelper.getSelectedUnits();
        (serverDTO as any).availability = selectedUnits;

        dispatch(updateUploadSoundStatus(true));
        try {
            await soundNetworkAPI.uploadNewSound(serverDTO, () => {});
            NavigationHelper.toAppUrl("/sounds/")
        }
        finally {
            dispatch(updateUploadSoundStatus(false));
        }
    }
);


export const updateSound = createAsyncThunk(
    'sounds/updateSound',
    async (formDTO: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const {sounds} = thunkAPI.getState() as any;
        formDTO.filename = sounds.editedSound.filename;
        const rootEntity = sounds.rootEntity;

        const serverDTO = SoundDataFormatHelper.migrateToServerFormat(formDTO);

        // Get selected available sound units
        const entityStateHelper = new EntityStateHelper(rootEntity);
        const selectedUnits = entityStateHelper.getSelectedUnits();
        (serverDTO as any).availability = selectedUnits;

        // Show Upload File Progress
        dispatch(updateUploadSoundStatus(true));
        try {
            await soundNetworkAPI.updateSound(serverDTO);
            // Hide navigation helper
            dispatch(updateUploadSoundStatus(false));
            // Navigate back to the sound data list page
            NavigationHelper.toAppUrl("/sounds/");
        }
        catch(e) {
            // TODO: Complete Error Handling
            dispatch(updateUploadSoundStatus(false));
        }
    }
)

export const editSound = createAsyncThunk(
    'sounds/editSound',
    async (id: number, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { sounds } = thunkAPI.getState() as any;
        const fetchedSounds: any[] = sounds.fetchedSounds;

        // Find edited Sound DataRow
        const editedSound = fetchedSounds.find(s => s.id === id);
        if(editedSound === undefined)
            throw new Error("Sound to edit was not found in fetched sounds.");

        // Fetch available sounds
        const availableSounds = await soundNetworkAPI.fetchAvailableSounds(editedSound.FileNo);
        dispatch(updateAvailableSounds(availableSounds));

        const [formDTO, entityTree] = await SoundDataFormatHelper.migrateToFormFormat(editedSound);

        // Update Entity Hierarchy   
        if(entityTree !== null) {
            const newRootEntity = _.cloneDeep(entityTree);
            const entityStateHelper = new EntityStateHelper(newRootEntity);
            entityStateHelper.updateSelectedUnits(availableSounds);
            dispatch(updateRootEntity(newRootEntity));    
        } else {
            dispatch(updateRootEntity(null));   
        }
        dispatch(updateEditedSound(formDTO));

        // Finally navigate to Edit Form
        NavigationHelper.toAppUrl(`/sounds/edit/`);       
    }
);

export const setFactoryDefaults = createAsyncThunk(
    'sounds/setFactoryDefaults',
    async (id: number, thunkAPI) => {
        const {dispatch} = thunkAPI;
        // Start loading probress
        dispatch(notifySoundsLoadingStarted(null));
        try {
            await soundNetworkAPI.setFileAsDefault(id);
        }
        finally {
            dispatch(notifySoundsLoadingFinished(null))
        }

        // Refresh sounds
        dispatch(fetchSounds());
    }
);

export const setEntityFlag = createAsyncThunk(
    'sounds/setEntityFlag',
    async (payload: {entityNo: string, flag: boolean | null}, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { sounds } = thunkAPI.getState() as any;
        const { rootEntity }  = sounds;

        const newRootEntity = _.cloneDeep(rootEntity);

        const entityStateHelper = new EntityStateHelper(newRootEntity);
        entityStateHelper.setCheck(payload.entityNo, payload.flag);

        dispatch(updateRootEntity(newRootEntity));
    }
);

export const hideSound = createAsyncThunk(
    'sounds/hideSound',
    async (FileNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        await soundNetworkAPI.hideSound(FileNo);
        dispatch(fetchSounds());
    }
);

// Initial Sound Slice State
const INITIAL_STATE = {
    fetchedSounds: [],
    shownSounds: [],
    factoryDefaultSoundFileNo: "",
    factoryDefaultSoundName: "",
    factoryDefaultsSoundId: null,
    loadingSounds: false,
    relatedUsers: [],
    loadingSoundDetails: 0,
    uploadingNewSound: false,
    soundFileToPlay: null,
    playSoundFormVisible: false,
    editedSound: {},
    rootEntity: {},
    availableSounds: [],
    unitGroupEnum: []
}

// Redux Slice for Sounds
export const soundsSlice = createSlice({
    name: 'sounds',
    initialState: INITIAL_STATE,
    reducers: {
        notifySoundsLoadingStarted: (state, action) => {
            state.loadingSounds = true;
            state.fetchedSounds = [];
            state.shownSounds = [];
        },
        notifySoundsLoadingFinished: (state, action) => {
            state.loadingSounds = false;
        },
        notifySoundDetailLoadingStarted: (state, action) => {
            state.loadingSoundDetails++;            
        },
        notifySoundDetailLoadingFinsihed: (state, action) => {
            state.loadingSoundDetails--;            
        },
        updateSounds: (state, action) => {
            state.fetchedSounds = action.payload;
            state.shownSounds = _.clone(action.payload);
            const defaultSound = action.payload.find((s: any) => s.FactoryDefault == 1);
            state.factoryDefaultsSoundId = defaultSound === undefined ? null : defaultSound.FileNo;
        },
        updateFactoryDefaultSound: (state, action) => {
            state.factoryDefaultSoundFileNo = action.payload.FileNo;
            state.factoryDefaultSoundName = action.payload.Description;
        },
        updateRelatedUsers: (state, action) => {
            state.relatedUsers = action.payload;
        },
        updateShownSounds: (state, action) => { 
            state.shownSounds = _.clone(action.payload);
        },
        showPlaySoundDialog: (state, action) => {
            state.soundFileToPlay = action.payload;
            state.playSoundFormVisible = true;
        },
        closePlaySoundDialog: (state, action) => {
            state.playSoundFormVisible = false;
        },
        clearSounds: (state, action) => {
            state.fetchedSounds = [];
            state.shownSounds = [];
        },
        updateUploadSoundStatus: (state, action) => {
            state.uploadingNewSound = action.payload;
        },
        updateEditedSound: (state, action) => {
            state.editedSound = action.payload;
        },
        updateRootEntity: (state, action) => {
            state.rootEntity = action.payload;
        },
        updateAvailableSounds: (state, action) => {
            state.availableSounds = action.payload;
        },
        updateUnitGroupEnum: (state, action) => {
            state.unitGroupEnum = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { 
    notifySoundsLoadingStarted,
    notifySoundsLoadingFinished,
    updateShownSounds,
    updateSounds,
    clearSounds,
    notifySoundDetailLoadingStarted,
    notifySoundDetailLoadingFinsihed,
    updateRelatedUsers,
    showPlaySoundDialog,
    closePlaySoundDialog,
    updateUploadSoundStatus,
    updateEditedSound,
    updateRootEntity,
    updateAvailableSounds,
    updateUnitGroupEnum,
    updateFactoryDefaultSound
} = soundsSlice.actions

export default soundsSlice.reducer
