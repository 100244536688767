import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux"
import * as _ from "lodash";
import * as $ from "jquery";


export interface EnumOption {
    label: string;
    value: string;
}

export interface EnumUpdateHandler {
    (value: string[]): void;
}

export const useSelectorEnum = (selection: any) => {
    const result: any[] = useSelector(selection);
    return result.map((item: any) => {
        return {label: item.name, value: item.id};
    })
}

export const useUpdateEnumSelection = (value: string[], options: EnumOption[], updateMethod: EnumUpdateHandler) => {
    const [prevOptions, setPrevOptions] = useState<any[]>([]);
    if(_.isEqual(prevOptions, options)) return;
    setPrevOptions(options);
    if(value === null) updateMethod([]);
    const filteredSelection = value.filter(selItem => {
        return options.find(option => option.value === selItem) !== undefined;
    });
    updateMethod(filteredSelection);
}

export const useServerCommunication = () => {
    const [state, setState] = useState(false);

    useEffect(() => {
        ($ as any)(document).ajaxStart(() => {
            setState(true);
        });
        ($ as any)(document).ajaxStop(() => {
            setState(false);
        });
    }, []);

    return state;
}
