// React Imports
import React from "react";

// Import LoginForm Header Style
import "./LoginFormHeader.scss";

// LoginFormHeader Component
const LoginFormHeader = () => {
    return (
        <div className="AppLogo--LoginForm"  >
            <img alt="logo" src="./logo.png" />
            <div className="AppLogo--LoginForm--TopTitle">
                <span>ECO Sound Online</span>
            </div>
            <div className="AppLogo--LoginForm--BottomTitle">
                <span>Login</span>
            </div>
            <div className="AppLogo--LoginForm--Separator"></div>
        </div>
    );   
}

export default LoginFormHeader;
