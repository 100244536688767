import { Form, Formik } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { object, string } from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { FormikTextField, FormikVoltageField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { saveCupuType } from "../CupuTypesReduxSlice";

import "./EditCupuTypeForm.scss";

const FORM_VALIDATION_SCHEMA = object().shape({
    HardwareName: string().required("Please, enter the hardware name."),
    minVPV: string().nullable().required("Please, enter Min VPV..."),
    maxVPV: string().nullable().required("Please, enter Max VPV..."),
    maxLPV: string().nullable().required("Please, enter Max LPV..."),
    maxPVP: string().nullable().required("Please, enter Max PVP..."),
    maxIcharge: string().nullable().required("Please, enter Max Icharge...")
});

const EditCupuTypeForm = () => {

    const initialData = useSelector((state: any) => state.cuputypes.editedCupuType);
    const isLoading = useSelector((state: any) => state.cuputypes.loadingCupuTypes);

    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        dispatch(saveCupuType(values));
    }

    const handleReject = () => {
        NavigationHelper.goBack();
    }

    const FormBody = () => {
        return (
            <div className="CupuType--FormBodyContainer">
                <div className="CupuType--FormBody">
                    <div>
                        <FormikTextField label="Hardware Name:" name="HardwareName" 
                                    placeholder="Enter hardware name..." ></FormikTextField>
                        <FormikVoltageField label="MinVPV" name="minVPV" placeholder="Enter Min VPV..." />
                        <FormikVoltageField label="MaxVPV" name="maxVPV" placeholder="Enter Max VPV..." />
                    </div>
                    <div>
                        <FormikVoltageField label="MaxLPV" name="maxLPV" placeholder="Enter Max LPV..." />
                        <FormikVoltageField label="MaxPVP" name="maxPVP" placeholder="Enter Max PVP..." />
                        <FormikVoltageField label="MaxIcharge" name="maxIcharge" placeholder="Enter Max Icharge..." />                   
                    </div>
                </div>
            </div>
        );
    }

    const formRef = useRef<any>(null);
    const FormFooter = () => {
        return (
            <ContentFormStandardButtons
                acceptLabel="Create" onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    return (
        <GenericContentForm title="Create New Cupu Type" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={initialData} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditCupuTypeForm;
