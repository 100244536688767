import React from "react";
import {Menu} from "primereact/menu";

import "./AppHeader.scss";
import { NavigationHelper } from "../helpers/NavigationHelper";
import { useAuth } from "./Auth";
import { useServerCommunication } from "../helpers/HookHelpers";
import { UserType } from "../network/NetworkInterfaces";
import { EnvironmentHelper } from "../helpers/EnvironmentHelper";

const AppLogoProgressBar = () => {
    const isCommunicationRunning = useServerCommunication();

    return (
        <div className="AppLogo--Communication">
            { isCommunicationRunning && (
                <span className="qa-ajax-running">
                    Fetching data...&nbsp;&nbsp;
                    <i className="fa fa-cog fa-spin" />
                </span>
            )}
        </div>
    );
}


const AppLogo = () => {
    const auth: any = useAuth();
    let userLogoUrl = auth.getUserLogoUrl();
    if(userLogoUrl === undefined || userLogoUrl === null) {
        userLogoUrl = "./logo.png";
    }
    const companyName = auth.getCompanyName();    
    const companyNameAfterUser = auth.getUserRole() === "Admin" ? "" : ", " + companyName;

    const handleLogoClick = () => {
        NavigationHelper.toAppUrl("/home");
    }

    return (
        <div className="AppLogo">
            <div className="AppLogo--Wrapper qa-homepage" onClick={handleLogoClick} >
                <img alt="logo" src={userLogoUrl} className="AppLogo--Image"  />
            </div>
            <div className="AppLogo--top-title">
                { companyName }
            </div>
            <div className="AppLogo--bottom-title">
                Logged in as { auth.getLoggedUsername() }&nbsp; { companyNameAfterUser }
            </div>
            <AppLogoProgressBar />
        </div>
    )
}

const AppMenuButton = (props: any) => {
    let faIcon = `fa fa-3x ${props.icon}`;
    return (
        <div className={'AppMenuButton ' + props.className} onClick={e => props.onClicked(e)} >
            <i className={faIcon}></i>
        </div>
    );
}

const SettingsMenuItems = [
    {
        label: "Integrators", 
        className: "qa-integrators",
        command: () => NavigationHelper.toAppUrl("/integrators"),
        isVisible: (userType: UserType)  => { return userType === "Admin" }
     },
    {
        label: "Distributors", 
        className: "qa-distributors",
        command: () => NavigationHelper.toAppUrl("/distributors"),
        isVisible: (userType: UserType)  => { return userType === "Admin" || userType === "Integrator" }
    },
    {
        label: "End Customers", 
        className: "qa-end-customers",               
        command: () => NavigationHelper.toAppUrl("/end-customers"),
        isVisible: (userType: UserType)  => { return userType === "Admin" || userType === "Integrator" || userType === "Distributor" }
    },
    {
        separator: true,
        isVisible: (userType: UserType)  => { return userType === "Admin" || userType === "Integrator" || userType === "Distributor" }
    },
    {
        label: "Support", 
        className: "qa-support",               
        command: () => NavigationHelper.toAppUrl("/support"),
        isVisible: (userType: UserType)  => { return userType === "Admin" || userType === "Integrator" || userType === "Distributor" }
    },
];

const SettingsDropdown = () => {
    let menu = React.createRef<Menu>();
    
    const auth: any = useAuth();
    const userType = auth.getUserRole();
    const filteredSettings = SettingsMenuItems.filter(item => item.isVisible(userType));

    // VP - HOTFIX: Settings Menu not visible when animations are turned off
    let appendTo = undefined
    if(EnvironmentHelper.isAnimationEnabled() === false) {
        appendTo = "self";
    }

    return (
        <React.Fragment>
            { filteredSettings.length > 0 && 
                (
                    <>
                        <AppMenuButton className="qa-settings" icon="fa-cog" onClicked={(e: any) => menu.current?.toggle(e)} ></AppMenuButton>
                        <Menu model={filteredSettings} popup ref={menu} appendTo={appendTo} ></Menu>
                    </>    
                )
            }
        </React.Fragment>
    );
}

const AppMenu = (props: any) => {
    let auth: any = useAuth();

    const handleLogout = async () => {
        await auth.logout();
        NavigationHelper.toUrl("/login");
    }

    return (
        <div className="AppMenu">
            <SettingsDropdown></SettingsDropdown>
            <AppMenuButton className="qa-logout" icon="fa-sign-out-alt" onClicked={(e: any) => handleLogout()} ></AppMenuButton>
        </div>
    );
}

export default class AppHeader extends React.Component {

    render() {
        return (
            <div className="AppHeader">
                <AppLogo></AppLogo>
                <div></div>
                <AppMenu></AppMenu>
            </div>
        );
    }    
}
