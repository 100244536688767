import type { SoundDataRow, SoundNetworkAPI, UserType, SoundDTO, UploadProgressHandler } from "../NetworkInterfaces";
import { AjaxRequest, CupuNetworkAPIBase } from "./CupuNetworkAPIBase";
import { NetworkHelper } from "../../helpers/NetworkHelper";
import { RestrictAccess } from "../NetworkSecurity";

export class CupuSoundNetworkAPI extends CupuNetworkAPIBase implements SoundNetworkAPI {

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchSounds(userType: UserType): Promise<SoundDataRow[]> {
        // const ajaxRequest: AjaxRequest = this.createAjaxRequestByUserType(userType);
        const ajaxRequest = super.createAjaxRequest("GET", "Files/get_available_files", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        // return response.response_data;
        return NetworkHelper.Sounds.parseSounds(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async fetchSoundsEnum(userType: UserType): Promise<any[]> {
        // const ajaxRequest: AjaxRequest = this.createAjaxRequestByUserType(userType);
        const ajaxRequest = super.createAjaxRequest("GET", "Files/get_available_files", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        const data = NetworkHelper.Sounds.parseSoundsEnum(response);
        console.dir(data);
        return [];

    }

    // Note: Progress handler is included for the case we would be doing upload file progress
    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async uploadNewSound(sound: SoundDTO, progressHandler: UploadProgressHandler): Promise<void> {
        const data = NetworkHelper.Sounds.formatSoundUploadRequest(sound, "upload");
        const formData = NetworkHelper.convertToFormData(data);

        const ajaxRequest = super.createAjaxRequest("POST", "files/save_file", formData, "json");
        ajaxRequest.processData = false;
        ajaxRequest.contentType = false;
        await super.callAjax(ajaxRequest);
    }

    // This method is included for the intention we would be creating sound file upload progress....
    async cancelSoundUpload(): Promise<void> {
        throw new Error("NOT IMPLEMENTED");
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async updateSound(sound: SoundDTO): Promise<void> {
        const data = NetworkHelper.Sounds.formatSoundUpdateRequest(sound);
        const formData = NetworkHelper.convertToFormData(data);

        const ajaxRequest = super.createAjaxRequest("POST", "files/save_file", formData, "json");       
        ajaxRequest.processData = false;
        ajaxRequest.contentType = false;
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin"])
    async removeSound(id: number): Promise<void> {
        const ajaxRequest = super.createAjaxRequest("POST", "files/delete_file", {FileNo: id}, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin"])
    async setFileAsDefault(id: number): Promise<void> {
        const data = { FileNo: id };
        const ajaxRequest = super.createAjaxRequest("POST", "Files/set_default_file", data, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async getFactoryDefaultSound(): Promise<any> {
        const ajaxRequest = super.createAjaxRequest("POST", "Files/get_default_file", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data;
    }

    async fetchEntityHierarchy(entityType?: string, entityNo?: string): Promise<any> {
        const ajaxData = {entityType, entityNo};
        const ajaxRequest = super.createAjaxRequest("GET", "entities/get_EntityHierarchy", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data;
    }

    async fetchEntityInheritance(entityType: string, entityNo: string): Promise<any> {
        const ajaxData = {entityType, entityNo};
        const ajaxRequest = super.createAjaxRequest("POST", "entities/get_entity_inheritance_path", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data;
    }

    async fetchAvailableSounds(fileNo: string): Promise<any[]> {
        const ajaxData = { FileNo: fileNo };
        const ajaxRequest = super.createAjaxRequest("GET", "Files/get_available_sounds", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data;
    }

    async hideSound(FileNo: string): Promise<void> {
        const ajaxData = { FileNo: FileNo };
        const ajaxRequest = super.createAjaxRequest("POST", "Files/hide_file", ajaxData, "json");
        await super.callAjax(ajaxRequest);
    }

    async queryIntegrators(): Promise<any[]> {
        const ajaxRequest = super.createAjaxRequest("GET", "Entities/query_integrators", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data.map((integrator: any) => {
            return {label: integrator.Name, value: integrator.IntegratorNo };
        });
    }

    async queryIntegratorEntities(integratorNo: string): Promise<any> {
        const ajaxData = {IntegratorNo: integratorNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Entities/query_integrator_entities", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);

        const distributors = response.response_data.Distributors.map((item: any) => {
            return {label: item.Name, value: item.DistributorNo }
        });
        const endCustomers = response.response_data.EndCustomers.map((item: any) => {
            return {label: item.Name, value: item.EndCustomerNo }
        });       
        return {distributors, endCustomers};
    }

    async queryDistributorEntities(distributorNo: string): Promise<any> {
        const ajaxData = {DistributorNo: distributorNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Entities/query_distributor_entities", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data.map((item: any) => {
            return {label: item.Name, value: item.EndCustomerNo };
        })
    }

    async getEntityName(entityNo: string, entityType: UserType): Promise<string> {
        const ajaxData = {entityNo, entityType};
        const ajaxRequest = super.createAjaxRequest("POST", "Entities/get_entity_name", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data;
    }

    private createAjaxRequestByUserType(userType: UserType): AjaxRequest {
        switch(userType) {
            case "Admin": 
                return super.createAjaxRequest("GET", "files/get_files", {}, "json");
            case "Distributor": 
            case "Integrator":
                return super.createAjaxRequest("GET", "files/get_files_integrator", {}, "json");
            case "End Customer":
                return super.createAjaxRequest("GET", "files/get_files_Endcustomer", {}, "json");
            default:
                throw new Error(`Unknown user type: ${userType}`);
        }
    }
}
