import React, { useEffect, useRef, useState } from "react"
import { DataTable } from "primereact/datatable";

// Import Style
import "./GenericDataList.scss";

// Automatic Scroll Height Computation for DataTable based on available space
const computeScrollHeight = (wrapper: HTMLElement) => {
    const dataTableAvailHeightSpace = wrapper.offsetHeight;
    const dataTableHeader = wrapper.querySelector(".p-datatable-scrollable-header");
    if(dataTableHeader === null)
        return "auto";
    const dataTableHeaderHeight = (dataTableHeader as HTMLElement).offsetHeight;
    const scrollHeight = Math.floor(dataTableAvailHeightSpace - dataTableHeaderHeight);
    return `${scrollHeight}px`;
}


// Generic DataList Component
const GenericDataList = ({children, ...rest}: any) => {
    let refDataListWrapper = useRef<HTMLDivElement>(null);
    const [scrollHeight, setScrollHeight] = useState("0px");

    const adjustDataListSize =() => {
        if(refDataListWrapper.current === null)
            throw new Error("Cannot compute scrollHeight, wrapper DIV not referenced.");
        const computedScrollHeight = computeScrollHeight(refDataListWrapper.current);
        setScrollHeight(computedScrollHeight);           
    }

    useEffect(() => {
        adjustDataListSize();
    }, []);

    useEffect(() =>{
        const windowResizeHandler = () => {
            adjustDataListSize();
        }
        window.addEventListener("resize", windowResizeHandler);
        return () => {
            window.removeEventListener("resize", windowResizeHandler);
        }
    }, [])

    return (
        <div className="GenericDataList--Container">
            <div className="GenericDataList" ref={refDataListWrapper}  >
                <DataTable children={children} {...rest} scrollable scrollHeight="flex" autoLayout
                    tableStyle={{width: "100%", minWidth: "1200px"}} />
            </div>
        </div>
    );
}

export default GenericDataList;
