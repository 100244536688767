import React from "react";

import "./TreeCheckBox.scss";

export interface TreeCheckBoxProps {
    value: boolean | null;
    onChange: (value: boolean | null) => void;
}

const TreeCheckBox = (props: TreeCheckBoxProps) => {

    let className = "TreeCheckBox ";

    if(props.value === true) className += "TreeCheckBox--Checked";
    else if(props.value === false) className += "TreeCheckBox--Unchecked";
    else className += "TreeCheckBox--Neutral";

    const handleOnClick = () => {
        props.onChange(! props.value);
    }

    return (
        <div className={className} onClick={handleOnClick} > 
            { props.value === true ? '\u2713' : (<>&nbsp;</>) }
        </div>
    );
}

export default TreeCheckBox;
