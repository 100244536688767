import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';
import { FilterHelper } from '../helpers/FilterHelper';
import * as _ from 'lodash';
import { NavigationHelper } from '../helpers/NavigationHelper';

const cupuTypesNetworkAPI = NetworkManager.getCupuTypesAPI();

export const fetchCupuTypes = createAsyncThunk(
    'cuputypes/fetchCupuTypes',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingCupuTypesStatus(true));
        const cupuTypesData = await cupuTypesNetworkAPI.fetchCupuTypes();
        dispatch(updateFetchedCupuTypes(cupuTypesData));
        dispatch(updateLoadingCupuTypesStatus(false));
    }
);

export const filterCupuTypesByPhrase = createAsyncThunk(
    'cuputypes/filterCupuTypesByPhrase',
    async (payload: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { cuputypes } = thunkAPI.getState() as any;

        const filteredDistributors = FilterHelper.filterRecordsUsingPhrase(payload, cuputypes.fetchedCupuTypes,
            ["HardwareCode", "HardwareName", "minVPV", "maxVPV", "maxLPV", "maxPVP", "maxIcharge"]);
        dispatch(updateShownCupuTypes(filteredDistributors));
    }
);

export const createNewCupuType = createAsyncThunk(
    'cuputypes/createNewCupuType',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateEditedCupuType({HardwareName: "", minVPV: "", maxVPV: "", maxLPV: "",
                    maxPVP: "", maxIcharge: ""}));
        NavigationHelper.toAppUrl("/cuputypes/edit/");
    }
);

export const saveCupuType = createAsyncThunk(
    'cuputypes/saveCupuType',
    async (cupuTypesData: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingCupuTypesStatus(true));       
        try {
            await cupuTypesNetworkAPI.saveCupuType(cupuTypesData);
            NavigationHelper.goBack();
        }
        finally {
            dispatch(updateLoadingCupuTypesStatus(false));
        }
    }
);

const INITIAL_STATE = {
    shownCupuTypes: [],
    fetchedCupuTypes: [],
    loadingCupuTypes: false,
    editedCupuType: {},
}

export const cupuTypesSlice = createSlice({
    name: 'cuputypes',
    initialState: INITIAL_STATE,
    reducers: {
        updateLoadingCupuTypesStatus: (state, action) => {
            state.loadingCupuTypes = action.payload;
        },
        updateFetchedCupuTypes: (state, action) => {
            state.fetchedCupuTypes = action.payload;
            state.shownCupuTypes = _.clone(action.payload);
        },
        updateShownCupuTypes: (state, action) => {
            state.shownCupuTypes = action.payload;
        },
        updateEditedCupuType: (state, action) => {
            state.editedCupuType = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateEditedCupuType,
    updateFetchedCupuTypes,
    updateLoadingCupuTypesStatus,
    updateShownCupuTypes
} = cupuTypesSlice.actions

export default cupuTypesSlice.reducer
