import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import GenericContentForm, {ContentFormStandardButtons} from "../../forms/GenericContentForm";
import LoadingProgress from "../../common/LoadingProgress";
import {Button} from "primereact/button";
import _ from "lodash";

// Formik and Yup Schema Imports
import { Formik, Form, useField } from "formik";
import  { object, string } from "yup";

// Formik Adaptation Components
import { FormikTextField, FormikDropdownField, FormikSoundVolumeField, FormikTextAreaField } from "../../forms/formik";

// Import Style
import "./EditUnitForm.scss";
import { useSelectorEnum } from "../../helpers/HookHelpers";
import { deleteUnit, fetchDistributorEndCustomers, fetchDistributors, fetchIntegratorEndCustomers, saveUnit, updateDistributors, updateEndCustomers } from "./EditUnitReduxSlice";
import { useState } from "react";
import { triggerEditUnit } from "../UnitsReduxSlice";
import { useEffect } from "react";
import { useParams } from "react-router";
import { confirmDialog } from "primereact/confirmdialog";
import { propTypes } from "react-bootstrap/esm/Image";
import { useAuth } from "../../common/Auth";



// Form Body Parts
const FormBodyPart1 = () => {

    return (
        <div  className="EditUnitForm--BodyPart1">
            <FormikTextField label="SerialNo:" name="SerialNo" disabled  />
            <FormikTextField label="Integrator SerialNo:" name="IntegratorSerialNo" 
                                placeholder="Enter the integrator serial number..." />
            <FormikTextField label="Hardware Code:" name="HardwareCode" disabled  />
            <FormikTextField label="Hardware Name:" name="HardwareName" disabled  />
            <div />
            {/* <FormikDropdownField label="Air Compressor Timeout:" name="AirCompressorTimeout" 
                        options={TIMEOUT_OPTIONS} showClear /> */}
        </div>
    )
}

const FormBodyPart2 = () => {
    const dispatch = useDispatch();

    const openedUnit = useSelector((state: any) => state.editUnit.openedUnit);

    const integratorsState = useSelector((state: any) => state.editUnit.integrators);

    const integrators = useSelectorEnum((state: any) => state.editUnit.integrators);
    const distributors = useSelectorEnum((state: any) => state.editUnit.distributors);
    const endCustomers = useSelectorEnum((state: any) => state.editUnit.endCustomers);

    const [fieldIntegratorNo, metaIntegratorNo, helpersIntegratorNo] = useField("IntegratorNo");
    const [fieldDistributorNo, metaDistributorNo, helpersDistributorNo] = useField("DistributorNo");

    const updateDepedentEntityLists = (data: {integratorNo: string | null, distributorNo: string | null}) => {
        const integratorNo = data.integratorNo;
        const distributorNo = data.distributorNo;
        console.dir([integratorNo, distributorNo]);

        if(integratorNo === null || integratorNo === undefined) {
            dispatch(updateDistributors([]));
            dispatch(updateEndCustomers([]));
        } else {
            dispatch(fetchDistributors(integratorNo));
            if(distributorNo === null || distributorNo === undefined) {
                dispatch(fetchIntegratorEndCustomers(integratorNo));
            } else {
                dispatch(fetchDistributorEndCustomers({integratorNo, distributorNo}));
            }
        }
    }

    const handleIntegratorSelected = (integratorNo: string) => {
        updateDepedentEntityLists({integratorNo, distributorNo: null});
    }

    const handleDistributorSelected = (distributorNo: string) => {        
        if(fieldIntegratorNo.value !== null && fieldIntegratorNo.value !== undefined) {
            updateDepedentEntityLists({integratorNo: fieldIntegratorNo.value, distributorNo});
        } else {
            updateDepedentEntityLists({integratorNo: fieldIntegratorNo.value, distributorNo: null});
        }
    }

    useEffect(() => {
        updateDepedentEntityLists({integratorNo: fieldIntegratorNo.value, distributorNo: fieldDistributorNo.value});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [integratorsState])

    return (
        <div  className="EditUnitForm--BodyPart2">
            <div>
                { /* Change to Formik Spinner */ }
                <FormikTextField label="GPS Coordinates:" name="Lat" />
                <FormikTextField label={null} name="Lng" />
            </div>
            <div>
                <FormikDropdownField label="Assigned To:" name="IntegratorNo" onChange={(e: any) => handleIntegratorSelected(e.value)}
                        placeholder="--- Select Integrator ---" options={integrators} showClear />
                <FormikDropdownField label={null} name="DistributorNo" onChange={(e: any) => handleDistributorSelected(e.value)}
                        placeholder="--- Select Distributor ---" options={distributors} showClear />
                <FormikDropdownField label={null} name="EndCustomerNo" 
                        placeholder="--- Select End Customer ---" options={endCustomers} showClear />
            </div>
            <div>
                <div style={{width: "50%"}}>
                    <FormikSoundVolumeField label="Sound Volume" name="AudioOutVolume" min={0} max={30} />
                </div>
            </div>
            <div />
        </div>
    );
}

const FormBodyPart3 = () => {

    const firmware = useSelectorEnum((state: any) => state.editUnit.firmware);

    return (
        <div  className="EditUnitForm--BodyPart3">
            <FormikTextField label="GSM Signal Strength:" name="GSMSignalStrength" disabled  />
            <FormikTextField label="Actual Firmware No:" name="ActualFirmwareNo" disabled  />
            <FormikDropdownField label="Requested Firmware No:" name="RequestedFirmwareNo" options={firmware}
                        showClear />
            <div className="NoteWrapper" >
                <div className="NotePositioningWrapper">
                <FormikTextAreaField label="Note:" name="Note" rows={5} />
                </div>
            </div>
        </div>
    );
}

// Let's create form validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    IntegratorSerialNo: string().nullable(),
    // IntegratorSerialNo: string().nullable().required("Enter the integrator serial number, please."),
    // AirCompressorTimeout: string().nullable().required("Enter the air compressor timeout, please."),
    Lat: string().nullable().required("Please, enter GPS Latitude"),
    Lng: string().nullable().required("Please, enter GPS Longitude"),
    IntegratorNo: string().nullable().required("Please, select the integrator"),
    EndCustomerNo: string().nullable(),
    // EndCustomerNo: string().nullable().required("Please, select the end customer"),
    Note: string().nullable(),
    // Note: string().nullable().required("Please, select the unit note"),
    RequestedFirmwareNo:  string().nullable().required("Please, the requested firmware number.")
});

const EditUnitForm = () => {
    const {serialNumber} = useParams<any>();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(triggerEditUnit(serialNumber));
    }, [])

    const transformFormValues = (formValues: any) => {
        // Note: We need to clone values because the passed Store Values have readonly properties
        formValues = _.cloneDeep(formValues);
        const AUDIO_VOLUME_SUM = 30;
        if(formValues.AudioOutVolume !== undefined && formValues.AudioOutVolume !== null) {
            formValues.AudioOutVolume = AUDIO_VOLUME_SUM - formValues.AudioOutVolume;
        }
        if(formValues.IntegratorSerialNo !== undefined) {
            if(formValues.IntegratorSerialNo === null || formValues.IntegratorSerialNo.trim() === "") {
                delete formValues.IntegratorSerialNo;
            }    
        }
        if(formValues.EndCustomerNo === "0" || formValues.EndCustomerNo === undefined) {
            formValues.EndCustomerNo = null;
        }
        if(formValues.DistributorNo === "0" || formValues.DistributorNo === undefined) {
            formValues.DistributorNo = null;
        }

        // if(formValues.EndCustomerNo !== undefined) {
        //     if(formValues.EndCustomerNo === null || formValues.EndCustomerNo.trim() === "") {
        //         delete formValues.EndCustomerNo;
        //     }   
        // }
        if(formValues.AirCompressorTimeout !== undefined) {
            if(formValues.AirCompressorTimeout === null || formValues.AirCompressorTimeout.trim() === "") {
                delete formValues.AirCompressorTimeout
            }
        }
        // if(formValues.DistributorNo !== undefined) {
        //     if(formValues.DistributorNo === null || formValues.DistributorNo.trim() === "") {
        //         delete formValues.DistributorNo
        //     }
        // }
        return formValues;
    }

    const isLoading = useSelector((state: any) => state.editUnit.isLoadingCounter > 0);
    const initialValues = transformFormValues(useSelector((state: any) => state.editUnit.openedUnit));  
    const unitId = initialValues.SerialNo;

    const auth: any = useAuth();
    const isAdmin = auth.getUserRole() === "Admin";

    // Submit Form Handler
    const handleSubmit = (formValues: any) => {
        dispatch(saveUnit(transformFormValues(formValues)));
    }

    // TODO: Check if data had changed?
    const handleReject = () => {
        NavigationHelper.goBack();
    }

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        const showPlaylists = () => {
            if(unitId !== undefined) {
                NavigationHelper.toAppUrl("/units/playlist/" + unitId);
            }
        }

        const showApiLog = () => {
            if(unitId !== undefined) {
                NavigationHelper.toAppUrl("/units/apilog/" + unitId);
            }
        }

        const showServiceLog = () => {
            if(unitId !== undefined) {
                NavigationHelper.toAppUrl("/units/servicelog/" + unitId);
            }
        }

        const showDeleteUnit = () => {
            if(unitId !== undefined) {
                confirmDialog({
                    message: 'Are you sure you want to delete this unit?',
                    header: 'Delete Unit',
                    icon: 'pi pi-exclamation-triangle',
                    style: {'width': '30rem'},
                    acceptClassName: 'p-button-danger',
                    acceptLabel: 'Delete',
                    rejectLabel: 'Cancel',
                    rejectClassName: 'p-button-secondary',
                    accept: () => dispatch(deleteUnit(unitId))
                });
        
            }
        }

        const Buttons = (
                <>
                    <Button label="Log" className="p-button-info" />
                    <Button label="API Log" className="p-button-info"
                            onClick={e => showApiLog()} />
                    <Button label="Statistics" className="p-button-info p-button-warning" />
                    <Button label="Playlists" className="p-button-info p-button-warning"
                            onClick={e => showPlaylists()} />
                    { isAdmin && (
                        <Button label="Service" className="p-button-info p-button-warning"
                            onClick={e => showServiceLog()} />
                    )}
                    <Button label="Delete" className="p-button-info p-button-danger"
                            onClick={showDeleteUnit} />
                </>
        );

        return (
            <ContentFormStandardButtons buttons={Buttons} 
                acceptLabel="Update" onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const FormBody = () => {
        return (
            <div className="EditUnitForm--Body">
                <FormBodyPart1 />
                <FormBodyPart2 />
                <FormBodyPart3 />
            </div>
        );
    }

    return (
        <GenericContentForm title="Unit Details" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={initialValues} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditUnitForm;
