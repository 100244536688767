import { UnitDataRow, UnitFilterOptions } from "../../network/NetworkInterfaces";
import { AjaxHelper } from "./AjaxHelper";


export class UnitsNetworkHelper {

    formatUpdateRequest(data: any): any {
        const fields = ["SerialNo", "IntegratorSerialNo", "AirCompressorTimeout", "Lat", "Lng",
                "IntegratorNo", "DistributorNo", "EndCustomerNo", "AudioOutVolume", "Note", "RequestedFirmwareNo"];
        return AjaxHelper.formatAjaxRequest(data, fields);
    }

    formatSearchRequest(options: UnitFilterOptions): any {
        return {
            integrators: options.integrators.length === 0 ? [0] : options.integrators,
            distributors: options.distributors.length === 0 ? [0] : options.distributors,
            endcustomers: options.endCustomers.length === 0 ? [0] : options.endCustomers
        };
    }

    parseUnits(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["units"], "SerialNo");
    }

    parseEndCustomers(response: any): any[] {
        return AjaxHelper.parseAjaxEnumResponse(response, ["Endcustomers"], "EndCustomerNo", "Name");
    }

    parseFirmware(response: any): any[] {
        return AjaxHelper.parseAjaxEnumResponse(response, ["Requestedfirmwareno"], "FirmwareNo", "FirmwareNo");
    }

    parseIntegrators(response: any): any[] {
        return AjaxHelper.parseAjaxEnumResponse(response, ["Integrators"], "IntegratorNo", "Name");
    }

    parseDistributors(response: any): any[] {
        return AjaxHelper.parseAjaxEnumResponse(response, ["Distributors", "Distributor_associated"], 
                        "DistributorNo", "Name");
    }

    parsePlaylists(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["playlist_data"], "FileNo");
    }

    parseSchedules(response: any): any {
        return AjaxHelper.parseAjaxResponse(response, ["playlist_schedules_data"], "");
    }

    formatPlaylistUpdateRequest(playlist: any) {
        const select_data = playlist.map((p: any) => p.FileNo);
        const playlist1_data = playlist.map((p: any) => parseInt(p.InPlaylist1) === 1 ? 1 : 0);
        const playlist2_data = playlist.map((p: any) => parseInt(p.InPlaylist2) === 1 ? 1 : 0);
        return {select_data, playlist1_data, playlist2_data};
    }
}
