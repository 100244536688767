import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';
import { FilterHelper } from '../helpers/FilterHelper';
import * as _ from 'lodash';
import { NavigationHelper } from '../helpers/NavigationHelper';
import { UserType } from '../network/NetworkInterfaces';

// Network API - contains method to fetch users and their types from the server
const usersNetworkAPI = NetworkManager.getUsersNetworkAPI();

// Asynchrounous methods waiting for HTTP request to complete and then update Store data
export const fetchEndCustomers = createAsyncThunk(
    'endCustomers/fetchEndCustomers',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingEndCustomerStatus(true));
       
        const endCustomersData = await usersNetworkAPI.fetchEndCustomers();

        dispatch(updateFetchedEndCustomers(endCustomersData));
        dispatch(updateLoadingEndCustomerStatus(false));
    }
);

// Filters end customer records by text
export const filterEndCustomersByPhrase = createAsyncThunk(
    'endCustomers/filterEndCustomersByPhrase',
    async (phrase: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { endCustomers } = thunkAPI.getState() as any;

        const filteredEndCustomers = FilterHelper.filterRecordsUsingPhrase(phrase, endCustomers.fetchedEndCustomers,
            ["EndCustomerNo", "Name"]);
        dispatch(updateShownEndCustomers(filteredEndCustomers));
    }
);

export const createNewEndCustomer = createAsyncThunk(
    'endCustomers/createNewEndCustomer',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateEditedEndCustomer({Name: "", associatedNo: null, WelcomeText: "", EndcustomerLogo: null}));
        NavigationHelper.toAppUrl("/end-customers/edit/")
    }
);

export const editEndCustomer = createAsyncThunk(
    'endCustomers/editEndCustomer',
    async (id: number, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { endCustomers } = thunkAPI.getState() as any;
        const fetchedEndCustomers: any[] = endCustomers.fetchedEndCustomers;

        // Find edited Sound DataRow
        let editedEndCustomer = fetchedEndCustomers.find(endC => endC.EndCustomerNo === id);
        if(editedEndCustomer === undefined)
            throw new Error("EndCustomer to edit was not found in fetched sounds.");

        editedEndCustomer = _.cloneDeep(editedEndCustomer);
        if(editedEndCustomer.EndcustomerLogo === "") {
            editedEndCustomer.EndcustomerLogo = null;
        }
    
        // Update edited sound data to the store
        dispatch(updateEditedEndCustomer(editedEndCustomer));

        // Finally navigate to Edit Form
        NavigationHelper.toAppUrl(`/end-customers/edit/`);       
    }
);

export const fetchUsersRelatedToEndCustomer = createAsyncThunk(
    'endCustomers/fetchUsersRelatedToEndCustomer',
    async (userType: UserType, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const fetchedUsers = await usersNetworkAPI.getAllUsersRelatedTo(userType);
        dispatch(updateRelatedUsers(fetchedUsers));
    }
);

export const saveEndCustomer = createAsyncThunk(
    'endCustomers/saveEndCustomer',
    async (endCustomerData: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingEndCustomerStatus(true));       
        try {
            endCustomerData = _.cloneDeep(endCustomerData);
            if(endCustomerData.EndcustomerLogo instanceof File) {
                endCustomerData.EndcustomerLogo = await usersNetworkAPI.saveEndCustomerLogo(endCustomerData.EndcustomerLogo);
            } else {
                if(endCustomerData.EndcustomerLogo === null) {
                    if(endCustomerData.EndCustomerNo !== undefined) {
                        await usersNetworkAPI.deleteEndCustomerLogo(endCustomerData.EndCustomerNo);
                    }
                    delete endCustomerData.EndcustomerLogo;
                } else {
                    delete endCustomerData.EndcustomerLogo;
                }
            }
            
            await usersNetworkAPI.saveEndCustomer(endCustomerData);
            NavigationHelper.goBack();
        }
        finally {
            dispatch(updateLoadingEndCustomerStatus(false));
        }
    }
);

export const deleteEndCustomer = createAsyncThunk(
    'endCustomers/deleteEndCustomer',
    async (endCustomerNo: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingEndCustomerStatus(true));       
            await usersNetworkAPI.deleteEndCustomer(endCustomerNo);
        }
        finally {
            dispatch(updateLoadingEndCustomerStatus(false));
        }
        dispatch(fetchEndCustomers())
    }
);

// Initial End Customer Store State
const INITIAL_STATE = {
    shownEndCustomers: [],
    fetchedEndCustomers: [],
    loadingEndCustomers: false,
    editedEndCustomer: {
        associatedNo: null
    },
    relatedUsers: []
}

// Redux Slice for Users Module
export const endCustomersSlice = createSlice({
    name: 'endCustomers',
    initialState: INITIAL_STATE,
    reducers: {
        updateLoadingEndCustomerStatus: (state, action) => {
            state.loadingEndCustomers = action.payload;
        },
        updateFetchedEndCustomers: (state, action) => {
            state.fetchedEndCustomers = action.payload;
            state.shownEndCustomers = _.clone(action.payload);
        },
        updateShownEndCustomers: (state, action) => {
            state.shownEndCustomers = action.payload;
        },
        updateEditedEndCustomer: (state, action) => {
            const endCustomer = _.cloneDeep(action.payload);
            if(endCustomer.associatedTo !== "Distributor") {
                endCustomer.associatedTo = "Integrator";
            }
            state.editedEndCustomer = endCustomer;
        },
        updateRelatedUsers: (state, action) => {
            state.relatedUsers = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateLoadingEndCustomerStatus,
    updateFetchedEndCustomers,
    updateShownEndCustomers,
    updateEditedEndCustomer,
    updateRelatedUsers,
} = endCustomersSlice.actions

export default endCustomersSlice.reducer
