import { Column } from "primereact/column";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingProgress from "../common/LoadingProgress";
import ActionsDataList, { DataListAction } from "../forms/ActionsDataList";
import {Button} from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import "./FirmwareDataList.scss";
import { deleteFirmware, fetchFirmwares, filterFirmwares, showAssignFirmwareForm, showUploadFirmwareForm, updateLastFilterSettings } from "./FirmwareReduxSlice";
import { SearchBox } from "../common/AppSearchBox";
import { useState } from "react";
import { confirmDialog } from "primereact/confirmdialog";
import AssignDefaultFirmwareForm from "./forms/AssignDefaultFirmwareForm";

const FirmwareFilterHeader = () => {
    const [cupuType, setCupuType] = useState<string | null>(null); 
    const [phrase, setPhrase] = useState<string>("");

    const dispatch = useDispatch();
    const handleFilterChanged = (value: string) => {
        setPhrase(value);
        dispatch(filterFirmwares({phrase: value, hardwareCode: cupuType}));
    }

    const handleCupuTypeChanged = (value: string | null) => {
        setCupuType(value);
        dispatch(filterFirmwares({phrase: phrase, hardwareCode: value}));       
    }

    const handleUploadFirmware = () => {
        dispatch(showUploadFirmwareForm());
    }

    const handleAssignDefaultFirmwares = () => {
        dispatch(showAssignFirmwareForm(true));
    }

    const cupuTypes = useSelector((state: any) => state.firmwares.cupuTypes).map((cupuType: any) => {
        return {
            id: cupuType.id,
            name: cupuType.id + " - " + cupuType.name
        }
    });

    return (
        <div className="FirmwareDataList--Header">
            <SearchBox placeholder="Search firmwares..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <Dropdown placeholder="All CUPU Types" options={cupuTypes} value={cupuType}
                    onChange={(e) => handleCupuTypeChanged(e.value)} 
                    optionLabel="name" optionValue="id" showClear />
            <Button label="Assign Default Firmwares" onClick={handleAssignDefaultFirmwares}
                    className="p-button-secondary" />
            <div />
            <Button label="Upload Firmware" className="p-button-success" 
                onClick={e => handleUploadFirmware()}/>
        </div>
    );
}

const FirmwareTableAtions: DataListAction[] = [
    {name: "delete", label: "Delete", className: "p-button-danger"}
];

const FirmwareDataListTable = () => {
    const firmwareData = useSelector((state: any) => state.firmwares.shownFirmwares);
    const handleActionClick = (data: {name: string, id: any}) => {
        switch(data.name) {
            case "delete": confirmDelete(data.id); break;
        }
    }

    // Delete Confirmation Function
    const dispatch = useDispatch();
    const confirmDelete = (id: string) => {
        confirmDialog({
            message: 'Are you sure you want to delete this firmware?',
            header: 'Delete Firmware',
            icon: 'pi pi-exclamation-triangle',
            style: {'width': '30rem'},
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            rejectClassName: 'p-button-secondary',
            accept: () => dispatch(deleteFirmware(id))
        });
    }    


    return (
        <ActionsDataList value={firmwareData} dataKey="FirmwareNo" actions={FirmwareTableAtions} actionColumnWidth="8rem"
                       onAction={handleActionClick} stripedRows  >
            <Column header="FirmwareNo" field="FirmwareNo" sortable style={{maxWidth: "10rem", textAlign: "left"}} />
            <Column header="Hardware Code" field="HardwareCode" sortable style={{maxWidth: "12rem", textAlign: "center"}} />
            <Column header="Description" field="Description" sortable style={{maxWidth: "auto", textAlign: "left"}} />
            <Column header="FileName" field="FileName" sortable style={{maxWidth: "10rem", textAlign: "center"}} />
            <Column header="CRC(HEX)" field="CRC" sortable style={{maxWidth: "10rem", textAlign: "center"}} />
        </ActionsDataList>
    );
}

const FirmwareDataList = () => {
    // Fetch isLoading status from the Store
    const isLoading = useSelector((state: any) => state.firmwares.firmwareLoadingStatus);
    const showAssignFirmwareForm = useSelector((state: any) => state.firmwares.showAssignFirmwareForm);

    // Fetch the data for this page
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(updateLastFilterSettings({}));
        dispatch(fetchFirmwares());
    }, []);

    return (
        <div className="FirmwareDataList LoadingProgress--Wrapper">
            { showAssignFirmwareForm && (<AssignDefaultFirmwareForm />)}
            <LoadingProgress show={isLoading} />
            <FirmwareFilterHeader />
            <div className="FirmwareDataListHeader--Separator" />
            <FirmwareDataListTable />
        </div>
    );
}

export default FirmwareDataList;
