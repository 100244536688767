import { NetworkHelper } from "../../helpers/NetworkHelper";
import { PDFManualsAPI } from "../NetworkInterfaces";
import { CupuNetworkAPIBase } from "./CupuNetworkAPIBase";

export class CupuPDFManualsAPI extends CupuNetworkAPIBase implements PDFManualsAPI {

    async fetchManuals(): Promise<any[]> {
        const ajaxRequest = super.createAjaxRequest("GET", "support/get_files", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data;
    }

    async uploadManual(data: any): Promise<void> {
        const formData = NetworkHelper.convertToFormData(data);
        const ajaxRequest = super.createAjaxRequest("POST", "support/upload_file", formData, "json");
        ajaxRequest.processData = false;
        ajaxRequest.contentType = false;
        await super.callAjax(ajaxRequest);
    }

    async deleteManual(fileName: string): Promise<void> {
        const ajaxData = {filename: fileName};
        const ajaxRequest = super.createAjaxRequest("POST", "support/delete_file", ajaxData, "json");
        await super.callAjax(ajaxRequest);
    }

    async downloadManual(fileName: string, download: boolean): Promise<Blob> {
        const url = `/support/download_file?filename=${fileName}&download=${download ? '1' : '0'}`;
        const response = await fetch(url);
        return await response.blob();
    }
}
