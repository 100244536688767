import { NetworkHelper } from "../../helpers/NetworkHelper";
import { OrdersAPI } from "../NetworkInterfaces";
import { AjaxRequest, CupuNetworkAPIBase } from "./CupuNetworkAPIBase";

export class CupuOrdersNetworkAPI extends CupuNetworkAPIBase implements OrdersAPI {

    async fetchOrders(): Promise<any[]> {
        const ajaxRequest: AjaxRequest = super.createAjaxRequest("GET", "orders/get_orders", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Orders.parseOrdersResponse(response);
    }

    async saveOrder(orderData: any): Promise<void> {
        const  ajaxRequest = super.createAjaxRequest("POST", "orders/save_order", orderData, "json");
        await super.callAjax(ajaxRequest);
    }
    
    async setOrderInProduction(OrderNo: string): Promise<void> {
        const ajaxData = { OrderNo };
        const ajaxRequest = super.createAjaxRequest("POST", "Orders/set_in_production", ajaxData, "json");
        await super.callAjax(ajaxRequest);
    }

    async getInProductionOrdersNumber(): Promise<number> {
        const ajaxRequest = super.createAjaxRequest("GET", "Orders/get_in_production_orders_number", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data.number_of_orders;
    }

    async getAllIntegrators(): Promise<any[]> {
        const ajaxRequest = super.createAjaxRequest("POST", "Integrator/get_Integrators", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Orders.parseIntegrators(response);
    }

    async getInProductionOrder(): Promise<any> {
        const ajaxRequest = super.createAjaxRequest("GET", "production/get_in_production_order", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return response.response_data;
    }

    async getUnitsOfOrder(orderNo: string): Promise<any[]> {
        const ajaxData = {OrderNo: orderNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Production/get_units_of_order", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Orders.parseUnitsInProductionResponse(response);
    }
}
