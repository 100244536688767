/*
     This file contains components adapting mainly existing PrimeReact components 
     to be easily used by the Formik framework
*/

// React and Formik Imports
import React, { useEffect, useRef, useState } from "react";
import { useField, ErrorMessage } from "formik";
import { InputText  } from "primereact/inputtext";
import { Password } from "primereact/password";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { FileUpload } from 'primereact/fileupload';
import { Button } from "primereact/button";
import { PickList } from 'primereact/picklist';
import {Checkbox} from "primereact/checkbox";
import { InputNumber } from "primereact/inputnumber"
import VolumeControl from "./VolumeControl";
import {Calendar} from "primereact/calendar";

// Clocklet Import
import "clocklet/css/clocklet.min.css";
import clocklet from "clocklet";

// Import Form Component Styles
import "./formik.scss";
import AvatarColumn from "./AvatarColumn";

// Formik Field Label
const FormikLabel = (props: any) => {
    if(props.label === null) { 
        return (<label>&nbsp;</label>); // Return Empty Label
    } else {
        return (<label>{ props.label}</label>);
    }
}

// General Wrapper Component for a Formik Field
const FormikField = (props: any) => {
    return (
        <div className="Form--FormField">
            <FormikLabel label={props.label}></FormikLabel>
            { props.children }
            <div className="Form--FormField--ErrorMessage--Wrapper">
                <div className="Form--FormField--ErrorMessage">
                <ErrorMessage name={props.name} component="div" ></ErrorMessage>
                </div>
            </div>
        </div>
    );
}

// TextField Formik Component
export const FormikTextField = (props: any) => {
    const [field ] = useField(props.name);    
    return (
        <FormikField label={props.label} name={props.name}  >
            <InputText {...props} {...field} autoComplete="off"  />
        </FormikField>
    );
}

// TextField Formik Component
export const FormikDatePickerField = (props: any) => {
    const [field, meta, helpers ] = useField(props.name); 
    
    const [state, setState] = useState<Date | undefined>(undefined);

    useEffect(() => {
        if(field.value === null || field.value === undefined) {
            setState(undefined);
        } else {
            const date = new Date(field.value);
            setState(date);
        }
    }, [field.value])

    const handleOnChange = (value: Date | Date[] | undefined) => {
        if(value instanceof Date) {
            setState(value);
            const dateString = `${value.getFullYear()}-${value.getMonth()+1}-${value.getDate()}`;
            helpers.setValue(dateString);
            helpers.setTouched(true);
        } else if(value === undefined) {
            setState(undefined);
        }
    }

    return (
        <FormikField label={props.label} name={props.name}  >
            <Calendar {...props} {...field} autoComplete="off" showIcon showOnFocus={false} readOnlyInput
                dateFormat="dd.mm.yy" value={state} onChange={(e) => handleOnChange(e.value)}  />
        </FormikField>
    );
}


// NumberField Formik Component
export const FormikNumberField = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helper ] = useField(props.name);    
    const [state, setState] = useState(field.value);

    const handleNumberChanged = (value: any) => {
        setState(value);
        helper.setTouched(true);
        helper.setValue(value);
    }

    return (
        <FormikField label={props.label} name={props.name}  >
            <InputNumber value={state} type="text" showButtons mode="decimal" onValueChange={e => handleNumberChanged(e.value)} 
                    {...props}  />
        </FormikField>
    )
}

// NumberField Formik Component
export const FormikVoltageField = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helper ] = useField(props.name);    
    const [state, setState] = useState(field.value);

    const handleNumberChanged = (value: any) => {
        setState(value);
        helper.setTouched(true);
        helper.setValue(value);
    }

    return (
        <FormikField label={props.label} name={props.name}  >
            <InputNumber value={state} type="text" showButtons mode="decimal" onValueChange={e => handleNumberChanged(e.value)} 
                    {...props} minFractionDigits={2} maxFractionDigits={2} className="FormikVoltageField" 
                    locale="en-US" />
        </FormikField>
    )
}


// PasswordField Formik Component
export const FormikPasswordField = (props: any) => {
    const [field ] = useField(props.name);    
    return (
        <FormikField label={props.label} name={props.name}  >
            <Password {...props} {...field} feedback={false} style={{width: "100%"}}
                    autoComplete="off" />
        </FormikField>
    );
}

// TextAreaField Formik Component
export const FormikTextAreaField = (props: any) => {
    const [field ] = useField(props.name);    
    return (
        <FormikField label={props.label} name={props.name}  >
            <InputTextarea {...props} {...field}  ></InputTextarea>
        </FormikField>
    );
}

// DropdownField Formik Component ---------------------------------- CHECK
export const FormikDropdownField = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helper ] = useField(props.name);   

    if(field.value === undefined) {
        field.value = null;
        helper.setValue(null, true);
    }
    
    const handleOnChange = (e: any) => {
        helper.setValue(e.value);
        helper.setTouched(true, false);
        if(typeof props.onChange == "function") {
            props.onChange(e)
        }
    }
    
    return (
        <FormikField label={props.label} name={props.name}  >
            <Dropdown {...props} {...field} style={{"width": "100%"}} onChange={e => handleOnChange(e)} ></Dropdown>
        </FormikField>
    );    
}

// Weekdays DropdownField Formik Component
const WEEK_DAYS = [
    {label: "Monday", value: "1"},
    {label: "Tuesday", value: "2"},
    {label: "Wednesday", value: "3"},
    {label: "Thursday", value: "4"},
    {label: "Friday", value: "5"},
    {label: "Saturday", value: "6"},
    {label: "Sunday", value: "7"}   
];

export const FormikWeekDayDropdownField = (props: any) => {
    return (<FormikDropdownField  options={WEEK_DAYS} {...props} />);
}

// PickList Formik Component ---------------------------------- CHECK
export const FormikPickListField = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helper ] = useField(props.name);   

    const [source, setSource] = useState(props.source);
    const [target, setTarget] = useState(props.target);

    const onPicklistChange = (e: any) => {
        setSource(e.source);
        setTarget(e.target);
        helper.setTouched(true, true);   
        helper.setValue(e.target, true);
    }

    useEffect(() => {
        helper.setValue(target, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const picklistItemTemplate = (item: any) => ( <div> {item.label}</div> );

    return (
        <FormikField label={null} name={props.name} >
            <PickList {...props} {...field} source={source} target={target} onChange={e => onPicklistChange(e)}
                showSourceControls={false} showTargetControls={false} 
                    className="Formik-PickList"  itemTemplate={picklistItemTemplate} ></PickList>                    
        </FormikField>
    );
}

interface FileInfo {
    name: string;
    size: number;
    lastModified: number;
}


// FileUpload Formik Component ---------------------------------- CHECK
export const FormikFileUploadField = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helpers] = useField(props.name);
    const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);

    const refUpload = useRef<FileUpload>(null);

    const handleFileSelected = (file: File) => {
        setFileInfo({
            name: file.name,
            size: file.size,
            lastModified: file.lastModified
        });
        helpers.setTouched(true, true);
        helpers.setValue(file);
    }

    const handleClearFile = () => {
        refUpload.current?.clear();
        setFileInfo(null);
        helpers.setValue(null);
        helpers.setTouched(true, true);
    }

    let humanFileSize = "";
    if(fileInfo != null) {
        let fileSize = fileInfo.size / 1024;
        if(fileSize > 1024) {
            fileSize = fileSize / 1024;
            humanFileSize = `${fileSize.toFixed(2)} MB`;
        } else {
            humanFileSize = `${fileSize.toFixed(2)} KB`;
        }
    }

    const fileInfoSummary = fileInfo === null ? "No file has been selected." :
        (<React.Fragment>
            <b>File size:</b> {humanFileSize} &nbsp;&nbsp;&nbsp;
            <b>Last modified:</b> {new Date(fileInfo.lastModified).toDateString()}
        </React.Fragment>
        );

    return (
        <div>
            <FormikField label={props.label} name={props.name}  >
                <div className="FormikFileUploadField">
                <FileUpload ref={refUpload} {...props} onSelect={e => handleFileSelected(e.files[0])} mode="basic"
                    className="p-upload-secondary"></FileUpload>
                <Button icon="fa fa-times" className="p-button-danger" onClick={handleClearFile} />
                <span>{ fileInfoSummary  }</span>
                </div>
            </FormikField>
        </div>
    );
}


// AvatarUpload Formik Component 
export const FormikAvatarUploadField = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helpers] = useField(props.name);
    const [fileInfo, setFileInfo] = useState<FileInfo | null>(null);
    const [downloadUrlPrefix, setDownloadUrlPrefix] = useState<string>(props.downloadUrlPrefix);
    const [avatarUrl, setAvatarUrl] = useState<string>(props.value)

    const refUpload = useRef<FileUpload>(null);

    const handleFileSelected = (file: File) => {
        setFileInfo({
            name: file.name,
            size: file.size,
            lastModified: file.lastModified
        });
        helpers.setTouched(true, true);
        helpers.setValue(file);
        const objectUrl = URL.createObjectURL(file);
        setDownloadUrlPrefix("");
        setAvatarUrl(objectUrl);
    }

    const handleClearFile = (event: any) => {
        refUpload.current?.clear();
        setFileInfo(null);
        setAvatarUrl("");
        helpers.setValue(null);
        helpers.setTouched(true, true); 
        event.preventDefault();
    }

    let humanFileSize = "";
    if(fileInfo != null) {
        let fileSize = fileInfo.size / 1024;
        if(fileSize > 1024) {
            fileSize = fileSize / 1024;
            humanFileSize = `${fileSize.toFixed(2)} MB`;
        } else {
            humanFileSize = `${fileSize.toFixed(2)} KB`;
        }
    }

    const fileInfoSummary = fileInfo === null ? "No file has been selected." :
        (<React.Fragment>
            <b>File size:</b> {humanFileSize} &nbsp;&nbsp;&nbsp;
            <b>Last modified:</b> {new Date(fileInfo.lastModified).toDateString()}
        </React.Fragment>
        );

    return (
        <div>
            <FormikField label={props.label} name={props.name}  >
                <div className="AvatarUpload">
                    <div>
                        <AvatarColumn downloadUrlPrefix={downloadUrlPrefix} 
                                    src={avatarUrl} maxImageSize={250} />
                    </div>                    
                    <div>
                    <div className="FormikFileUploadField">                        
                        <FileUpload auto={false} customUpload={true} ref={refUpload} {...props} onSelect={e => handleFileSelected(e.files[0])} mode="basic"
                            className="p-upload-secondary" chooseLabel="Choose Logo Image File"  ></FileUpload>
                        <Button icon="fa fa-times" className="p-button-danger" onClick={handleClearFile} />
                        <span>{ fileInfoSummary  }</span>
                    </div>
                    </div>
                </div>
            </FormikField>
        </div>
    );
}

// SoundVolumeField Formik Component
export const FormikSoundVolumeField = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helpers ] = useField(props.name);
    const [state, setState] = useState(field.value);

    const updateState = (value: any) => {
        setState(value);
        helpers.setTouched(true);
        helpers.setValue(value);
    }

    const label = (<React.Fragment>{`${props.label}:  `}<b>{state}</b></React.Fragment>);

    return (
        <FormikField label={label} name={props.name}  >
            <VolumeControl {...props} {...field} value={state} onChange={(value: any) => updateState(value)} ></VolumeControl>
        </FormikField>
    );
}

// FormikCheckboxField Formik Component
export const FormikCheckboxField = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helpers ] = useField(props.name);
    const [state, setState] = useState(field.value);

    const handleStateChanged = (value: any) => {
        setState(value);
        helpers.setTouched(true);
        helpers.setValue(value);
    }

    return (
        <FormikField label={undefined} name={props.name}  >
            <div className="Form--FormField--Checkbox">
                <Checkbox checked={state} {...props} {...field}   onChange={(e: any) => handleStateChanged(e.checked)} />
                <label className="p-checkbox-label" onClick={e => handleStateChanged(! state)} >{ props.label }</label>
                <div />
            </div>
        </FormikField>
    );
};
   
// Formik Clocklet Component (aka TimePicker)
export const FormikClocklet = (props: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars 
    const [field, meta, helpers ] = useField(props.name);
    const [state, setState] = useState<string>(field.value);

    const checkHourValueRange = (value: string) => {
        let intValue = parseInt(value);
        if(isNaN(intValue)) return "";
        if(intValue < 0) intValue = 0;
        if(intValue > 23) intValue = 23;
        return intValue.toString();
    }

    const handleStateChanged = (value: string) => {
        value = checkHourValueRange(value);
        setState(value);
        helpers.setTouched(true, true);
        helpers.setValue(value, true);
    }

    clocklet.defaultOptions.zIndex = 9999;
    clocklet.defaultOptions.format = "HH";
    
    return (
        <FormikField label={undefined} name={props.name}  >
            <div className="Form--FormField--Clocklet">
                <InputText type="text" data-clocklet="" {...props} {...field} value={state}
                        onInput={e => handleStateChanged(e.currentTarget.value)}  autoComplete="off"  />
            </div>
        </FormikField>
    );
}
