import React from "react"
import { useLocation } from "react-router"

const usePrevious = (value: any) => {
    const ref = React.useRef()
    React.useEffect(() => { ref.current = value }, [ref, value]);  
    return ref.current
}
  
export const useLocationChange = (action: any) => {
    const location = useLocation();
    const prevLocation = usePrevious(location)
    React.useEffect(() => { 
      action(location, prevLocation) 
    }, [location, prevLocation, action])
}
