import { Button } from "primereact/button";
import { Column } from "primereact/column";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../common/AppSearchBox";
import LoadingProgress from "../common/LoadingProgress";
import ActionsDataList, { DataListAction } from "../forms/ActionsDataList";
import { createNewUser, deleteUser, editUser, fetchUsers, filterUsersByPhrase } from "./UsersReduxSlice";

// Import the page style
import "./UsersDataList.scss";
import AvatarColumn from "../forms/AvatarColumn";
import { confirmDialog } from "primereact/confirmdialog";

// Wrapper Component for full-text search
const UsersFilterHeader = () => {
    // Handler for filtering users - dispatches filtering in the Store
    const dispatch = useDispatch();
    const handleFilterChanged = (phrase: string) => {
        dispatch(filterUsersByPhrase(phrase));
    }

    const handleCreateNewUser = () => {
        dispatch(createNewUser());
    }

    // Render DOM
    return (
        <div className="UsersDataList--Header">
            <SearchBox placeholder="Search users..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <div />
            <Button label="New User" className="p-button-success"
                    onClick={e => handleCreateNewUser()} />
        </div>
    );
}

// Definition of Action Buttons for the UsersDataTable
const UsersTableAtions: DataListAction[] = [
    {name: "edit", label: "Edit", className: "p-button-secondary"},
    {name: "delete", label: "Delete", className: "p-button-danger"}
]

// Component for configuring the DataList Table component itself
const UsersDataListTable = () => {
    // Code for invoking the initial fetching of data from the server
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Select the filtered list of users from the Redux Store    
    const usersData = useSelector((state: any) => state.users.shownUsers);

    // Delete Confirmation Function
    const confirmDelete = (id: string) => {
        confirmDialog({
            message: 'Are you sure you want to delete this user?',
            header: 'Delete User',
            icon: 'pi pi-exclamation-triangle',
            style: {'width': '30rem'},
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            rejectClassName: 'p-button-secondary',
            accept: () => dispatch(deleteUser(id))
        });
    }    

    // Code for handler for receiving clicks on Action Buttons
    const handleActionClick = (data: {name: string, id: any}) => {
        switch(data.name) {
            case "edit": dispatch(editUser(data.id)); break;
            case "delete": confirmDelete(data.id); break;
        }
    }

    const AvatarColumnTemplate = (dataRow: any) => {
        return (<AvatarColumn  downloadUrlPrefix="/uploads/userlogos/" src={dataRow.UserPicture} />);
    }

    // Render the final DOM of Users Data List
    return (
        <ActionsDataList value={usersData} dataKey="ID" actions={UsersTableAtions} actionColumnWidth="15rem"
                       onAction={handleActionClick} stripedRows  >
            <Column header="Id" field="ID" sortable style={{maxWidth: "5rem", textAlign: "center"}} />
            <Column header="Username" field="Username" sortable style={{maxWidth: "15rem", textAlign: "left"}} />
            <Column header="User Picture" field="UserPicture" style={{maxWidth: "auto", textAlign: "center"}} 
                       body={AvatarColumnTemplate} />
            <Column header="Email" field="Email" sortable style={{maxWidth: "15rem", textAlign: "center"}} />
            <Column header="Type" field="Type" sortable style={{maxWidth: "9rem", textAlign: "center"}} />
        </ActionsDataList>
    );
}

// Composes the whole UsersDataList Content Page
const UsersDataList = () => {
    // Fetch isLoading status from the Store
    const isLoading = useSelector((state: any) => state.users.loadingUsers);

    // Render DOM
    return (
        <div className="UsersDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <UsersFilterHeader />
            <div className="UsersDataListHeader--Separator" />
            <UsersDataListTable />
        </div>
    );
}

export default UsersDataList;
