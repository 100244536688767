import { Button } from "primereact/button";
import { Column } from "primereact/column";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../common/AppSearchBox";
import LoadingProgress from "../common/LoadingProgress";
import ActionsDataList from "../forms/ActionsDataList";
import OrderInProductionSettings from "./OrderInProductionSettings";

import "./OrdersDataList.scss";
import { createNewOrder, fetchOrders, filterOrdersByPhrase } from "./OrdersReduxSlice";

// Wrapper Component for full-text search
const OrdersFilterHeader = () => {
    const dispatch = useDispatch();
    const handleFilterChanged = (phrase: string) => {
        dispatch(filterOrdersByPhrase(phrase));
    }

    const handleCreateNewOrder = () => {
        dispatch(createNewOrder());
    }

    return (
        <div className="OrdersDataList--Header">
            <SearchBox placeholder="Search orders..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <OrderInProductionSettings />
            <div />
            <Button label="New Order" className="p-button-success" 
                onClick={e => handleCreateNewOrder()}/>
        </div>
    );
}

const OrdersDataListTable = () => {
    // Code for invoking the initial fetching of data from the server
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Select the filtered list of users from the Redux Store    
    const ordersData = useSelector((state: any) => state.orders.shownOrders);

    return (
        <ActionsDataList value={ordersData} dataKey="OrderNo" actions={[]} actionColumnWidth="0rem"
                       onAction={undefined} stripedRows showGridlines  >
            <Column header="OrderNo" field="OrderNo" sortable style={{maxWidth: "5rem", textAlign: "center"}} />
            <Column header="Integrator" field="IntegratorName" sortable style={{maxWidth: "auto", textAlign: "center"}} />
            <Column header="Received Date" field="ReceivedDate" sortable style={{maxWidth: "auto", textAlign: "center"}} />
            <Column header="IntegratorOrderNo" field="IntegratorOrderNo" sortable style={{maxWidth: "auto", textAlign: "center"}} />
            <Column header="Description" field="Description" sortable style={{maxWidth: "auto", textAlign: "center"}} />
            <Column header="Quantity Ordered" field="QuantityOrdered" sortable style={{maxWidth: "auto", textAlign: "center"}} />
            <Column header="Delivery Date" field="DeliveryDate" sortable style={{maxWidth: "auto", textAlign: "center"}} />
            <Column header="Amount of Units" field="ManufacturedUnits" sortable style={{maxWidth: "auto", textAlign: "center"}} />
        </ActionsDataList>
    );

}


const OrdersDataList = () => {
    // Fetch isLoading status from the Store
    const isLoading = useSelector((state: any) => state.orders.loadingOrders);

    return (
        <div className="OrdersDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <OrdersFilterHeader />
            <div className="OrdersDataListHeader--Separator" />
            <OrdersDataListTable />
        </div>
    );
}

export default OrdersDataList;
