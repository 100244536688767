import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUnitsInProduction, filterUnitsInProduction } from "./OrdersReduxSlice";

import "./InProductionDataList.scss";
import { SearchBox } from "../common/AppSearchBox";
import LoadingProgress from "../common/LoadingProgress";
import ActionsDataList from "../forms/ActionsDataList";
import { Column } from "primereact/column";

// Wrapper Component for full-text search
const InProductionFilterHeader = () => {

    const dispatch = useDispatch();
    const handleFilterChanged = (phrase: string) => {
        dispatch(filterUnitsInProduction(phrase));
    }

    const qtyOrdered = useSelector((state: any) => state.orders.qtyOrdered);
    const qtyRemaining = useSelector((state: any) => state.orders.qtyRemaining);

    return (
        <div className="InProductionDataList--Header">
            <SearchBox placeholder="Search units..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <div className="InProductionDataList--Header--Quantities" >
                <span>Order Quantity: {qtyOrdered}</span>
                <span>Remaining Units to be manufactured: {qtyRemaining}</span>
            </div>
            <div />
        </div>
    );
}

const InProductionDataTable = () => {
    // Select the filtered list of users from the Redux Store    
    const unitsInProduction = useSelector((state: any) => state.orders.shownUnitsInProduction);

    return (
        <ActionsDataList value={unitsInProduction}  actions={[]} actionColumnWidth="0rem" dataKey="SerialNo"
                       onAction={undefined} stripedRows sortField="TestDate" sortOrder={-1}  >
            <Column header="SerialNo" field="SerialNo" sortable style={{maxWidth: "auto", textAlign: "left"}} />
            <Column header="TestDate" field="TestDate" sortable style={{maxWidth: "auto", textAlign: "left"}} />
            <Column header="StatusTimestamp" field="StatusTimestamp" sortable style={{maxWidth: "auto", textAlign: "left"}} />
            <Column header="StatusText" field="StatusText" sortable style={{maxWidth: "auto", textAlign: "left"}} />
        </ActionsDataList>
    );
}

const InProductionDataList = () => {
    // Invoke fetch of units in production
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUnitsInProduction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Fetch isLoading status from the Store
    const isLoading = useSelector((state: any) => state.orders.loadingOrders);

    return (
        <div className="InProductionDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <InProductionFilterHeader />
            <div className="InProductionDataList--Separator" />
            <InProductionDataTable />
        </div>
    );   
}

export default InProductionDataList;
