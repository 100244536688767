import { UserType } from "./NetworkInterfaces";
import { NetworkManager } from "./NetworkManager";
import { showExceptionDialog } from "../AppReduxSlice";

export function RestrictAccess(userRoles: UserType[]) {
    return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
        const originalMethod = descriptor.value;
        descriptor.value = async function(...args: any[]) {
            const loginAPI = NetworkManager.getLoginNetworkAPI();

            if(loginAPI.isUserLoggedIn() === false) {
                const store = await import("../Store");

                const errorMessage = {
                    html: `HTTP AJAX Method <b>${propertyKey}</b> is not allowed to be called <b>when the user is not logged in</b>.`
                }

                store.default.dispatch(showExceptionDialog({
                    title: "Security Exception",
                    message: "The user is not logged in. It cannot access this HTTP AJAX method.",
                    ...errorMessage
                }));

                throw errorMessage.html;
            }

            const userRole = await loginAPI.getLoggedUserType();
            if(userRoles.indexOf(userRole) < 0) {
                const store = await import("../Store");

                const errorMessage = {
                    html: `HTTP AJAX Method <b>${propertyKey}</b> is not allowed to be called by the user with role <b>${userRole}</b>.`
                }

                store.default.dispatch(showExceptionDialog({
                    title: "Security Exception",
                    message: "The application tried to access a restricted HTTP AJAX method.",
                    ...errorMessage
                }));

                throw errorMessage.html;
            }           

            return originalMethod.apply(this, args);
        }
    }
}
