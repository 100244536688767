import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';
import { FilterHelper } from '../helpers/FilterHelper';
import * as _ from 'lodash';
import { NavigationHelper } from '../helpers/NavigationHelper';
import { UserType } from '../network/NetworkInterfaces';

const loginNetworkAPI = NetworkManager.getLoginNetworkAPI();
const usersNetworkAPI = NetworkManager.getUsersNetworkAPI();

// Function to transform data format inconsistencies between FETCH and SAVE AJAX requests for Users agenda
function transformUserDataBeforeSave(data: any) {
    // First create the deep copy of data
    let userData = _.cloneDeep(data);

    // Check if existing user account is updated?
    if(userData.ID !== undefined) {
        // Trim password and set flag is the user account password is set
        userData.Password = userData.Password.trim();
        userData.changedPassword = userData.Password.length > 0 ? "yes" : "no";
        // If password is empty, then delete the field
        if(userData.Password.length === 0) {
            delete userData.Password;
        }
    }

    // If no related distributor, integrator or end customer is not filled in, then delete the field
    if(userData.RelatedTo === null) {
        delete userData.RelatedTo;
    }

    // If no user picture is empty, then delete the field
    if(userData.UserPicture === null) {
        delete userData.UserPicture;
    }

    // Transform data inconsitency in the user account primary key
    userData.id = userData.ID;
    delete userData.ID;

    return userData;
}

export const fetchUsers = createAsyncThunk(
    'users/fetchUsers',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingUsersStatus(true));
        
        // Fetch Global Users List by the logged user type
        const userType = await loginNetworkAPI.getLoggedUserType();
        const usersData = await usersNetworkAPI.fetchUsersByUserType(userType);

        dispatch(updateFetchedUsers(usersData));
        dispatch(updateLoadingUsersStatus(false));
    }
);

export const filterUsersByPhrase = createAsyncThunk(
    'users/filterUsersByPhrase',
    async (phrase: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { users } = thunkAPI.getState() as any;

        const filteredUsers = FilterHelper.filterRecordsUsingPhrase(phrase, users.fetchedUsers,
            ["ID", "Username", "Email", "Type"]);
        dispatch(updateShownUsers(filteredUsers));
    }
);

export const fetchRelatedUsers = createAsyncThunk(
    'users/fetchRelatedUsers',
    async (userType: UserType, thunkAPI) => {
        const {dispatch} = thunkAPI;
        if(userType === "Admin") {
            dispatch(updateRelatedUsers([]));
        } else {
            const relatedUsers = await usersNetworkAPI.getAllUsersRelatedToUser(userType);
            dispatch(updateRelatedUsers(relatedUsers));
        }
    }
);

export const createNewUser = createAsyncThunk(
    'users/createNewUser',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateEditedUser({Username: "", Password: "", Email: "", RelatedTo: null,  Type: null, UserPicture: null}));
        NavigationHelper.toAppUrl("/users/edit/")
    }
);

export const editUser = createAsyncThunk(
    'users/editUser',
    async (id: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { users } = thunkAPI.getState() as any;
        const fetchedUsers: any[] = users.fetchedUsers;

        const editedUser = fetchedUsers.find(endC => endC.ID === id);
        if(editedUser === undefined)
            throw new Error("editedUser to edit was not found in fetched users.");

        dispatch(updateEditedUser(editedUser));
        NavigationHelper.toAppUrl(`/users/edit/`);       
    }
);

export const saveUser = createAsyncThunk(
    'users/saveUser',
    async (data: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingUsersStatus(true));       
        try {
            const userData: any = transformUserDataBeforeSave(data);
            if(userData.UserPicture instanceof File) {
                userData.UserPicture = await usersNetworkAPI.saveUserLogo(userData.UserPicture);
            } else {
                if(userData.UserPicture === undefined) {
                    if(userData.id !== undefined) {
                        await usersNetworkAPI.deleteUserLogo(userData.id);
                    }
                    delete userData.UserPicture;
                } else {
                    delete userData.UserPicture;
                }
            }

            await usersNetworkAPI.saveUser(userData);
            NavigationHelper.goBack();
        }
        finally {
            dispatch(updateLoadingUsersStatus(false));
        }
    }
);

export const deleteUser = createAsyncThunk(
    'users/deleteUser',
    async (id: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        try {
            dispatch(updateLoadingUsersStatus(true));       
            await usersNetworkAPI.deleteUser(id);
        }
        finally {
            dispatch(updateLoadingUsersStatus(false));
        }
        dispatch(fetchUsers())
    }
);

// Initial Users Store State
const INITIAL_STATE = {
    shownUsers: [],
    fetchedUsers: [],
    loadingUsers: false,
    editedUser: {},
    relatedUsers: []
}

// Redux Slice for Users Module
export const usersSlice = createSlice({
    name: 'users',
    initialState: INITIAL_STATE,
    reducers: {
        updateLoadingUsersStatus: (state, action) => {
            state.loadingUsers = action.payload;
        },
        updateFetchedUsers: (state, action) => {
            state.fetchedUsers = action.payload;
            state.shownUsers = _.clone(action.payload);
        },
        updateShownUsers: (state, action) => {
            state.shownUsers = action.payload;
        },
        updateEditedUser: (state, action) => {
            let editedUser = _.cloneDeep(action.payload);
            editedUser.Password = "";
            state.editedUser = editedUser;
        },
        updateRelatedUsers: (state, action) => {
            state.relatedUsers = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateLoadingUsersStatus,
    updateFetchedUsers,
    updateShownUsers,
    updateEditedUser,
    updateRelatedUsers
} = usersSlice.actions

export default usersSlice.reducer
