import { Formik, Form, useField } from "formik";
import React, { useRef } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { object, string, mixed } from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { FormikAvatarUploadField, FormikDropdownField, FormikTextAreaField, FormikTextField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { fetchUsersRelatedToEndCustomer, saveEndCustomer } from "../EndCustomerReduxSlice";

import "./EditEndCustomerForm.scss";

const ASSOCIATED_TO_USERTYPE = [
    {label: "Integrator", value: "Integrator"},
    {label: "Distributor", value: "Distributor"}
];

const FILE_SIZE = 8 * 1024 * 1024;
const SUPPORTED_FORMATS: string[] = ["image/jpeg", "image/png", "image/gif"];

// Let's create SoundDetailForm validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    Name: string().required("Please, fill in the end customer name."),
    associatedNo: string().nullable().required("Please, fill in the associated user."),
    WelcomeText: string().required("Please, fill in the welcome text."),
    EndcustomerLogo: mixed()
        .nullable(true)
        .test("size", "Selected file is too big. Maximum size is 8MB.",  
            (value) => typeof value === "string" || (!value || (value && value.size <= FILE_SIZE)))
        .test("type", "File has unsupported format Supported file formats are only images.",
            value => typeof value === "string" || (!value || (value && SUPPORTED_FORMATS.includes(value.type))))
});

const EditEndCustomerForm = () => {
    // Submit Form Handler
    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        dispatch(saveEndCustomer(values));
    }

    const handleReject = () => {
        NavigationHelper.toAppUrl("/end-customers");
    }

    // Fetch Edited Customer Data
    const editedCustomer = useSelector((state: any) => state.endCustomers.editedEndCustomer);
    const relatedUsers = useSelector((state: any) => state.endCustomers.relatedUsers);
    const isLoading = useSelector((state: any) => state.endCustomers.loadingEndCustomers);

    const dialogTitle = editedCustomer.EndCustomerNo === undefined ?
                "Create New End Customer" : "Edit Existing End Customer";

    // Fetch the list of related users appropriate for the selected user associated type
    useEffect(() => {
        dispatch(fetchUsersRelatedToEndCustomer(editedCustomer.associatedTo));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        const acceptLabel = editedCustomer.EndCustomerNo === undefined ? "Create" : "Update";
        return (
            <ContentFormStandardButtons
                acceptLabel={acceptLabel} onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }


    const FormBody = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [associatedNo, meta, associatedNoHelper] = useField<string | null>("associatedNo");

        const handleUserTypeChanged = (userType: any) => {     
            associatedNoHelper.setValue(null);
            dispatch(fetchUsersRelatedToEndCustomer(userType));
        }
    
        const [logoUrl] = useField<string>("EndcustomerLogo");
        const [associatedTo] = useField<string>("associatedTo");
        const associatedNamePlaceholder = associatedTo.value === "Integrator" 
                        ? "--- Select Integrator ---" : "--- Select Distributor ---";

        return (
                <div className="EditEndCustomer--Body">
                    <div className="EditEndCustomer--Body--Left-Part" >
                        <FormikTextField label="Name:" name="Name" 
                            placeholder="Enter end customer name..." ></FormikTextField>
                        <FormikDropdownField label="Associated To:" name="associatedTo"  
                            options={ASSOCIATED_TO_USERTYPE}
                            onChange={(e: any) => handleUserTypeChanged(e.value)}  />
                        <FormikDropdownField label="Associated Name:" name="associatedNo"  
                            options={relatedUsers}
                             optionLabel="Name" optionValue="id"
                            placeholder={associatedNamePlaceholder}   />
                        <FormikTextAreaField label="Welcome Text" name="WelcomeText" rows={4}
                            placeholder="Enter welcome text..." />
                    </div>
                    <div>
                        <FormikAvatarUploadField label="End Customer Logo" name="EndcustomerLogo" 
                                    downloadUrlPrefix="/uploads/endcustomer_logo/" value={logoUrl.value}  />
                    </div>
                </div>
        );
    }

    return (
        <GenericContentForm title={dialogTitle} footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={editedCustomer} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditEndCustomerForm;