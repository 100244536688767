import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NetworkManager } from "../network/NetworkManager";
import { fetchManuals } from "../support/SupportReduxSlice";

import "./HomeViewScreen.scss";

const loginAPI = NetworkManager.getLoginNetworkAPI();
const manualAPI = NetworkManager.getManualsAPI();

const WelcomeInfo = () => {
    return (
        <div className="WelcomeInfo">
            <div className="WelcomeInfo--Header">
                Welcome { loginAPI.getLoginUserName()} 
            </div>
            <div className="WelcomeInfo--IntroText">
                <p>
                    The CUPU Platform is a complete solution for the technology required inside a Smart City product. It consists of the following parts:
                </p>
                    <ul>
                        <li>
                            <b>Control Electronics:</b> This is the control electronics. We have several standard types that can be easily customized to your product.
                        </li>
                        <li>
                            <b>Sensors:</b> We have developed several sensors that are relevant for smart city products.                            
                        </li>
                        <li>
                            <b>CUPU Connector:</b> A PC based software to setup, test and configure all CUPU products.                        
                        </li>
                        <li>
                            <b>CUPU Cloud:</b> A cloud service to store and extract data from CUPU Devices. An ideal tool to benefit from the rapidly generated Big Data.                            
                        </li>
                    </ul>
                <p>
                    CUPU is an offgrid control platform, because it can be used in smart city products that are not connected to the power grid, and therefore needs to harvest its own energy.
                </p>
            </div>
        </div>
    );
}

const ManualsPanel = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchManuals());
    }, []);

    const manuals = useSelector((state: any) => state.support.fetchedManuals);

    return (
        <div className="ManualsPanel">
            <div className="ManualsPanel--Header">
                Manuals
            </div>
            <div className="ManualsPanel--Body">
                <div className="ManualsPanel--BodyContent">
                    <ul>
                        {
                            manuals.length > 0 ?
                            manuals.map((manual: any) => {

                            const handleManualOpen = async (event: React.MouseEvent) => {
                                event.preventDefault();
                                let manualContent = await manualAPI.downloadManual(manual.name, false);
                                const objectUrl = window.URL.createObjectURL(manualContent);
                                window.open(objectUrl, "_blank");
                            }  

                            const handleManualDownload = async (event: React.MouseEvent) => {
                                event.preventDefault();
                                let manualContent = await manualAPI.downloadManual(manual.name, true);
                                const objectUrl = window.URL.createObjectURL(manualContent);
                                const domLink = document.createElement("a");
                                domLink.href = objectUrl;
                                domLink.download = manual.name;
                                domLink.click();
                            }  

                            return (
                                <li key={manual.name}>
                                    <div className="ManualsPanel--Document">
                                        <a href="#/" title="Open document in the new tab." onClick={handleManualOpen} >{manual.name}</a>
                                        <span className="ManualsPanel--Document--Line"></span>
                                        <a href="#/" title="Download document to your computer." className="ManualsPanel--DownloadLink" onClick={handleManualDownload}>Download</a>
                                    </div>                                    
                                </li>
                            );
                        }) : (<span style={{fontSize: "2rem"}}>No manuals were uploaded yet.</span>)}

                    </ul>
                </div>
            </div>
        </div>
    );
}

const HomeViewScreen = () => {

    return (
        <div className="HomeViewScreen">
            <WelcomeInfo />
            <ManualsPanel />
        </div>
    );
}

export default HomeViewScreen;
