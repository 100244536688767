import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ActionsDataList, { DataListActions } from "../../forms/ActionsDataList";
import { setDefaultFirmware, showAssignFirmwareForm } from "../FirmwareReduxSlice";
import { Dropdown  } from "primereact/dropdown";

import "./AssignDefaultFirmwareForm.scss";
import { useState } from "react";


const AssignFirmwareFormActions: DataListActions = [
    {
        name: "assign",
        label: "Assign Default Firmware",
        className: "p-button-info"
    }
]

const AssignDefaultFirmwareForm = () => {

    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(showAssignFirmwareForm(false));
    }

    const firmwares = useSelector((state: any) => state.firmwares.fetchedFirmwares).map((firmware: any) => {
        return { label: firmware.FirmwareNo + " - " + firmware.FileName, value: firmware.FirmwareNo, HardwareCode:  firmware.HardwareCode}
    });

    const factoryDefaultNo = useSelector((state: any) => state.firmwares.defaultFirmware);

    const [defaultFirmwareState, setDefaultFirmwareState] = useState(factoryDefaultNo);

    const handleActionClick = (data: {id: string, name: string}) => {
        if(data.name === "assign") {
            const HardwareCode = data.id;
            const defaultFirmware = defaultFirmwareState.find((f: any) => f.HardwareCode === HardwareCode);
            if(defaultFirmware === undefined) return;
            const FirmwareNo = defaultFirmware.FirmwareNo;
            dispatch(setDefaultFirmware({FirmwareNo: FirmwareNo, HardwareCode: HardwareCode}));
        }
    }

    const handleAssignFirmware = (payload: {FirmwareNo: string, HardwareCode: string}) => {
        let defaultFirmwareData = defaultFirmwareState.filter((f: any) => f.HardwareCode !== payload.HardwareCode);
        defaultFirmwareData.push({
            HardwareCode: payload.HardwareCode,
            FirmwareNo: payload.FirmwareNo
        });
        setDefaultFirmwareState(defaultFirmwareData);
    }

    const cupuTypes = useSelector((state: any) => state.firmwares.cupuTypes);

    const DialogFooter = (
        <div className="AssignFirmwareForm--Footer">
            <Button label="Close" className="p-button-success" onClick={handleClose} />
        </div>
    )

    const FirmwareDropdownTemplate = (rowData: any) => {
        const myFirmwares = firmwares.filter((f: any) => f.HardwareCode === rowData.id);
        let defaultFirmware = defaultFirmwareState.find((f: any) => f.HardwareCode === rowData.id);
        defaultFirmware = defaultFirmware === undefined ?  null : defaultFirmware.FirmwareNo;
        return <Dropdown options={myFirmwares} value={defaultFirmware} style={{width: "100%"}}
                filter  onChange={e => handleAssignFirmware({FirmwareNo: e.value, HardwareCode: rowData.id})} />
    }


    return (
        <Dialog header="Assign Factory Default Firmwares" visible={true} style={{width: "1600px", height: "800px"}} footer={DialogFooter}
                   onHide={handleClose}  >
            <div style={{textAlign: "center", paddingTop: "1rem", height: "100%", display: "grid", gridTemplateRows: "100%"}}>
                <ActionsDataList value={cupuTypes} actions={AssignFirmwareFormActions} actionColumnWidth="18rem"
                  onAction={handleActionClick} stripedRows >
                    <Column header="Id"  field="id" style={{width: "5rem"}} />
                    <Column header="Name"  field="name" style={{width: "25rem"}} />
                    <Column header="FirmwareNo" field="FirmwareNo" body={FirmwareDropdownTemplate}
                                style={{"width": "auto"}} />
                </ActionsDataList>
            </div>
        </Dialog>
    )
}

export default AssignDefaultFirmwareForm;
