import React from "react";
import AppSidebarMenu from "./AppSidebarMenu";
import {UnitsSearchBox} from "./AppSearchBox";

import "./AppSidebar.scss";
import { NavigationHelper } from "../helpers/NavigationHelper";

const AppSidebar = () => {

    const handleOnSearch = (value: string) => {
        NavigationHelper.toAppUrl(`/units?search=${value}`)
    }

    return (
        <div>
            <div className="AppSidebar--SearchBoxWrapper">
                {/* <UnitsSearchBox onSearch={handleOnSearch} ></UnitsSearchBox> */}
            </div>                
            <AppSidebarMenu></AppSidebarMenu>
        </div>
        
    );
}

export default AppSidebar;

