import React, { useEffect, useState } from "react";
import * as _ from "lodash";
import { Button } from "primereact/button";
import { SearchBox } from "../common/AppSearchBox";
import ActionsDataList from "../forms/ActionsDataList";
import { Column } from "primereact/column";

import "./SoundDataList.scss";
import { useDispatch, useSelector } from "react-redux";
import { editSound, fetchSounds, filterSounds, hideSound, playSound, showUploadSoundForm } from "./SoundReduxSlice";
import { DataListActions } from "../forms/ActionsDataList";
import PlaySoundForm from "./forms/PlaySoundForm";

import LoadingProgress from "../common/LoadingProgress";
import FactoryDefaultsFilter from "./FactoryDefaultsFilter";
import { confirmDialog } from "primereact/confirmdialog";

 // Sound Data List Header Functionality
///////////////////////////////////////////////////////////////////////
const FILTER_FUNCTION_DEBOUNCE = 200;

const SoundDataListHeader = () => {
    // Dispatching Sound Filtering
    const dispatch = useDispatch();
    const invokeSoundFiltering = _.debounce((phrase: string) => {
        dispatch(filterSounds(phrase));
    }, FILTER_FUNCTION_DEBOUNCE);

    const handleShowUploadSoundForm = () => {
        dispatch(showUploadSoundForm());
    }

    // Render Markup for SoundDataList Header
    return (
        <div className="SoundDataListHeader">
            <SearchBox placeholder="Search sounds..." 
                onChange={(value: string) => invokeSoundFiltering(value)} ></SearchBox>
            <FactoryDefaultsFilter />
            <div></div>
            <Button label="Upload Sound" className="p-button-success"
                  onClick={handleShowUploadSoundForm}  ></Button>
        </div>
    )
}

const SoundActions: DataListActions = [
    {name: "play", label: "Play", className: "p-button-warning"},
    {name: "edit", label: "Edit", className: "p-button-success", 
        isVisible: (dataRow) => dataRow.IsUserOwner == 1},
    {name: "hide", label: "Hide", className: "p-button-secondary",
        isVisible: (dataRow) => dataRow.canHideSound == 1
    }
];

const SoundDataListTable = () => {
    // Invoke the first fetch of sounds
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchSounds());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const sounds = useSelector((state: any) => state.sounds.shownSounds);

    // Delete Confirmation Function
    const confirmHidingSound = (id: string) => {
        confirmDialog({
            message: 'Are you sure you want to hide this sound?',
            header: 'Hide Sound',
            icon: 'pi pi-exclamation-triangle',
            style: {'width': '30rem'},
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Hide',
            rejectLabel: 'Cancel',
            rejectClassName: 'p-button-secondary',
            accept: () => dispatch(hideSound(id))
        });
    }    

    // Handle Action Click
    const handleActionClick = (data: {name: string, id: any}) => {
        switch(data.name) {
            case "edit": dispatch(editSound(data.id)); break;
            case "play": dispatch(playSound(data.id)); break;
            case "hide": confirmHidingSound(data.id); break;
            default: throw new Error(`Action Handler not defined for ${data.name}`);
        }
    }

    return (
        <ActionsDataList
                value={sounds}  actions={SoundActions} actionColumnWidth="16.5rem"
                onAction={handleActionClick} stripedRows showGridlines
                virtualScrollerOptions={{itemSize: 85, delay: 0, showLoader: false}}
            >
            <Column header="FileNo" field="FileNo" sortable style={{maxWidth: "7rem", justifyContent: "center"}} />
            <Column header="Description" field="Description" sortable style={{maxWidth: "auto", justifyContent: "left"}} />
            <Column header="Filename" field="FileName" sortable style={{maxWidth: "25rem", justifyContent: "left"}} />
            <Column header="Owner" field="OwnerName" sortable style={{maxWidth: "12rem", justifyContent: "center", textAlign: "center"}} />
            <Column header="CRC" field="CRC" sortable style={{maxWidth: "8rem", justifyContent: "center"}} />
            <Column header="PlaylistPopularity" field="PlaylistPopularity" sortable style={{maxWidth: "12rem", justifyContent: "center"}} />
        </ActionsDataList>
    );
}

const SoundDataList = () => {
    const loadingSounds = useSelector((state: any) => state.sounds.loadingSounds);
    const playSoundFormVisible = useSelector((state: any) => state.sounds.playSoundFormVisible);

    return (        
        <div className="SoundDataList LoadingProgress--Wrapper">
            <LoadingProgress show={loadingSounds} />
            { playSoundFormVisible && <PlaySoundForm /> }

            <SoundDataListHeader />
            <div className="SoundDataListHeader--Separator" />
            <SoundDataListTable />
        </div>
    );
}

export default SoundDataList;
