import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React from "react"
import { useDispatch, useSelector } from "react-redux";
import { fetchGraphApiLogs, showGraphDialog } from "../LogsReduxSlice";

import {process_log_data_from_server, draw_log_chart} from "./highcharts-data-transform.js";
import { useEffect } from "react";
import { useParams } from "react-router";

import "./ApiLogGraphForm.scss";

const ApiLogGraphForm = () => {

    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(showGraphDialog(false));
    }

    const {serialNumber} = useParams<any>();
    useEffect(() => {
        dispatch(fetchGraphApiLogs(serialNumber));
    }, []);

    const data = useSelector((state: any) => state.logs.graphApiLogs);
    const isLoading = useSelector((state: any) => state.logs.loadingLogs);

    if(data.length > 0) {
        const transformedData = process_log_data_from_server(data);
        draw_log_chart("apiLogGraph", {
            log_records: transformedData 
        });
    }

    const DialogFooter = (
        <div style={{textAlign: "right"}}>
            <Button label="Close" className="p-button-success" onClick={handleClose} />
        </div>
    )
    
    return (
        <Dialog header="Unit Api Function Log" visible={true} style={{width: "1500px", height: "800px"}} footer={DialogFooter}
                   onHide={handleClose}  >
            <div style={{textAlign: "center", paddingTop: "1rem", height: "100%"}}>
                <div id="apiLogGraph" style={{height: "100%"}} >
                    <div className="NoEntriesMessage-Container" style={{display: isLoading ? "none" : "grid"}} >
                        <span className="NoEntriesMessage" >No log entries for last 30 days.</span>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default ApiLogGraphForm;
