import React from "react"
import { CSSTransition } from "react-transition-group";

import "./LoadingProgress.scss";

const LoadingProgress = (props: any) => {
    return (
        <CSSTransition in={props.show} timeout={300} classNames="loading-progress" mountOnEnter unmountOnExit appear >
            <div className="LoadingProgress">
                <i className="fas fa-10x fa-spinner fa-pulse"></i>
            </div>
        </CSSTransition>
    );
}

export default LoadingProgress;
