import { Form, Formik } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mixed, object, string } from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { FormikDropdownField, FormikFileUploadField, FormikTextAreaField, FormikTextField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { uploadFirmware } from "../FirmwareReduxSlice";

import "./UploadFirmwareForm.scss";

const FILE_SIZE = 8 * 1024 * 1024;

const FORM_VALIDATION_SCHEMA = object().shape({
    HardwareCode: string().nullable().required("Please, select the hardware code"),
    FirmwareNo: string().required("Please, fill in the firmware number")
                .test("numeric", "Firmware number must contain only numbers.",
                    (value: any) => /^[0-9]+$/.test(value))
                .min(9, "Firmware number must contain at least 9 digits."),
    Description: string().required("Please, fill in the firmware description"),
    myfile: mixed().nullable(false)
        .required("Please, select the firmware you want to upload")
        .test("name", "Filename must have maximum 8 characters.", value => {
            if(! value || typeof value.name !== "string" ) return false;            
            const extensionIndex = value.name.lastIndexOf(".");
            if(extensionIndex < 0) return false;
            return value.name.slice(0, extensionIndex).length <= 8;
        })
        .test("name", "Filename must have .BIN or .HEX extension only.", value => {
            console.dir(value);
            return !value || (typeof value.name === 'string' && (value.name.endsWith(".hex") || (value.name.endsWith(".bin"))));
        })
        .test("size", "Selected file is too big. Maximum size is 8MB.",  value => !value || (value && value.size <= FILE_SIZE))
});

const UploadFirmwareForm = () => {
    const isLoading = useSelector((state: any) => state.firmwares.firmwareLoadingStatus);
    const initialData = useSelector((state: any) => state.firmwares.editedFirmware);

    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        dispatch(uploadFirmware(values));
    }

    const handleReject = () => {
        NavigationHelper.goBack();
    }

    const FormBody = () => {
        const cupuTypes = useSelector((state: any) => state.firmwares.cupuTypes).map((cupuType: any) => {
            return {
                id: cupuType.id,
                name: cupuType.id + " - " + cupuType.name
            }
        });
    
        return (
            <div className="UploadFirmware--FormBody">
                <div className="UploadFirmware--Body--Left-Part">
                    <FormikDropdownField placeholder="--- Select Hardware Code ---"
                                optionValue="id" optionLabel="name" label="Hardware Code:"
                               options={cupuTypes} name="HardwareCode"  />
                    <FormikTextField label="FirmwareNo:" name="FirmwareNo" 
                                placeholder="Enter firmware number..." ></FormikTextField>
                    <FormikTextAreaField label="Description:" name="Description"
                            placeholder="Enter firmware description..."
                            rows={4} />
                    <FormikTextField label="CRC:" name="CRC" disabled ></FormikTextField>
                </div>
                <div className="UploadFirmware--Body--Right-Part">
                    <FormikFileUploadField label="Firmware File" name="myfile"
                        chooseLabel="Select Firmware File"  customUpload  />
                    <div />
                </div>
            </div>
        )
    }

    const formRef = useRef<any>(null);
    const FormFooter = () => {
        return (
            <ContentFormStandardButtons
                acceptLabel="Upload" onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    return (
        <GenericContentForm title="Upload Firmware" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={initialData} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default UploadFirmwareForm;
