import React, { useState } from "react";
import {Tree, TreeExpandedKeysType} from "primereact/tree";

import "./EntityTreeView.scss";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { useDispatch, useSelector } from "react-redux";
import { setEntityFlag } from "../SoundReduxSlice";
import * as _ from "lodash";
import TreeCheckBox from "./TreeCheckBox";

export interface EntityTreeViewProps {
    rootNode: any;
}

const getIconByType = (type: string) => {
    switch(type) {
        case "Integrator": return "I";
        case "Distributor": return "D";
        case "EndCustomer": return "EC";
        case "Unit": return "U";
        default: return "N/A";
    }
}

const mapTreeData = (data: any) => {
    if(data === null) return undefined;
    const node = {
        "key": data.EntityNo,
        "label": data.Type === "Unit" ? data.EntityNo : data.Name,
        "data": data,
        "icon": getIconByType(data.Type),
        "isChecked": data.IsChecked === undefined ? false : data.IsChecked,
        "children": data.Children === undefined ? undefined : 
                    data.Children.map((child: any) => mapTreeData(child))
    }
    return node;
}

const NodeTemplate = (props: {node: any, options: any}) => {
    const node = props.node;
    const iconClassName = "Entity-Node--Icon Entity-Node--Icon-" + node.data.Type;

    const dispatch = useDispatch();
    const handleSetCheck = (value: boolean | null | undefined) => {
        if(value === undefined) value = null;
        dispatch(setEntityFlag({entityNo: node.key, flag: value}));
    }

    return (
        <span className="Entity-Node">
            <span className="Entity-Node--Checkbox">
                <TreeCheckBox  value={node.isChecked} onChange={e => handleSetCheck(e)} />
            </span>
            <span className={iconClassName}>
                { node.icon }
            </span>
            <span>
                { node.label }
            </span>
        </span>
    )   
}

const nodeTemplate = (node: any, options: any) => {
    return (<NodeTemplate node={node} options={options} />)
}

const togglerTemplate = (node: any, options: any) => {
    return (
        <span className="EntityTreeView--Toggler">{options.expanded === true ? '+': '-'}</span>
    )
}

const EntityTreeView = () => {

    const rootEntityNode = useSelector((state: any) => state.sounds.rootEntity);
    const data = mapTreeData(rootEntityNode);

    const expandRootNodeState: any = {};
    if(data !== undefined)
        expandRootNodeState[data.key] = true;
    const [expandedKeys, setExpandedKeys] = useState<TreeExpandedKeysType>(expandRootNodeState);

    const toggleExpansion = (key: string) => {
        let newExpandedKeys = _.cloneDeep(expandedKeys);
        if(expandedKeys[key] === undefined) {
            newExpandedKeys[key] = true;
        } else {
            newExpandedKeys[key] = ! newExpandedKeys[key];
        }
        setExpandedKeys(newExpandedKeys);
    }

    return (
        <div className="EntityTreeView">
            <div className="EntityTreeView--Content">
                <Tree value={data === undefined ? [] : [data]} nodeTemplate={nodeTemplate}   expandedKeys={expandedKeys} filter filterPlaceholder="Filter tree nodes..."
                    onExpand={e => toggleExpansion(e.node.key as string)} onCollapse={e => toggleExpansion(e.node.key as string)}  />
            </div>
        </div>
    )
}

export default EntityTreeView;
