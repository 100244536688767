import React, { createContext, useContext } from "react";
import { NetworkManager } from "../network/NetworkManager";

// Network API for login and logout
const loginAPI = NetworkManager.getLoginNetworkAPI();

// CUPU Authorization Object
const cupuAuthorization = {
  isAuthenticated: false,
  username: "",
  userRole: "",
  logoUrl: undefined,
  companyName: "",
  async login(username: string, password: string) {
        const loginResult = await loginAPI.loginUser(username, password);
        this.isAuthenticated = loginResult;
        this.username = loginResult ? username : "";
        if(this.isAuthenticated === true) {
            this.userRole = await loginAPI.getLoggedUserType();
            this.logoUrl = await loginAPI.getUserLogo() as any;            
            this.companyName = await loginAPI.getCompanyName();
        } else {
            this.userRole = ""
            this.logoUrl = undefined;
            this.companyName = ""
        }
        return loginResult;    
  },
  async logout() {
    await loginAPI.logoutUser();
    this.isAuthenticated = false;
    this.username = "";
    this.userRole = "";    
    this.logoUrl = undefined;
    this.companyName = "";
  },
  async resetPassword(username: string) {
    return await loginAPI.resetUserPassword(username);
  },
  isLoggedIn() {
      return this.isAuthenticated;
  },
  getLoggedUsername() {
    return this.username;
  },
  getUserRole() {
    return this.userRole;
  },
  getUserLogoUrl() {
    return this.logoUrl;
  },
  getCompanyName() {
    return this.companyName;
  }
};

// Authorization Hook
function useProvideAuth() {
    const login = async (username: string, password: string) => {      
      return await cupuAuthorization.login(username, password);
    };

    const isLoggedIn = () => {
      return cupuAuthorization.isLoggedIn();
    }

    const getLoggedUsername = () => {
      return cupuAuthorization.getLoggedUsername();
    }

    const getUserRole = () => {
      return cupuAuthorization.getUserRole();
    }

    const getUserLogoUrl = () => {
      return cupuAuthorization.getUserLogoUrl();
    }

    const getCompanyName = () => {
      return cupuAuthorization.getCompanyName();
    }
  
    const logout = async () => {
      await cupuAuthorization.logout();
    };

    const resetPassword = async (username: string) => {
      return await cupuAuthorization.resetPassword(username);
    }
  
    return {
        login,
        logout,
        isLoggedIn,
        getLoggedUsername,
        getUserRole,
        getUserLogoUrl,
        getCompanyName,
        resetPassword
    };
}

// Authorization Context
const authContext = createContext({});

export const ProvideAuth = (props: any) => {
    const auth = useProvideAuth();
    return (
      <authContext.Provider value={auth}>
            {props.children}
      </authContext.Provider>
    );
}

export const useAuth = () => {
    return useContext(authContext);
}
