import React from "react"
import "./AppFooter.scss";
import appVersion from "../app-version.json";

export const AppFooter =(props: any) => {

    const handleDefaultClick = (e: any) => {
        e.preventDefault();
    }

    return (
        <div className="AppFooter">
            <div></div>
            <span>Version {appVersion.version}</span>
        </div>
    );
}
