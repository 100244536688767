import { NetworkHelper } from "../../helpers/NetworkHelper";
import type { UsersNetworkAPI, UserType } from "../NetworkInterfaces";
import { RestrictAccess } from "../NetworkSecurity";
import { CupuNetworkAPIBase } from "./CupuNetworkAPIBase";

export class CupuUsersNetworkAPI extends CupuNetworkAPIBase implements UsersNetworkAPI {

     // CRUD - Users
    //////////////////////////////////////////////////////////////////////////////////////////   

    async fetchUsersByUserType(loggedUserType: UserType): Promise<any[]> {
        switch(loggedUserType) {
            case "Integrator": return await this.fetchUsersForIntegrator();
            case "Distributor": return await this.fetchUsersForDistributor();
            case "End Customer": return await this.fetchUsersForEndCustomer();
            case "Admin": return await this.fetchUsersForAdmin();
            default: throw new Error(`Unknown user type: ${loggedUserType}`);
        }
    }

    @RestrictAccess(["Admin"])
    async fetchUsersForAdmin(): Promise<any[]> { 
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "users/get_users", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseUsers(response);
    }

    @RestrictAccess(["Integrator"])
    async fetchUsersForIntegrator(): Promise<any[]> { 
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "users/get_users_integrator", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseUsers(response);
    }

    @RestrictAccess(["Distributor"])
    async fetchUsersForDistributor(): Promise<any[]> { 
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "users/get_users_Distributor", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseUsers(response);
    }

    @RestrictAccess(["End Customer"])
    async fetchUsersForEndCustomer(): Promise<any[]> { 
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "users/get_users_Endcustomer", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseUsers(response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async saveUserLogo(image: File) {
        const requestData = super.createUploadFormData({}, image);
        const ajaxRequest = super.createUploadAjaxRequest("POST", "users/save_user_logo", requestData, "json");
        const response: any = await super.callAjax(ajaxRequest);
        return response.UserPictureImageName;
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async deleteUserLogo(userID: string): Promise<any> {
        const requestData = {"ID": userID};
        const ajaxRequest = super.createAjaxRequest("POST", "users/delete_user_logo", requestData, "json"); 
        await super.callAjax(ajaxRequest); 
    }


    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async getAllUsersRelatedToUser(userType: UserType) {
        const requestData = {userType: userType};
        const ajaxRequest = super.createAjaxRequest("POST", "users/get_all_related_to", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseRelatedUsers(userType, response);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async saveUser(data: any) {
        const requestData = super.subsetRequestProperties(data, 
            ["id", "Username", "Password", "Email", "Type", "RelatedTo", "UserPicture", "changedPassword"]);
        if(requestData.id === undefined) {
            return await this.insertUser(requestData);
        } else {
            return await this.updateUser(requestData);
        }
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    private async insertUser(requestData: any) {
        const ajaxRequest = super.createAjaxRequest("POST", "users/save_user", requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    private async updateUser(requestData: any) {
        const ajaxRequest = super.createAjaxRequest("POST", "users/update_user", requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async deleteUser(id: string) {
        const requestData = {id: id};
        const ajaxRequest = super.createAjaxRequest("POST", "users/delete_user", requestData, "json");
        await super.callAjax(ajaxRequest);
    }


     // CRUD - Integrators
    //////////////////////////////////////////////////////////////////////////////////////////   
    
    @RestrictAccess(["Admin"])
    async fetchIntegrators(): Promise<any[]> { 
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "Integrator/get_Integrators", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseIntegrators(response);
    }

    @RestrictAccess(["Admin"])
    async saveIntegratorLogo(image: File) {
        const requestData = super.createUploadFormData({}, image);
        const ajaxRequest = super.createUploadAjaxRequest("POST", "Integrator/save_Integrators_logo", requestData, "json");
        const response: any = await super.callAjax(ajaxRequest);
        return response.IntegratorPictureImageName;
    }

    @RestrictAccess(["Admin"])
    async deleteIntegratorLogo(integratorNo: string) {
        const requestData = {"IntegratorNo": integratorNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Integrator/delete_integrator_logo", requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin"])
    async saveIntegrator(data: any) {
        const requestData = super.subsetRequestProperties(data, 
            ["IntegratorNo", "Name", "LoginText", "WelcomeText", "IntegratorLogo"]);
        const url = requestData.IntegratorNo === undefined || requestData.IntegratorNo === null ? "Integrator/save_Integrator" :  "Integrator/update_Integrator";
        const ajaxRequest = super.createAjaxRequest('POST', url, requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin"])
    async deleteIntegrator(integratorNo: string) {
        const requestData = {IntegratorNo: integratorNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Integrator/delete_Integrator", requestData, "json");
        await super.callAjax(ajaxRequest);
    }

     // CRUD - Distributors
    //////////////////////////////////////////////////////////////////////////////////////////

    @RestrictAccess(["Admin", "Integrator"])
    async fetchDistributors(): Promise<any[]> { 
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "Distributor/get_Distributors", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseDistributors(response);
    }

    @RestrictAccess(["Admin", "Integrator"])
    async fetchIntegratorsAssociatedToDistributor(): Promise<any[]> {
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("POST", "Integrator/get_Integrators", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseIntegratorsRelatedToDistributor(response);
    } 

    @RestrictAccess(["Admin", "Integrator"])
    async saveDistributorLogo(image: File) {
        const requestData = super.createUploadFormData({}, image);
        const ajaxRequest = super.createUploadAjaxRequest("POST", "Distributor/save_Distributor_logo", requestData, "json");
        const response: any = await super.callAjax(ajaxRequest);
        return response.DistributorPictureImageName;
    }

    @RestrictAccess(["Admin", "Integrator"])
    async deleteDistributorLogo(distributorNo: string) {
        const requestData = {"DistributorNo": distributorNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Distributor/delete_distributor_logo", requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator"])
    async saveDistributor(data: any) {
        const requestData = super.subsetRequestProperties(data, 
            ["DistributorNo", "Name", "IntegratorNo", "WelcomeText", "DistributorLogo"]);
        const url = requestData.DistributorNo === undefined ? "Distributor/save_Distributor" :  "Distributor/update_Distributor";
        const ajaxRequest = super.createAjaxRequest('POST', url, requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator"])
    async deleteDistributor(distributorNo: string) {
        const requestData = {DistributorNo: distributorNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Distributor/delete_Distributor", requestData, "json");
        await super.callAjax(ajaxRequest);
    }

     // CRUD - End Customers
    //////////////////////////////////////////////////////////////////////////////////////////

    @RestrictAccess(["Admin", "Integrator", "Distributor"])
    async fetchEndCustomers(): Promise<any[]> { 
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", "Endcustomer/get_Endcustomers", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.Users.parseEndCustomers(response);
    }   

    @RestrictAccess(["Admin", "Integrator", "Distributor"])
    async getAllUsersRelatedTo(userType: UserType) {
        const requestData = {"userType": userType};
        const ajaxRequest = super.createAjaxRequest("POST", "Endcustomer/get_all_related_to", requestData, "json");
        const response = await super.callAjax(ajaxRequest);
        if(userType === "Integrator") {
            return NetworkHelper.Users.parseUsersRelatedToIntegrators(response);
        } else if(userType === "Distributor") {
            return NetworkHelper.Users.parseUsersRelatedToDistributors(response);
        } else throw new Error("Undefined user type.");
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor"])
    async saveEndCustomer(data: any) {
        const requestData = super.subsetRequestProperties(data, 
            ["EndCustomerNo", "Name", "associatedTo", "associatedNo", "WelcomeText", "EndcustomerLogo"]);
        const url = requestData.EndCustomerNo === undefined ? "Endcustomer/save_Endcustomer" :  "Endcustomer/update_Endcustomer";
        const ajaxRequest = super.createAjaxRequest('POST', url, requestData, "json");
        await super.callAjax(ajaxRequest);
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor"])
    async saveEndCustomerLogo(image: File) {
        const requestData = super.createUploadFormData({}, image);
        const ajaxRequest = super.createUploadAjaxRequest("POST", "Endcustomer/save_Endcustomer_logo", requestData, "json");
        const response: any = await super.callAjax(ajaxRequest);
        return response.EndcustomerPictureImageName;
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor"])
    async deleteEndCustomerLogo(endCustomerNo: string) {
        const requestData = {"EndCustomerNo": endCustomerNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Endcustomer/delete_endcustomer_logo", requestData, "json");
        await super.callAjax(ajaxRequest);
    }


    @RestrictAccess(["Admin", "Integrator", "Distributor"])
    async deleteEndCustomer(endCustomerNo: string) {
        const requestData = {EndCustomerNo: endCustomerNo};
        const ajaxRequest = super.createAjaxRequest("POST", "Endcustomer/delete_Endcustomer", requestData, "json");
        await super.callAjax(ajaxRequest);
    }
}
