import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

import "./FactoryDefaultsFilter.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setFactoryDefaults } from "./SoundReduxSlice";
import { useEffect } from "react";
import { useAuth } from "../common/Auth";
import { UserType } from "../network/NetworkInterfaces";

// HOTFIX: Enable Factory Default Sound to be populated by all sounds or the factory default sound
const useGetFactoryDefaultSounds = (isUserAllowedToSetFactoryDefaultSound: boolean) => {
    const [state, setState] = useState([]);
    const allSounds = useSelector((state: any) => state.sounds.fetchedSounds);
    const onlyFactoryDefaultSound = [{
        label: useSelector((state: any) => state.sounds.factoryDefaultSoundName),
        value: useSelector((state: any) => state.sounds.factoryDefaultSoundFileNo)
    }];

    useEffect(() => {
        if(isUserAllowedToSetFactoryDefaultSound) {
            const allSounds2 = allSounds.map((sound: any) => {
                return {
                    label: sound.Description,
                    value: sound.FileNo
                }
            });
       
            setState(allSounds2);
        } else {
            setState(onlyFactoryDefaultSound);
        }
    }, [isUserAllowedToSetFactoryDefaultSound, allSounds]);

    return state;
}

// HOTFIX: Enable Factory Default Sound to be populated by all sounds or the factory default sound
const useGetFactoryDefaultSoundFileNo = (isUserAllowedToSetFactoryDefaultSound: boolean) => {
    const [state, setState] = useState(null);
    const factoryDefaultId = useSelector((state: any) => state.sounds.factoryDefaultsSoundId);
    const factoryDefaultFileNo = useSelector((state: any) => state.sounds.factoryDefaultSoundFileNo);

    useEffect(() => {
        if(isUserAllowedToSetFactoryDefaultSound) {
            setState(factoryDefaultId);
        } else {
            setState(factoryDefaultFileNo);
        }
    }, [isUserAllowedToSetFactoryDefaultSound, factoryDefaultId, factoryDefaultFileNo]);


    return state;
}

const FactoryDefaultsFilter = () => {

    // Get the current user role
    const auth: any = useAuth();
    const userRole: UserType = auth.getUserRole();
    const isUserAllowedToSetFactoryDefaultSound = userRole === "Admin";

    // Select available sounds out of store
    const sounds = useGetFactoryDefaultSounds(isUserAllowedToSetFactoryDefaultSound);
    const factoryDefaultId = useGetFactoryDefaultSoundFileNo(isUserAllowedToSetFactoryDefaultSound);

    // NOTE: Candidate for Removal - Out-dated Code
    // let sounds = useSelector((state: any) => state.sounds.fetchedSounds).map((sound: any) => {
    //     return {
    //         label: sound.Description,
    //         value: sound.FileNo
    //     }
    // });
    // if(isUserAllowedToSetFactoryDefaultSound === false) {
    //     sounds = [{}];
    //     sounds[0].label = useSelector((state: any) => state.sounds.factoryDefaultSoundName);
    //     sounds[0].value = useSelector((state: any) => state.sounds.factoryDefaultSoundFileNo);
    // }

    // // Factory Defaults Sound
    // const factoryDefaultId = useSelector((state: any) => state.sounds.factoryDefaultsSoundId);

    // Generate state
    const [selectedSound, setSelectedSound] = useState(factoryDefaultId);

    // Update selected sound when a correct value is delivered later on by async network request
    useEffect(() => {
        if(selectedSound !== factoryDefaultId) {
            setSelectedSound(factoryDefaultId);
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [factoryDefaultId]);

    // Apply Factory Default Changes
    const dispatch = useDispatch();
    const applyChanges = () => {
        if(selectedSound === null)
            return;
        dispatch(setFactoryDefaults(selectedSound));
    }

    return (
        <div className="FactoryDefaultsFilter">
            <label>Factory Defaults:</label>
            <Dropdown 
                options={sounds} 
                value={selectedSound} 
                onChange={e => setSelectedSound(e.value)}
                style={{"width": "20rem"}} 
                filter   
                disabled={isUserAllowedToSetFactoryDefaultSound !== true}
            ></Dropdown> 
            <Button 
                label="Apply Changes" 
                className="p-button-secondary"
                onClick={e => applyChanges()} 
                disabled={isUserAllowedToSetFactoryDefaultSound !== true}
            ></Button>
        </div>
    );
}

export default FactoryDefaultsFilter;
