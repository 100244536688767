import React from "react";
import {Button} from "primereact/button";

import "./ApiLogDataList.scss";
import { NavigationHelper } from "../helpers/NavigationHelper";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useEffect } from "react";
import { fetchApiLogCount, fetchApiLogs, showGraphDialog } from "./LogsReduxSlice";
import LoadingProgress from "../common/LoadingProgress";
import VirtualActionDataList from "../forms/VirtualActionDataList";
import ApiLogGraphForm from "./forms/ApiLogGraphForm";

const ApiLogHeader = () => {


    const handleGoBack = () => {
        NavigationHelper.goBack();
    }

    const dispatch = useDispatch();
    const handleShowGraph = () => {
        dispatch(showGraphDialog(true));
    }

    return (
        <div className="ApiLogHeader">
            <Button icon="fas fa-angle-double-left" onClick={e => handleGoBack()}
                    className="p-button-secondary" tooltip="Navigate back" tooltipOptions={{position: "bottom"}}   ></Button>
            <div>API Log</div>
            <Button label="Show Graph" className="p-button-success"
                onClick={handleShowGraph} />
        </div>
    )
}

const ApiLogDataListTable = () => {
    
    const {serialNumber} = useParams<any>();

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchApiLogCount(serialNumber));
        dispatch(fetchApiLogs({serialNo: serialNumber, options: {skip: 0, take: 20}}));
    }, []);

    const handleActionClick = (data: {name: string, id: any}) => {}

    const isLoading = useSelector((state: any) => state.logs.loadingLogs);
    const totalCount = useSelector((state: any) => state.logs.apiLogsCount);
    const records: any[] = useSelector((state: any) => state.logs.apiLogs);

    const handleFetch = (first: number, rows: number) => {
        dispatch(fetchApiLogs({serialNo: serialNumber, options: {skip: first, take: rows}}));
    }

    return (
        <VirtualActionDataList value={records} actions={null} actionColumnWidth="0rem"
                       onAction={handleActionClick} stripedRows 
                       totalRecords={totalCount} virtualLoading={isLoading} 
                       onFetch={handleFetch} >
            <Column header="SerialNo" field="SerialNo" style={{width: "25%", textAlign: "left"}} />
            <Column header="Timestamp" field="Timestamp"  style={{width: "25%", textAlign: "left"}} />
            <Column header="APIFunctionName" field="APIFunctionName" style={{width: "25%", textAlign: "left"}} />
            <Column header="ErrorCode" field="ErrorCode" style={{width: "auto", textAlign: "left"}} />
        </VirtualActionDataList>
    );
}

const ApiLogDataList = () => {
    const showGraphDialog = useSelector((state: any) => state.logs.showGraphDialog);

    return (
        <div className="ApiLogDataList LoadingProgress--Wrapper">
            { showGraphDialog && (<ApiLogGraphForm />)}
            <ApiLogHeader />
            <div className="ApiLogDataList--Separator" />
            <div className="ApiLogDataList--Body">
                <ApiLogDataListTable />
            </div>
        </div>
    )
}

export default ApiLogDataList;
