import React from "react";

import "./AccessDeniedPage.scss"

const AccessDeniedPage = () => {
    return (
        <div className="AccessDeniedPage">
            <span>You are not authorized to access this page.</span>
        </div>
    )
}

export default AccessDeniedPage;