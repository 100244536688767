import { UserType, LoginNetworkAPI, UserDetailDTO } from "../NetworkInterfaces";
import { CupuNetworkAPIBase } from "./CupuNetworkAPIBase";
import { NetworkHelper } from "../../helpers/NetworkHelper";
import { RestrictAccess } from "../NetworkSecurity";

export class CupuLoginNetworkAPI  extends CupuNetworkAPIBase implements LoginNetworkAPI {

    private isLoggedIn = false;
    private userRole: UserType | null = null;
    private loginUserName: string = "";

    async loginUser(username: string, password: string): Promise<boolean> {
        // const queryString = `username=${username}&password=${password}`;
        const ajaxData = {
            username: username, 
            password: password
        };
        const ajaxRequest =  super.createAjaxRequest("POST", "/WebApi/Login", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        this.isLoggedIn = response.result;
        if(response.result === true) {
            this.loginUserName = username;
            this.userRole = await this.fetchLoggedUserType();
        } else {
            this.loginUserName = "";
            this.userRole = null;
        }
        return response.result;
    }

    async logoutUser(): Promise<void> {
        this.isLoggedIn = false;
        this.userRole = null;
        const ajaxRequest = super.createAjaxRequest("POST", "/WebApi/Logout", {}, "json");
        await super.callAjax(ajaxRequest, "Bypass");
    }

    async resetUserPassword(username: string): Promise<boolean> {
        const ajaxData = {username: username};
        const ajaxRequest = super.createAjaxRequest("POST", "/WebApi/ResetPassword", ajaxData, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        return response.result === 'RESPONSE_OK';
    }

    private async fetchLoggedUserType() {
        const ajaxRequest = super.createAjaxRequest("GET", "users/get_logged_in_usertype", {}, "text");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        this.userRole = response;
        return response;   
    }

    getLoginUserName(): string {
        return this.loginUserName;
    }

    getLoggedUserType(): UserType {
        if(this.userRole === null)
            throw new Error("Error: UserType was not fetched.");
        return this.userRole;
    }

    async getCompanyName(): Promise<string> {
        const ajaxRequest = super.createAjaxRequest("POST", "/WebApi/GetCompanyName", {}, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        return response.result;
    }

    async getUserEntityNo(): Promise<string> {
        const ajaxRequest = super.createAjaxRequest("GET", "/WebApi/GetUserEntityNo", {}, "text");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        return response;
    }

    async getUserLogo(): Promise<string | undefined> {
        // Note: Admins do not have logos
        if(this.userRole === undefined || this.userRole === "Admin") return undefined;
        let logoPathUrl = "users/get_users_logo";
        let folderPath = "";

        const entityNo = await this.getUserEntityNo();
        const entityType = this.userRole;
        const entityAjaxRequest = super.createAjaxRequest("POST", "Entities/get_entity_inheritance_path", {
            entityNo, entityType}, "json");
        const entityResponse = await super.callAjax(entityAjaxRequest);
        const integratorNo = entityResponse.response_data.IntegratorNo;
       
        // switch(this.userRole) {
        //     case "Integrator": 
        //         logoPathUrl = "Integrator/get_integrator_logo"; 
        //         folderPath = "uploads/Integrator_logo/"
        //         break;
        //     case "Distributor": 
        //         logoPathUrl = "Distributor/get_Distributor_logo"; 
        //         folderPath = "uploads/distributor_logo/"
        //         break;
        //     case "End Customer": 
        //         logoPathUrl = "Endcustomer/get_endcustomer_logo"; 
        //         folderPath = "uploads/endcustomer_logo/"
        //         break;
        // }

        logoPathUrl = "Integrator/get_integrator_logo"; 
        folderPath = "uploads/Integrator_logo/"

        const ajaxRequest = super.createAjaxRequest("GET", logoPathUrl, {entityNo: integratorNo}, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");

        // Logo might not be filled in yet
        if(response.result !== "RESPONSE_OK") 
            return undefined;
            
        const logoImageUrl =  this.baseUrl + folderPath + response.logo_name;
        return logoImageUrl;
    }

    @RestrictAccess(["Admin", "Integrator", "Distributor", "End Customer"])
    async getAllRelatedUsers(): Promise<UserDetailDTO[]> {
        const ajaxRequest = super.createAjaxRequest("POST", "users/get_all_related_to", {}, "json");
        const response = await super.callAjax(ajaxRequest);
        return NetworkHelper.parseRelatedUsers(response);
    }

    isUserLoggedIn(): boolean {
        return this.isLoggedIn;
    }
}
