import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

import "./OrderInProductionSettings.scss";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { setOrderInProduction } from "./OrdersReduxSlice";

const OrderInProductionSettings = () => {

    // Select available orders out of store
    const orders = useSelector((state: any) => state.orders.fetchedOrders).map((order: any) => {
        return {
            label: order.OrderNo,
            value: order.OrderNo
        }
    });

    // Order In Production
    const orderInProduction = useSelector((state: any) => state.orders.orderInProductionNo);

    // Generate state
    const [selectedOrder, setSelectedOrder] = useState(orderInProduction);

    // Update selected order when a correct value is delivered later on by async network request
    useEffect(() => {
        if(orderInProduction !== selectedOrder) {
            setSelectedOrder(orderInProduction);
        }   
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderInProduction]);

    // Apply New Order In Production
    const dispatch = useDispatch();
    const applyChanges = () => {
        if(selectedOrder === null)
            return;
        dispatch(setOrderInProduction(selectedOrder));
    }

    return (
        <div className="OrderInProductionSettings">
            <label>Order In Production:</label>
            <Dropdown options={orders} value={selectedOrder} onChange={e => setSelectedOrder(e.value)}
                       style={{"width": "15rem"}} filter   ></Dropdown>
            <Button label="Apply Changes" className="p-button-secondary"
                    onClick={e => applyChanges()} ></Button>
        </div>
    );
}

export default OrderInProductionSettings;
