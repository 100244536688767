import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { UnitFilterOptions } from '../network/NetworkInterfaces';
import { NetworkManager } from '../network/NetworkManager';
import * as _ from "lodash";
import { FilterHelper } from '../helpers/FilterHelper';
import { editUnit } from './forms/EditUnitReduxSlice';
import { showPlaylistForm } from './forms/PlaylistReduxSlice';

// Units Network API
const unitsNetworkAPI = NetworkManager.getUnitsNetworkAPI();
const loginAPI = NetworkManager.getLoginNetworkAPI();
const soundAPI = NetworkManager.getSoundNetworkAPI();

// Helper Function Filters
interface EndCustomerFilterPayload {
    integrators: string[];
    distributors: string[];
}

/**
 *  Functions returning only the allowed list of integrators and distributors
 */

// Return the list of integrators restricted by the entity logged in
async function getAllowedIntegrators(integratorNoArray: string[]): Promise<string[]> {
    // Get the type of entity logged in
    const entityType = loginAPI.getLoggedUserType();
    if(entityType !== "Admin") {
        // If not administrator, fetch the entity's owning integrator and return it as the only allowed value
        const entityNo = await loginAPI.getUserEntityNo();
        const entityInheritance = await soundAPI.fetchEntityInheritance(entityType, entityNo);
        return [entityInheritance.IntegratorNo];
    } else {
        // If logged in as the administrator, impose no restrictions
        return integratorNoArray === null ? [] : integratorNoArray;
    }
}

// Restricts the tuple of integrator and distributor sets
async function getAllowedIntegratorDistributorTuple(tuple: EndCustomerFilterPayload): Promise<EndCustomerFilterPayload> {
    // Get the type of entity logged in
    const entityType = loginAPI.getLoggedUserType();
    if(entityType !== "Admin") {
        // For any non-admin entity, fetch the inheritance hierarchy
        const entityNo = await loginAPI.getUserEntityNo();
        const entityInheritance = await soundAPI.fetchEntityInheritance(entityType, entityNo);

        // If the user is integrator, impose restriction on the integrator, no restriction on distributors
        if(entityType === "Integrator") {
            return {
                integrators: [entityInheritance.IntegratorNo], 
                distributors: tuple.distributors
            };
        } else if(entityType === "Distributor" || entityType ==="End Customer") {
            // For distributor and end customer entities restrict both, integrators and distributors
            return {
                integrators: [entityInheritance.IntegratorNo], 
                distributors: [entityInheritance.DistributorNo]
            };
        } else {
            throw new Error("Unknown entity type");
        }
    } else {
        // If logged in as the administrator, impose no restrictions
        return tuple;
    }
}

/**
 *  Helper functions for fetching the integrators, distributors and end customers for the Units Filter
 */

// Fetch distributors that are allowed to access by the logged in user
async function fetchAllowedDistributors(integratorNoArray: string[]) {
    // Fetch all distributors for given integrators
    let distributorData = await unitsNetworkAPI.filterDistributorsByIntegrators(integratorNoArray);
    // Map data to their kyes
    const distributorNoArray = distributorData.map(item => item.id);       
    // Check the valid distrutors
    const endCustomerPayload = await getAllowedIntegratorDistributorTuple({integrators: integratorNoArray, distributors: distributorNoArray});
    // Map back the valid distributor records in the list
    distributorData = distributorData.filter(item => endCustomerPayload.distributors.indexOf(item.id) >= 0);
    // Return the valid set of distributors
    return distributorData;
}

// Returns the set of end customers allowed to be accessed by the logged in entity
async function fetchAllowedEndCustomers(payload: EndCustomerFilterPayload): Promise<any[]> {
    // First check, payload contains only valid allowed integrators and distributors
    const allowedPayload = await getAllowedIntegratorDistributorTuple(payload);
    let allowedEndCustomers = [];
    // If distributors DO EXIST, fetch only end customers owned by given distributors, 
    // otherwise all allowed End Customers
    const userType = loginAPI.getLoggedUserType();
    if(userType === "Admin") {
        if(allowedPayload.distributors.length > 0) {
            allowedEndCustomers = await unitsNetworkAPI.filterEndCustomersByDistributors(allowedPayload.distributors);
        } else {
            allowedEndCustomers = await unitsNetworkAPI.filterEndCustomers(allowedPayload.integrators, allowedPayload.distributors);
        }   

    } else {
        if(allowedPayload.integrators.length > 0 && allowedPayload.distributors.length > 0) {
            allowedEndCustomers = await unitsNetworkAPI.filterEndCustomersByDistributors(allowedPayload.distributors);
        } else {
            allowedEndCustomers = await unitsNetworkAPI.filterEndCustomers(allowedPayload.integrators, allowedPayload.distributors);
        }   
    }

    return allowedEndCustomers;
}


/**
 *  Async Thunks - asynchronous actions in the Store Slice
 */

export const fetchIntegratorsForFilter = createAsyncThunk(
    'units/fetchIntegratorsForFilter',
    async (payload: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const integratorData = await unitsNetworkAPI.fetchIntegrators();
        dispatch(updateIntegrators(integratorData));
    }
);

export const fetchDistributorsForFilter = createAsyncThunk(
    'units/fetchDistributorsForFilter',
    async (payload: string[], thunkAPI) => {
        const {dispatch} = thunkAPI;
        const allowedIntegrators  = await getAllowedIntegrators(payload);
        const allowedDistributors = await fetchAllowedDistributors(allowedIntegrators);
        dispatch(updateDistributors(allowedDistributors));
    }
);

export const fetchEndCustomersForFilter = createAsyncThunk(
    'units/fetchEndCustomersForFilter',
    async (payload: EndCustomerFilterPayload, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const allowedEndCustomers = await fetchAllowedEndCustomers(payload);
        dispatch(updateEndCustomers(allowedEndCustomers));
    }
);

export const applyIntegratorSelectionInFilter = createAsyncThunk(
    'units/applyIntegratorSelectionInFilter',
    async (payload: any, thunkAPI) => {
        const {dispatch} = thunkAPI;

        const allowedIntegrators  = await getAllowedIntegrators(payload);
        const allowedDistributors = await fetchAllowedDistributors(allowedIntegrators);
        const allowedDistributorKeys = allowedDistributors.map(item => item.id);
        // const allowedEndCustomers = await fetchAllowedEndCustomers({integrators: allowedIntegrators, distributors: allowedDistributorKeys});
        // Note: VP - When changing integrator settings, distributors are reseted and we need to fetch all end customers
        const allowedEndCustomers = await fetchAllowedEndCustomers({integrators: allowedIntegrators, distributors: []});

        dispatch(updateDistributors(allowedDistributors));
        dispatch(updateEndCustomers(allowedEndCustomers));
    }
);

export const applyDistributorSelectionInFilter = createAsyncThunk(
    'units/applyDistributorSelectionInFilter',
    async (payload: EndCustomerFilterPayload, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const allowedEndCustomers = await fetchAllowedEndCustomers(payload);
        dispatch(updateEndCustomers(allowedEndCustomers));
    }
);

export const fetchUnits = createAsyncThunk(
    'units/fetchUnits',
    async (payload: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const { units } = thunkAPI.getState() as any;

        dispatch(updateLoadingUnitsStatus(true));
        const unitsData = await unitsNetworkAPI.fetchUnits();        
        dispatch(updateFetchedUnits(unitsData));
        dispatch(updateLoadingUnitsStatus(false));

        if(units.searchedPhrase.length > 0) {
            dispatch(filterUnitsByPhrase(units.searchedPhrase));
        }

    }
);

export const filterUnits = createAsyncThunk(
    'units/filterUnits',
    async (payload: UnitFilterOptions, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingUnitsStatus(true));
        const unitsData = await unitsNetworkAPI.filterUnits(payload);
        dispatch(updateFetchedUnits(unitsData));
        dispatch(updateLoadingUnitsStatus(false));
    }
);

export const filterUnitsByPhrase = createAsyncThunk(
    'units/filterUnitsByPhrase',
    async (payload: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const {units } = thunkAPI.getState() as any;

        dispatch(updateSearchedPhrase(payload));

        const filteredUnits = FilterHelper.filterRecordsUsingPhrase(payload, units.fetchedUnits,
            ["SerialNo", "IntegratorSerialNo", "HardwareCode", "Status", "StatusTimestamp", "Note"]);
        dispatch(updateShownUnits(filteredUnits));
    }
);

export const triggerEditUnit = createAsyncThunk(
    'units/triggerEditUnit',
    async (payload: string, thunkAPI) => {
        const {dispatch} = thunkAPI;

        const unitsData = await unitsNetworkAPI.fetchUnits();
        const openedUnit = unitsData.find((unit: any) => unit.id === payload);
        if(openedUnit === undefined)
            throw new Error("Unit to edit was not found.");

        dispatch(editUnit(openedUnit));
    }
);

export const triggerPlaylists = createAsyncThunk(
    'units/triggerPlaylists',
    async (payload: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        
        const unitsData = await unitsNetworkAPI.fetchUnits();
        const openedUnit = unitsData.find((unit: any) => unit.id.toString() === payload);
        if(openedUnit === undefined)
            throw new Error("Unit to edit was not found.");

        dispatch(showPlaylistForm(openedUnit));
    }
);


// Initial State
const INITIAL_STATE = {
    shownUnits: [],
    fetchedUnits: [],
    integrators: [],
    distributors: [],
    endCustomers: [],
    loadingFilterData: false,
    loadingUnits: false,
    searchedPhrase: ""
}

// Redux Slice for Units Module
export const unitsSlice = createSlice({
    name: 'units',
    initialState: INITIAL_STATE,
    reducers: {
        updateFilterLoadingState: (state, action) => {
            state.loadingFilterData = action.payload;
        },
        updateIntegrators: (state, action) => {
            state.integrators = action.payload;
        },
        updateDistributors: (state, action) => {
            state.distributors = action.payload;
        },
        updateEndCustomers: (state, action) => {
            state.endCustomers = action.payload
        },
        updateFetchedUnits: (state, action) => {
            state.fetchedUnits = action.payload;
            state.shownUnits = _.clone(action.payload);
        },
        updateShownUnits: (state, action) => {
            console.dir(action.payload);
            state.shownUnits = action.payload;
        },
        updateLoadingUnitsStatus: (state, action) => {
            state.loadingUnits = action.payload;
        },
        clearUnits: (state, action) => {
            state.fetchedUnits = [];
            state.shownUnits = [];
        },
        updateSearchedPhrase: (state, action) => {
            state.searchedPhrase = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const {
    updateFilterLoadingState,
    updateIntegrators,
    updateDistributors,
    updateEndCustomers,
    updateLoadingUnitsStatus,
    updateFetchedUnits,
    updateShownUnits,
    clearUnits,
    updateSearchedPhrase
} = unitsSlice.actions

export default unitsSlice.reducer
