import React from "react";
import { NavigationHelper } from "../helpers/NavigationHelper";
import "./AppSidebarMenu.scss"
import ActionLink from "./ActionLink";
import { NetworkManager } from "../network/NetworkManager";

const loginAPI = NetworkManager.getLoginNetworkAPI();

// Gemeric component for menu item in submenu
const AppMenuItem = (props: any) => {
    return (
        <li className="AppSidebarMenu--SubMenu--MenuItem">
            <ActionLink 
                onClick={() => NavigationHelper.toAppUrl(props.url)}  
                className={props.className}
            >
                    { props.title }
            </ActionLink>
        </li>
    );
}

// Generic component for representation of submenu
const AppSubmenu = (props: any) => {
    return (
        <div className="AppSidebarMenu--SubMenu" >
            <div className="AppSidebarMenu--SubMenuHeader">
                <ActionLink 
                    onClick={() => NavigationHelper.toAppUrl(props.subMenuUrl)}
                    className={props.className}
                >
                    {props.title}
                </ActionLink>
            </div>
            <ul>
                {
                    props.menuitems.map((menuitem: any) => {
                        return (
                            <AppMenuItem 
                                title={menuitem.title} 
                                key={menuitem.title} 
                                url={menuitem.url}  
                                className={menuitem.className}
                            ></AppMenuItem>)
                    })
                }
            </ul>
        </div>
    );
}

// Menu item list for Units submenu
const UnitsMenuItems = [
    { title: "Groups", url: "unit-groups", onlyAdmin: false, className: "qa-unit-groups" },
    { title: "Map", url: "map", onlyAdmin: false, className: "qa-map" },
    { title: "Firmware", url: "firmware", onlyAdmin: true, className: "qa-firmware"},
    { title: "Cupu Types", url: "cuputypes", onlyAdmin: true, className: "qa-cupu-types" },
    { title: "Orders", url: "orders", onlyAdmin: true, className: "qa-orders"},
    { title: "Production", url: "production", onlyAdmin: true, className: "qa-production"}
];

const AppUnitsSubmenu = (props: any) => {
    let menuItems = UnitsMenuItems;
    if(loginAPI.getLoggedUserType() !== "Admin") {
        menuItems = menuItems.filter(menuItem => menuItem.onlyAdmin === false);
    }

    return (
        <AppSubmenu title="Units" subMenuUrl="/units"  menuitems={menuItems} className="qa-units" ></AppSubmenu>
    );
}

export default class AppSidebarMenu extends React.Component {

    render() {
        return (
            <div className="AppSidebarMenu">
                <AppUnitsSubmenu></AppUnitsSubmenu>
                <div className="AppSidebarMenu--Header">
                    <ActionLink 
                        onClick={() => NavigationHelper.toAppUrl("/sounds")}
                        className="qa-sound-library"
                    >
                        Sound Library
                    </ActionLink>
                </div>
                <div className="AppSidebarMenu--Header">
                    <ActionLink 
                        onClick={() => NavigationHelper.toAppUrl("/users")}
                        className="qa-users"
                    >
                        Users
                    </ActionLink>
                </div>
            </div>
        );
    }
}
