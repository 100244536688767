// React and Formik Imports
import React, { useState } from "react";
import { Formik, Form } from "formik";

// Yup Validation Schema Import
import { object, string } from "yup";

// PrimeReact and other imports
import { FormikTextField, FormikPasswordField } from "../forms/formik";
import { Button } from "primereact/button";
import LoginFormHeader from "./LoginFormHeader";

// SCSS Style Import
import "./LoginForm.scss";
import { NavigationHelper } from "../helpers/NavigationHelper";
import { useAuth } from "../common/Auth";

// LoginForm Body Component
const LoginFormBody = () => {
    return (
        <React.Fragment>
            <FormikTextField label="Username:" name="login" 
                    placeholder="Enter your username..." autoFocus></FormikTextField>
            <FormikPasswordField label="Password:" name="password"
                    placeholder="Enter your password..."></FormikPasswordField>
        </React.Fragment>
    );
}

// LoginForm Footer Component
const LoginFormFooter = () => {

    const handleResetPassword = (event: any) => {
        event.preventDefault();
        NavigationHelper.toUrl("/reset-password");
    }

    return (
        <div className="LoginForm--Footer" >
            <a href="!#" onClick={(e: any) => handleResetPassword(e)}  tabIndex={-1}  className="LoginForm--ForgotPassword" >Forgot password?</a>
            <Button type="submit" label="Login" className="p-button-success LoginForm--Submit qa-login"></Button>
        </div>
    );
}

// Invalid Credentials Message
const InvalidCredentialsMessage = (props: any) => {
    const visibilityStyle = props.visible ? "visible" : "hidden";
    return (
        <div className="InvalidCredentialsMessage" style={{visibility: visibilityStyle}} >
            You have entered invalid username or password.
        </div>
    )
}

// Initial Form Values
const INITIAL_VALUES = {
    login: "",
    password: ""
}

// Let's create LoginForm validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    login: string().required("Please, enter your username."),
    password: string().required("Please, enter your password.")
});


// Login Form Function Component
const LoginForm = () => {
    // Invalid Credentials State
    const [invalidCredentials, setInvalidCredentials] = useState(false);
    let auth: any = useAuth();

    const handleSubmit = async (values: any) => {
        const loginResult = await auth.login(values.login, values.password);
        if(loginResult === true) {
            NavigationHelper.toAppUrl("/");
        } else {
            setInvalidCredentials(true);
        }
    };

    return (
        <div className="LoginForm--Wrapper">
            <div className="LoginForm">
                <LoginFormHeader></LoginFormHeader>
                <Formik initialValues={INITIAL_VALUES} validationSchema={FORM_VALIDATION_SCHEMA}
                    onSubmit={handleSubmit}   >
                    <Form>
                        <InvalidCredentialsMessage visible={invalidCredentials} />
                        <LoginFormBody /> 
                        <LoginFormFooter />
                    </Form>
                </Formik>
            </div>
        </div>
    )
};

export default LoginForm;
