import React from "react"
import { Column } from "primereact/column";
import ActionsDataList from "./ActionsDataList";

import "./VirtualActionDataList.scss";

const MAX_ROWS = 20;
const VIRTUAL_ROW_HEIGHT = 45;

const VirtualActionDataList = ({children, totalRecords, virtualLoading, onFetch, ...rest}: any) => {

    const loadingText = () => {
        return <span className="loading-text"></span>;
    }

    const templatedChildren = children.map((child: any) => {
        const props = child.props;
        return (<Column key={child.props.field} {...props} loadingBody={loadingText} />);
    });

    const onVirtualScroll = (event: any) => {
        if(typeof onFetch === "function") {
            onFetch(event.first, event.last - event.first);
        }
    }

    return (
        <ActionsDataList {...rest} rows={MAX_ROWS} totalRecords={totalRecords} 
                scrollable virtualScrollerOptions={{ lazy: true, onLazyLoad: onVirtualScroll, itemSize: 46, delay: 0, showLoader: false }}>
            { templatedChildren }
        </ActionsDataList>
    );
}

export default VirtualActionDataList;
