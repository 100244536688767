import React, { useState } from "react";

// Import Style
import "./PlaylistToggleButton.scss";

const parseButtonStateFromPlaylist = (playlist: any) => {
    if(playlist.InPlaylist1 === "1" && playlist.InPlaylist2 === "1") return 12;
    else if(playlist.InPlaylist1 === "1") return 1;
    else if(playlist.InPlaylist2 === "1") return 2;
    return 0;
}

const formatButtonStateToPlaylist = (state: any) => {
    if(state === 0) return {InPlaylist1: null, InPlaylist2: null};
    else if(state === 1) return {InPlaylist1: "1", InPlaylist2: null};
    else if(state === 2) return {InPlaylist1: null, InPlaylist2: "1"};
    else return {InPlaylist1: "1", InPlaylist2: "1"};
}

const getToggleButtonNextState = (state: number) => {
    if(state === 0) return 1;
    else if(state === 1) return 2;
    else if(state === 2) return 12;
    else return 1;
}

// ToggleButton Titles
const PLAYLIST_SWITCH_TITLES: any = {
    0: "Playlist?",
    1: "Only 1",
    2: "Only 2",
    12: "1 & 2"
};

const PlaylistToggleButton = (props: any) => {
    const playlist = props.playlist;
    const curState = parseButtonStateFromPlaylist(playlist);
    const [state, setState] = useState<number>(curState);

    const handleButtonClick = () => {
        const nextState = getToggleButtonNextState(state);
        setState(nextState);
        if(typeof props.onChange === "function") {
            const formattedState = formatButtonStateToPlaylist(nextState);
            const playlistState = {id: playlist.id, ...formattedState};
            props.onChange(playlistState);
        }
    }

    const title = PLAYLIST_SWITCH_TITLES[state];
    const highlightClassName = state === 0 ? "PlaylistToggleButton--Highlight" : ""
    return (
        <div className={'PlaylistToggleButton ' + highlightClassName} 
                onClick={(e: any) => handleButtonClick()}>
            {title}
        </div>
    );
}

export default PlaylistToggleButton;
