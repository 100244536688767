import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";

import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../common/AppSearchBox";

import AvatarColumn from "../forms/AvatarColumn";
import ActionsDataList, { DataListAction } from "../forms/ActionsDataList";
import LoadingProgress from "../common/LoadingProgress";
import { confirmDialog } from "primereact/confirmdialog";

import "./DistributorDataList.scss";
import { createNewDistributor, deleteDistributor, editDistributor, fetchDistributors, filterDistributorsByPhrase } from "./DistributorReduxSlice";

// Wrapper Component for full-text search
const DistributorFilterHeader = () => {
    const dispatch = useDispatch();
    const handleFilterChanged = (phrase: string) => {
        dispatch(filterDistributorsByPhrase(phrase));
    }

    const handleCreateNewDistributor = () => {
        dispatch(createNewDistributor());
    }

    return (
        <div className="DistributorDataList--Header">
            <SearchBox placeholder="Search distributors..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <div />
            <Button label="New Distributor" className="p-button-success" 
                onClick={e => handleCreateNewDistributor()}/>
        </div>
    );
}

// Definition of Action Buttons for the DistributorDataTable
const DistributorTableAtions: DataListAction[] = [
    {name: "edit", label: "Edit", className: "p-button-secondary"},
    {name: "delete", label: "Delete", className: "p-button-danger"}
];

// Component for configuring the DataList Table component itself
const DistributorDataListTable = () => {
    // Code for invoking the initial fetching of data from the server
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchDistributors());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Select the filtered list of users from the Redux Store    
    const distributorData = useSelector((state: any) => state.distributors.shownDistributors);

    // Delete Confirmation Function
    const confirmDelete = (id: string) => {
        confirmDialog({
            message: 'Are you sure you want to delete this distributor?',
            header: 'Delete Distributor',
            icon: 'pi pi-exclamation-triangle',
            style: {'width': '30rem'},
            acceptClassName: 'p-button-danger',
            acceptLabel: 'Delete',
            rejectLabel: 'Cancel',
            rejectClassName: 'p-button-secondary',
            accept: () => dispatch(deleteDistributor(id))
        });
    }    


    // Code for handler for receiving clicks on Action Buttons
    const handleActionClick = (data: {name: string, id: any}) => {
        switch(data.name) {
            case "edit": dispatch(editDistributor(data.id)); break;
            case "delete": confirmDelete(data.id); break;
        }
    }

    const AvatarColumnTemplate = (dataRow: any) => {
        return (<AvatarColumn downloadUrlPrefix="/uploads/distributor_logo/" src={dataRow.DistributorLogo} />);
    }

    return (
        <ActionsDataList value={distributorData} dataKey="DistributorNo" actions={DistributorTableAtions} actionColumnWidth="15rem"
                       onAction={handleActionClick} stripedRows  >
            <Column header="Id" field="DistributorNo" sortable style={{maxWidth: "5rem", textAlign: "center"}} />
            <Column header="Name" field="Name" sortable style={{maxWidth: "30rem", textAlign: "left"}} />
            <Column header="Distributor Logo" field="DistributorLogo" style={{maxWidth: "auto", textAlign: "center"}} 
                       body={AvatarColumnTemplate} />
        </ActionsDataList>
    );
}


const DistributorDataList = () => {
    // Fetch isLoading status from the Store
    const isLoading = useSelector((state: any) => state.distributors.loadingDistributors);

    return (
        <div className="DistributorDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <DistributorFilterHeader />
            <div className="DistributorDataListHeader--Separator" />
            <DistributorDataListTable />
        </div>
    );
}

export default DistributorDataList;
