import React, { useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";

import { useDispatch, useSelector } from "react-redux";
import { SearchBox } from "../common/AppSearchBox";

import ActionsDataList, { DataListAction } from "../forms/ActionsDataList";
import LoadingProgress from "../common/LoadingProgress";

import "./CupuTypesDataList.scss";
import { createNewCupuType, fetchCupuTypes, filterCupuTypesByPhrase } from "./CupuTypesReduxSlice";

const CupuTypesFilterHeader = () => {
    const dispatch = useDispatch();
    const handleFilterChanged = (phrase: string) => {
        dispatch(filterCupuTypesByPhrase(phrase));
    }

    const handleCreateCupuType = () => {
        dispatch(createNewCupuType());
    }

    return (
        <div className="CupuTypesDataList--Header">
            <SearchBox placeholder="Search CUPU Types..." onChange={(e: any) => {handleFilterChanged(e)}} />
            <div />
            <Button label="New CUPU Type" className="p-button-success" 
                onClick={e => handleCreateCupuType()}/>
        </div>
    );
}

const CupuTypeDataListTable = () => {
    // Code for invoking the initial fetching of data from the server
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchCupuTypes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const cupuTypesData = useSelector((state: any) => state.cuputypes.shownCupuTypes);
    const handleActionClick = (data: {name: string, id: any}) => {}

    return (
        <ActionsDataList value={cupuTypesData} dataKey="HardwareCode" actions={null} actionColumnWidth="0rem"
                       onAction={handleActionClick} stripedRows  >
            <Column header="Hardware Code" field="HardwareCode" sortable style={{maxWidth: "10rem", textAlign: "center"}} />
            <Column header="Hardware Name" field="HardwareName" sortable style={{maxWidth: "auto", textAlign: "left"}} />
            <Column header="MinVPV" field="MinVPV" sortable style={{maxWidth: "10rem", textAlign: "center"}} />
            <Column header="MaxVPV" field="maxVPV" sortable style={{maxWidth: "10rem", textAlign: "center"}} />
            <Column header="MaxLPV" field="maxLPV" sortable style={{maxWidth: "10rem", textAlign: "center"}} />
            <Column header="MaxPVP" field="maxPVP" sortable style={{maxWidth: "10rem", textAlign: "center"}} />
            <Column header="MaxIcharge" field="maxIcharge" sortable style={{maxWidth: "10rem", textAlign: "center"}} />
        </ActionsDataList>
    );
}


const CupuTypesDataList = () => {
    // Fetch isLoading status from the Store
    const isLoading = useSelector((state: any) => state.cuputypes.loadingCupuTypes);

    return (
        <div className="CupuTypesDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <CupuTypesFilterHeader />
            <div className="CupuTypesDataListHeader--Separator" />
            <CupuTypeDataListTable />
        </div>
    );
}

export default CupuTypesDataList;
