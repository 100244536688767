import React, { useRef } from "react";
import GenericContentForm, {ContentFormStandardButtons} from "../../forms/GenericContentForm";
import LoadingProgress from "../../common/LoadingProgress";
import { NavigationHelper } from "../../helpers/NavigationHelper";

// Formik and Yup Schema Imports
import { Formik, Form } from "formik";
import  { mixed, number, object, string } from "yup";

// Formik Adaptation Components
import { FormikFileUploadField, FormikTextField } from "../../forms/formik";

// Style Import
import "./UploadSoundForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { loadEntityTree, uploadNewSound } from "../SoundReduxSlice";
import EntityTreeView from "../controls/EntityTreeView";
import SoundOwnerControl from "../controls/SoundOwnerControl";
import { NetworkManager } from "../../network/NetworkManager";

const loginAPI = NetworkManager.getLoginNetworkAPI();

// Initial Form Values
const INITIAL_VALUES = {
    description: "",
    soundFile: null,
    endCustomerId: null,
    crcCode: "CRC"   
};

const FILE_SIZE = 8 * 1024 * 1024;
const SUPPORTED_FORMATS: string[] = ["audio/mp4", "audio/x-m4a", "audio/mpeg", "audio/aac", "audio/ogg", "audio/wav", "audio/x-ms-wma"];

// Let's create SoundDetailForm validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    IntegratorOwner: string().nullable(true).test("soundWOwner", "Please, fill in the sound owner", (value) => {
        return loginAPI.getLoggedUserType() === "Admin" ? (value !== undefined && value !== null && value != "0") 
                : true;
    }),
    description: string().required("Please, fill in the sound description."),
    crcCode: string(),
    soundFile: mixed()
    .nullable(false)
    .required("Please, select the sound you want to upload")
    .test("size", "Selected file is too big. Maximum size is 8MB.",  value => !value || (value && value.size <= FILE_SIZE))
    .test("type", "File has unsupported format Supported file formats are (mp3, aac, ogg, wav, wma).",
        value => !value || (value && SUPPORTED_FORMATS.includes(value.type)))
});


const UploadSoundForm = () => {

    const dispatch = useDispatch();

    const loading = useSelector((state: any) => state.sounds.loadingSoundDetails > 0);

    const handleSubmit = (values: any) => {
        dispatch(uploadNewSound(values));
    }

    const handleReject = () => {
        NavigationHelper.toAppUrl("/sounds");
    }

    const formRef = useRef<any>(null);

    const FormFooter = () => {
        return (
            <ContentFormStandardButtons
                acceptLabel="Upload" onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const handleSoundOwnerChanged = (entityType: string | null, entityNo: string | null) => {
        dispatch(loadEntityTree({entityType, entityNo}));
    }

    const FormBody = () => {
        return (
            <div className="UploadSoundForm--Body">
                <div className="UploadSoundForm--Body--LeftPart">
                    <span>Sound Availability:</span>
                    <EntityTreeView />
                    </div>
                <div className="UploadSoundForm--Body--RightPart">
                    <SoundOwnerControl onSoundOwnerChanged={handleSoundOwnerChanged} />
                    <FormikFileUploadField label="Sound file to upload" name="soundFile"
                        chooseLabel="Select Sound File"  customUpload  />
                    <FormikTextField label="Sound Description:" name="description" 
                            placeholder="Enter description..." ></FormikTextField>
                    <FormikTextField label="CRC:" name="crcCode" disabled />
                    <div />
                </div>
            </div>
        );
    };

    return (
        <GenericContentForm title="Upload Sound" footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={loading} />
            <Formik initialValues={INITIAL_VALUES} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} >                    
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default UploadSoundForm;
