import { UserType } from "../../network/NetworkInterfaces";
import { AjaxHelper } from "./AjaxHelper";

export class UsersNetworkHelper {

    parseUsers(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["users"], "ID");
    }

    parseRelatedUsers(userType: UserType, response: any): any[] {
        let ID = "";
        if(userType === "Distributor") ID = "DistributorNo";
        else if(userType === "Integrator") ID = "IntegratorNo";
        else if(userType === "End Customer") ID = "EndCustomerNo";
        return AjaxHelper.parseAjaxEnumResponse(response, ["all_related_to"], ID, "Name");
    }

    parseIntegrators(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["Integrators"], "IntegratorNo");
    }

    parseDistributors(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["Distributors"], "DistributorNo");
    }

    parseEndCustomers(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["Endcustomers"], "EndCustomerNo")
    }

    parseIntegratorsRelatedToDistributor(response: any) {
        return AjaxHelper.parseAjaxEnumResponse(response, ["Integrators"], "IntegratorNo", "Name");
    }

    parseUsersRelatedToIntegrators(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["all_related_to"], "IntegratorNo");
    }

    parseUsersRelatedToDistributors(response: any): any[] {
        return AjaxHelper.parseAjaxResponse(response, ["all_related_to"], "DistributorNo");
    }
}
