import { Formik, Form, useField } from "formik";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { object, string, mixed } from "yup";
import LoadingProgress from "../../common/LoadingProgress";
import { FormikAvatarUploadField, FormikDropdownField, FormikTextAreaField, FormikTextField } from "../../forms/formik";
import GenericContentForm, { ContentFormStandardButtons } from "../../forms/GenericContentForm";
import { NavigationHelper } from "../../helpers/NavigationHelper";
import { saveDistributor } from "../DistributorReduxSlice";

import "./EditDistributorForm.scss";

const FILE_SIZE = 8 * 1024 * 1024;
const SUPPORTED_FORMATS: string[] = ["image/jpeg", "image/png", "image/gif"];

// Let's create SoundDetailForm validation schema
const FORM_VALIDATION_SCHEMA = object().shape({
    Name: string().required("Please, fill in the end customer name."),
    IntegratorNo: string().nullable().required("Please, select the associated integrator."),
    WelcomeText: string().required("Please, fill in the welcome text."),
    DistributorLogo: mixed()
        .nullable(true)
        .test("size", "Selected file is too big. Maximum size is 8MB.",  
            (value) => typeof value === "string" || (!value || (value && value.size <= FILE_SIZE)))
        .test("type", "File has unsupported format Supported file formats are only images.",
            value => typeof value === "string" || (!value || (value && SUPPORTED_FORMATS.includes(value.type))))
});

const EditDistributorForm = () => {

    // Submit Form Handler
    const dispatch = useDispatch();
    const handleSubmit = (values: any) => {
        dispatch(saveDistributor(values));
    }

    const handleReject = () => {
        NavigationHelper.toAppUrl("/distributors"); 
    }

    const editedDistributor = useSelector((state: any) => state.distributors.editedDistributor);
    const isLoading = useSelector((state: any) => state.distributors.loadingDistributors);
    const relatedIntegrators = useSelector((state: any) => state.distributors.relatedIntegrators);

    const dialogTitle = editedDistributor.DistributorNo === undefined ?
                "Create New Distributor" : "Edit Existing Distributor";

    // Form Footer
    const formRef = useRef<any>(null);
    const FormFooter = () => {
        const acceptLabel = editedDistributor.DistributorNo === undefined ? "Create" : "Update";
        return (
            <ContentFormStandardButtons
                acceptLabel={acceptLabel} onAccept={() => formRef.current.handleSubmit()} onReject={handleReject} /> );
    }

    const FormBody = () => {  
        const [logoUrl] = useField<string>("DistributorLogo");

        return (
                <div className="EditDistributor--Body">
                    <div className="EditDistributor--Body--Left-Part" >
                        <FormikTextField label="Name:" name="Name" 
                            placeholder="Enter distributor name..." ></FormikTextField>
                        <FormikDropdownField placeholder="--- Select Integrator ---"
                                optionValue="id" optionLabel="name"
                               options={relatedIntegrators} name="IntegratorNo"  />
                        <FormikTextAreaField label="Welcome Text" name="WelcomeText" rows={4}
                            placeholder="Enter welcome text..." />
                    </div>
                    <div>
                        <FormikAvatarUploadField label="Distributor Logo" name="DistributorLogo" 
                                    downloadUrlPrefix="/uploads/distributor_logo/" value={logoUrl.value}  />
                    </div>
                </div>
        );
    }

    return (
        <GenericContentForm title={dialogTitle} footer={FormFooter} onCancel={handleReject} >
            <LoadingProgress show={isLoading} />
            <Formik initialValues={editedDistributor} validationSchema={FORM_VALIDATION_SCHEMA}
                onSubmit={handleSubmit} innerRef={formRef} enableReinitialize >
                <Form >
                    <FormBody />
                </Form>
            </Formik>
        </GenericContentForm>
    );
}

export default EditDistributorForm;
