import { AjaxHelper } from "./AjaxHelper";
import * as _ from "lodash";

export class UnitGroupsNetworkHelper {

    parseUnitGroups(response: any): any[] {
        const data = AjaxHelper.parseAjaxResponse(response, ["UnitGroups"], "UnitGroupNo");
        return data.map((unitGroup: any) => {
            return {
                id: parseInt(unitGroup.UnitGroupNo),
                Id: unitGroup.UnitGroupNo,
                name: unitGroup.Name,
                endCustomerId: parseInt(unitGroup.EndCustomerNo),
                endCustomerName: unitGroup.Endcustomer
            }
        });
    }

    parseUnitGroupsEnumeration(response: any): any[] {
        if(response.response_data === false) return [];
        else return AjaxHelper.parseAjaxEnumResponse(response, [], "UnitGroupNo", "Name");
    }

    parseEndCustomerUnits(response: any): any[] {
        return AjaxHelper.parseAjaxEnumResponse(response, ["units"], "SerialNo", "Note");
    }

    parseUnitGroupUnits(response: any): any[] {
        return AjaxHelper.parseAjaxEnumResponse(response, ["GroupsUnits"], "SerialNo", "Note");
    }

    formatUnitGroupSaveRequest(unitGroup: any): any {
        return {
            data: {
                UnitGroupNo: typeof unitGroup.id === "number" ? unitGroup.id : undefined,
                Name: unitGroup.name,
                EndCustomerNo: unitGroup.endCustomerId,
                units: _.cloneDeep(unitGroup.unitsInGroup)
            }
        }
    }
}
