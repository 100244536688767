
export class NavigationHelper {

    static toUrl(url: string) {
        window.location.hash= "#" + url;
    }

    static toAppUrl(url: string) {
        if(url[0] === "/") url = url.slice(1);
        this.toUrl(`/app/${url}`);
    }

    static goBack() {
        window.history.back();
    }
}
