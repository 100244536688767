import React from 'react';

// Hook Imports
import { useDispatch, useSelector } from 'react-redux';
import { useLocationChange } from './helpers/RouterHelper';

// Application Style Import
import './App.scss';

// React Router Imports
import {
  Redirect,
  Route
} from "react-router-dom";
import { Container } from 'react-bootstrap';
import {GuardedAnimatedRoute} from "./common/AnimatedRoute"

// Global Application Components
import AppHeader from "./common/AppHeader";
import AppSidebar from "./common/AppSidebar";
import {AppFooter} from "./common/AppFooter";
import ExceptionDialog from './common/ExceptionDialog';


// Importing Data-Clearing Actions
import { clearSounds } from './sounds/SoundReduxSlice';
import { ALL_ROLES_ACCESS, APPLICATION_ROUTING } from './RouterConfiguration';
import { clearUnitGroups } from './unitgroups/UnitGroupsReduxSlice';
import NotImplementedPage from './common/NotImplementedPage';
import AppSwitch from './common/AppSwitch';

// Content-Page Switching Component
const ApplicationContentSwitch = ({animationType, ...rest}: any) => {
  return (
      <Container className="ViewContainer">
    <AppSwitch>
          {
              APPLICATION_ROUTING.map(route => {
                  return (
                    <GuardedAnimatedRoute 
                        key={route.path}
                        type={animationType}                    
                        exact
                        path={route.path}
                        component={route.component}
                        userRoles={route.roles}
                  />)
              })
          }
            <GuardedAnimatedRoute 
                  key="page-not-found" 
                  type="fade" 
                  exact
                  path="/app/page-not-found" 
                  component={NotImplementedPage}
                  userRoles={ALL_ROLES_ACCESS} />
            <Redirect exact path="/app" to="/app/home" ></Redirect>
        </AppSwitch>
      </Container>
  );
}

// Hook for cleaning store data for non-active pages
// TODO: Does this have meaning for anything???
const useDataCleanup = () => {
    const dispatch = useDispatch();

    useLocationChange((location: any, prevLocation: any) => {
        if(prevLocation === null || prevLocation === undefined)
            return;
          const pathname = prevLocation.pathname;
        if(pathname === "/app/sounds") {
          dispatch(clearSounds(null));
        } else if(pathname === "/app/unit-groups") {
          dispatch(clearUnitGroups(null));
        } 
    });
}

const App = () => {

  // Hook for Store Data-Clenaup 
  // (means clear given data in store, when associated page is not visible)
  useDataCleanup(); 
  
  const animationType = "fade";
  const renderExceptionDialog = useSelector((state: any) => state.app.exceptionDialogVisible);

  return (
    <div className="App--ContentWrapper">
        {renderExceptionDialog && <ExceptionDialog />}

        <AppHeader></AppHeader>
        <div className="App--Body" >
            <AppSidebar></AppSidebar>
            <div>
              <div className="App--ContentPageWrapper" >
                  <ApplicationContentSwitch animationType={animationType} />
              </div>
            </div>
        </div>
        <AppFooter></AppFooter>
    </div>
  );
}

export default App;
