import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { NetworkManager } from '../network/NetworkManager';
import * as _ from "lodash";
import { UnitGroupDTO } from '../network/NetworkInterfaces';
import { NavigationHelper } from '../helpers/NavigationHelper';
import { FilterHelper } from '../helpers/FilterHelper';

// Network API
const unitGroupNetworkAPI = NetworkManager.getUnitGroupNetworkAPI();
const loginAPI = NetworkManager.getLoginNetworkAPI();

// Asynchronous Slice Actions
export const fetchUnitGroups = createAsyncThunk(
    'unitGroups/fetchUnitGroups',
    async (payload: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true));

        try {
            const unitGroups = await unitGroupNetworkAPI.fetchUnitGroups();
            dispatch(updateUnitGroups(unitGroups));
        }
        finally {
            dispatch(updateLoadingStatus(false));
        }
    }
);

export const filterUnitGroups = createAsyncThunk(
    'unitGroups/filterUnitGroups',
    async (payload: string, thunkAPI) => {
        const {dispatch} = thunkAPI;
        const {unitGroups } = thunkAPI.getState() as any;
        const { fetchedUnitGroups } = unitGroups;

        const filteredUnitGroups = FilterHelper.filterRecordsUsingPhrase(payload, fetchedUnitGroups, 
            ["Id", "name", "endCustomerName"]);
        dispatch(updateShownUnitGroups(filteredUnitGroups));
    }
);

// Create New Unit Group
export const createNewUnitGroup = createAsyncThunk(
    'unitGroups/createNewUnitGroup',
    async (payload: undefined, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateLoadingStatus(true))

    
        try {
            // Fetch related users
            const relatedUsers = await loginAPI.getAllRelatedUsers();
            dispatch(updateRelatedUsers(relatedUsers));

            // Clear available and associated units
            dispatch(fetchEndCustomerUnits(null));
            dispatch(updateAssociatedUnits([]));

            // Clear unit group details
            dispatch(updateEditedUnitGroup({id: null, name: "", endCustomerId: null}));

            NavigationHelper.toAppUrl("unit-groups/detail");
        }
        finally {
            dispatch(updateLoadingStatus(false))
        }
    }
);


// Edit Existing Unit Group
export const editUnitGroup = createAsyncThunk(
    'unitGroups/editUnitGroup',
    async (payload: number, thunkAPI) => {
        const {dispatch } = thunkAPI;
        dispatch(updateLoadingStatus(true))

        // Get Slice State
        const { unitGroups } = thunkAPI.getState() as any;
        const  { fetchedUnitGroups } = unitGroups;
        const editedUnitGroup = fetchedUnitGroups.find((g: any) => g.id === payload);
        if(editedUnitGroup === undefined)
            throw new Error("Unit Group to edit was not found.");
    
        try {
            // Fetch related users
            const relatedUsers = await loginAPI.getAllRelatedUsers();
            dispatch(updateRelatedUsers(relatedUsers));

            // Fetch available  and associated units
            dispatch(fetchEndCustomerUnits(editedUnitGroup.endCustomerId));
            const associatedUnits = await unitGroupNetworkAPI.fetchGroupUnits(editedUnitGroup.id);
            dispatch(updateAssociatedUnits(associatedUnits));

            // Update Unit Group Details to Store State
            dispatch(updateEditedUnitGroup({
                id: editedUnitGroup.id,
                name: editedUnitGroup.name, 
                endCustomerId: editedUnitGroup.endCustomerId
            }));

            NavigationHelper.toAppUrl("unit-groups/detail");
        }
        finally {
            dispatch(updateLoadingStatus(false))
        }
    }
);

// Update Unit Group
export const updateUnitGroup = createAsyncThunk(
    'unitGroups/updateUnitGroup',
    async (payload: any, thunkAPI) => {
        const {dispatch} = thunkAPI;
        dispatch(updateDetailFormLoadingStatus(true))
    
        try {
            // TODO: Refactor
            const unitGroupDTO: UnitGroupDTO = {
                id: payload.id,
                name: payload.name,
                endCustomerId: payload.endCustomerId,
                unitsInGroup: payload.selectedUnits.map((unit: any) => { return unit.value;})
            };

            await unitGroupNetworkAPI.saveUnitGroup(unitGroupDTO);
            NavigationHelper.toAppUrl("unit-groups");
        }
        finally {
            dispatch(updateDetailFormLoadingStatus(false))
        }
    }
);

// Update Unit Group
export const fetchEndCustomerUnits = createAsyncThunk(
    'unitGroups/fetchEndCustomerUnits',
    async (endCustomerId: number | null, thunkAPI) => {
        const {dispatch} = thunkAPI;

        if(endCustomerId == null) {
            dispatch(updateAvailableUnits([]));
            dispatch(updateAssociatedUnits([]));
        } else {
            const availUnits = await unitGroupNetworkAPI.fetchAvailUnitsForEndCustomer(endCustomerId);
            dispatch(updateAvailableUnits(availUnits));   
            dispatch(updateAssociatedUnits([]));
        }
    }
);

// Update Unit Group
export const deleteUnitGroup = createAsyncThunk(
    'unitGroups/deleteUnitGroup',
    async (unitGroupId: number, thunkAPI) => {
        const {dispatch} = thunkAPI;
        await unitGroupNetworkAPI.deleteUnitGroup(unitGroupId);
        dispatch(fetchUnitGroups(null));
    }
);

// Initial State
const INITIAL_STATE = {
    // Unit Group Table
    loadingUnitGroups: false,
    fetchedUnitGroups: [],
    shownUnitGroups: [],
    // Unit Gorup Form
    loadingUnitGroupForm: false,
    relatedUsers: [],
    editedUnitGroup: {},
    availableUnits: [],
    associatedUnits: []
};

// Redux Slice for Sounds
export const unitGroupsSlice = createSlice({
    name: 'unitGroups',
    initialState: INITIAL_STATE,
    reducers: {
        updateAvailableUnits: (state, action) => {
            state.availableUnits = action.payload;
        },
        updateAssociatedUnits: (state, action) => {
            state.associatedUnits = action.payload;
        },
        updateUnitGroups: (state, action) => {
            state.fetchedUnitGroups = action.payload;
            state.shownUnitGroups = _.cloneDeep(action.payload);
        },
        updateShownUnitGroups: (state, action) => {
            state.shownUnitGroups = _.cloneDeep(action.payload);
        },
        updateLoadingStatus: (state, action) => {
            state.loadingUnitGroups = action.payload;
        },
        updateDetailFormLoadingStatus: (state, action) => {
            state.loadingUnitGroupForm = action.payload;
        },
        clearUnitGroups: (state, action) => {
            state.fetchedUnitGroups = [];
            state.shownUnitGroups = [];
        },
        updateRelatedUsers: (state, action) => {
            state.relatedUsers = action.payload;
        },
        updateEditedUnitGroup: (state, action) => {
            state.editedUnitGroup = action.payload;
        }
    }
});

// Action creators are generated for each case reducer function
export const { 
    updateLoadingStatus,
    updateUnitGroups,
    clearUnitGroups,
    updateShownUnitGroups,
    updateDetailFormLoadingStatus,
    updateRelatedUsers,
    updateEditedUnitGroup,
    updateAvailableUnits,
    updateAssociatedUnits
} = unitGroupsSlice.actions

export default unitGroupsSlice.reducer
