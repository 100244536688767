import React from "react";

// Import Routing Facilities
import {
    HashRouter as Router,
    Redirect,
    Switch,
    Route
} from "react-router-dom";
import { Container } from 'react-bootstrap';

// Login Form Imports
import LoginForm from "./login/LoginForm";
import PasswordResetForm from "./login/PasswordResetForm";

// The main access-restricted area of application
import App from "./App";
import { AnimatedRoute } from "./common/AnimatedRoute";
import { ProvideAuth } from "./common/Auth";
import { ALL_ROLES_ACCESS } from "./RouterConfiguration";
import MasterRoute from "./common/MasterRoute";
import AppSwitch from "./common/AppSwitch";
import { ConfirmDialog } from "primereact/confirmdialog";

// The main component - integration of Application and LoginForm Components
const Main = () => {

    // Note: Switch Component must be present for Redirects to be working
    return (
      <ProvideAuth>
        <ConfirmDialog />
        <Router>
          <div className="App">
              <Container className="ViewContainer">            
                  <AppSwitch> 
                    <Route exact path="/support/download/:filename" children={({match}) => {
                        if(match) {
                          const url = `http://localhost:3000/support/download_file?filename=${match.params.filename}`;
                          window.location.href=url;
                        }
                        return null;
                    }} />

                    <AnimatedRoute type="fade" path="/reset-password" component={PasswordResetForm} />
                    <AnimatedRoute type="fade" path="/login" component={LoginForm} />
                    <MasterRoute type="fade" path="/app" component={App} userRoles={ALL_ROLES_ACCESS} />
                    <Redirect path="/" to={{pathname: "/login"}} ></Redirect>
                    <Redirect path="*" to={{pathname: "/login"}}></Redirect>
                  </AppSwitch>
              </Container> 
          </div>
        </Router>
      </ProvideAuth>
    );
  }
  
  export default Main;
  