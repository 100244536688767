import React from "react";
import { Button } from "primereact/button";
import GenericDataList from "./GenericDataList";
import { Column } from "primereact/column";

// Import ActionDataList Style
import "./ActionDataList.scss";
import { useRef, useEffect } from "react";

// Definition classes for actions
export interface DataListAction {
    name: string;
    label: string;
    className?: string;
    isVisible?: (dataRow: any) => boolean;
}

export type DataListActions = DataListAction[];

// Action Button Component
const ActionButton = (props: any) => {
    return (
        <Button {...props} />
    )
}

// Action Button List Component inside a column
const ActionButtonList = (props: any) => {
    return (
            <div className="ActionButtonList">
                {props.actions.map((actionButton: DataListAction) => {
                    if(actionButton.isVisible !== undefined && actionButton.isVisible(props.dataRow) === false) {
                        return null;
                    }
                    return (
                        <ActionButton key={actionButton.name}  
                                    {...actionButton}  
                                    onClick={() => 
                                        props.onAction({
                                            id: props.dataRow.id, 
                                            name: actionButton.name
                                        })
                                    }
                            />
                        )
                })}
            </div>
    )
}

// Final Action Data List Component derived from GeneracDataList
const ActionsDataList = ({children, actionColumnWidth, actions, ...rest}: any) => {    

    // Column Template for Actions' Column
    const actionsColumnTemplate = (dataRow: any) => (
            <ActionButtonList 
                    dataRow={dataRow} 
                    actions={actions}
                    onAction={(payload: any) => rest.onAction(payload)}
        />);

    // Wrap each generic content of cell into <span /> element, it is used for special styling purposes
    const genericColumnTemplate = (rowData: any[], props: any) => { 
        return (<span>{rowData[props.field]}</span>) 
    };      
    const columns = children.map((column: any) => {                
        if(column === null) return null;
        const props = column.props;
        const columBody = props.body === undefined ? (genericColumnTemplate) : props.body;
        return (<Column {...props} key={props.field}  body={columBody} />);
    });

    // Trigger zoom animation - TODO: REFACTORING
    const refDataList = useRef<HTMLDivElement>(null);
    useEffect(() => {
        let timeoutID: null | number = null;   
        let span: HTMLElement|null = null;     

        const handleMouseEnter = (e: MouseEvent) => {
            if((e.target as HTMLElement).nodeName.toUpperCase() === "TD") {
                if(timeoutID !== null) {
                    window.clearTimeout(timeoutID);                
                }   
                const targetEl = e.target as HTMLElement;
                const spanEl = targetEl.querySelector("span");
                if(targetEl == null || spanEl == null)
                    return;
                const boundsWrapper = targetEl.getBoundingClientRect();
                const boundsContent = spanEl.getBoundingClientRect();
                if(boundsContent.width <= boundsWrapper.width && boundsContent.height <= boundsWrapper.height)
                    return;


                timeoutID = window.setTimeout(() => {
                    timeoutID = null;
                    const targetEl = e.target as HTMLElement;
                    if(targetEl === null)
                        return;
                    targetEl?.classList.add("zoom");

                }, 1000);   
                e.stopImmediatePropagation();
                e.stopPropagation();
            }
        }

        const handleMouseLeave = (e: MouseEvent) => {
            if((e.target as HTMLElement).nodeName.toUpperCase() === "TD") {
                if(timeoutID !=  null) {
                    window.clearTimeout(timeoutID);
                    timeoutID = null;   
                }
                const zoomTableCell = refDataList.current?.querySelector("td.zoom");
                if(zoomTableCell !== null) {
                    zoomTableCell?.classList.remove("zoom");
                    span = null;
                }
            }
        }

        refDataList.current?.addEventListener("mouseenter", handleMouseEnter, {capture: true});
        refDataList.current?.addEventListener("mouseleave", handleMouseLeave, {capture: true});
    }, []);


        
    // Extension of GenericDataList to support Actions in the last column
    return (
            <div ref={refDataList}>
                <GenericDataList {...rest} >
                    {columns}
                    { actions && actions.length > 0 && 
                        ( <Column body={actionsColumnTemplate} field="id" header="Actions" style={{"maxWidth": actionColumnWidth, "textAlign": "center", justifyContent: "center"}} /> )
                    }
                </GenericDataList>
            </div>
        );
}

export default ActionsDataList;
