

export class EntityStateHelper {

    constructor(private rootNode: any) {}

    // Returns the list of selected units using Breadth-First Search
    getSelectedUnits(): string[] {
        const selectedUnits: string[] = [];

        let nodeQueue = [this.rootNode];
        while(nodeQueue.length > 0) {
            const node = nodeQueue.shift();
            if(node.Children === null || node.Children === undefined) {
                if(node.IsChecked === true) {
                    selectedUnits.push(node.EntityNo);
                }
            } else {
                nodeQueue = nodeQueue.concat(node.Children);
            }
        }

        return selectedUnits;
    }

    // Update selected nodes using Depth-First Search
    updateSelectedUnits(selectedUnits: string[]) {
        this.updateSelectedUnitsDFS(this.rootNode, selectedUnits);
    }

    // The DFS algorithm itself to check selection of units
    private updateSelectedUnitsDFS(node: any, selectedUnits: string[]): boolean {
        if(node.Children === null || node.Children === undefined) {
            const isNodeSelected = selectedUnits.indexOf(node.EntityNo) >= 0;
            this.markEntityAs(node, isNodeSelected);
            return isNodeSelected;
        } else {
            if(node.Children.length === 0)
                return false;
            let allChildNodesSelected = true;
            for(const childNode of node.Children) {
                const isChildNodeSelected = this.updateSelectedUnitsDFS(childNode, selectedUnits);
                allChildNodesSelected = allChildNodesSelected && isChildNodeSelected;
                    
            }
            this.markEntityAs(node, allChildNodesSelected);
            return allChildNodesSelected;
        } 
    }

    setCheck(entityNo: string, flag: boolean | null) {
        // Find the entity path
        const entityPath = this.scanEntityPath(this.rootNode, entityNo);
        if(entityPath === undefined)
            return;
        
        // Mark entity itself
        const entity = entityPath[entityPath.length - 1];
        this.markEntityAs(entity, flag);

        
        // Resolve flagging desdendant and ancestor nodes
        if(flag === true) {
            this.markDescendantsAs(entity, true);
        } else if(flag === false) {
            this.markAncestorsAs(entityPath, false);
            this.markDescendantsAs(entity, false);
        }
    }

    // Marks all ancestor nodes with given flag
    private markAncestorsAs(entityPath: any[], flag: boolean | null) {
        for(let i = 0; i < entityPath.length - 1; i++) {
            this.markEntityAs(entityPath[i], flag);
        }
    }

    // Depth-First Search to mark all desdendant nodes
    private markDescendantsAs(entity: any, flag: boolean | null) {
        if(entity.Children === null || entity.Children === undefined) return;
        else {
            for(const child of entity.Children) {
                this.markEntityAs(child, flag);
                this.markDescendantsAs(child, flag);
            }
        }
    }

    // Sets the check flag to the entity
    private markEntityAs(entity: any, flag: boolean | null) {
        entity.IsChecked = flag;
    }

    // Depth-First Search to find entity path from Root to the searched entity
    private scanEntityPath(entity: any, entityNo: string): any[] | undefined {
        if(entity.EntityNo === entityNo) {
            return [entity];
        } else {
            if(entity.Children === null || entity.Children === undefined) return undefined;
            for(const child of entity.Children) {
                const result = this.scanEntityPath(child, entityNo);
                if(result !== undefined) {
                    return [entity].concat(result);
                }
            }
            return undefined;
        }
    }
}
