import { CupuTypesNetworkAPI, FirmwareNetworkAPI, LoginNetworkAPI, LogsNetworkAPI, OrdersAPI, PDFManualsAPI, SoundNetworkAPI, UnitGroupNetworkAPI, UnitsNetworkAPI, UsersNetworkAPI } from "../NetworkInterfaces";
import { CupuFirmwareNetworkAPI } from "./CupuFirmwareNetworkAPI";
import { CupuLoginNetworkAPI } from "./CupuLoginNetworkAPI";
import { CupuLogsNetworkAPI } from "./CupuLogsNetworkAPI";
import { CupuOrdersNetworkAPI } from "./CupuOrdersNetworkAPI";
import { CupuPDFManualsAPI } from "./CupuPDFManualsAPI";
import { CupuSoundNetworkAPI } from "./CupuSoundNetworkAPI";
import { CupuTypesNetworkAPIImpl } from "./CupuTypesNetworkAPI";
import { CupuUnitGroupNetworkAPI } from "./CupuUnitGroupNetworkAPI";
import { CupuUnitsNetworkAPI } from "./CupuUnitsNetworkAPI";
import { CupuUsersNetworkAPI } from "./CupuUsersNetworkAPI";

export class CupuNetworkAPI {

    private loginAPI: LoginNetworkAPI;
    private soundAPI: SoundNetworkAPI;
    private unitGroupAPI: UnitGroupNetworkAPI;
    private unitsAPI: UnitsNetworkAPI;
    private usersAPI: UsersNetworkAPI;
    private cupuTypesAPI: CupuTypesNetworkAPI;
    private firmwareAPI: FirmwareNetworkAPI;
    private logsAPI: LogsNetworkAPI;
    private ordersAPI: OrdersAPI;
    private manualsAPI: PDFManualsAPI;

    constructor(private baseUrl: string) {
        this.loginAPI = new CupuLoginNetworkAPI(this.baseUrl);
        this.soundAPI = new CupuSoundNetworkAPI(this.baseUrl);
        this.unitGroupAPI = new CupuUnitGroupNetworkAPI(this.baseUrl);
        this.unitsAPI = new CupuUnitsNetworkAPI(this.baseUrl);
        this.usersAPI = new CupuUsersNetworkAPI(this.baseUrl); 
        this.cupuTypesAPI = new CupuTypesNetworkAPIImpl(this.baseUrl);
        this.firmwareAPI = new CupuFirmwareNetworkAPI(this.baseUrl);
        this.logsAPI = new CupuLogsNetworkAPI(this.baseUrl);
        this.ordersAPI = new CupuOrdersNetworkAPI(this.baseUrl);
        this.manualsAPI = new CupuPDFManualsAPI(this.baseUrl);
    }

    getLoginAPI() { return this.loginAPI; }

    getSoundAPI() { return this.soundAPI; }

    getUnitGroupAPI() { return this.unitGroupAPI; }

    getUnitsAPI() { return this.unitsAPI; }

    geUsersAPI() { return this.usersAPI; }

    getCupuTypesAPI() { return this.cupuTypesAPI; }

    getFirmwareAPI() { return this.firmwareAPI; }

    getLogsAPI() { return this.logsAPI; }

    getOrdersAPI() { return this.ordersAPI; }

    getManualsAPI() { return this.manualsAPI}; 
}
