
export class FileHelper {

    static formatFileSize(size: number) {
        if(size < 1024) {
            return `${size} bytes`
        } else if(size < 1024 * 1024) {
            size = size / 1024;
            return `${size.toFixed(2)} KB`;
        } else if(size < 1024 * 1024 * 1024) {
            size = size / 1024 / 1024;
            return `${size.toFixed(2)} MB`
        } else {
            size = size / 1024 / 1024 / 1024;
            return `${size.toFixed(2)} GB`;
        }
    }
}
