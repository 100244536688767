import React from "react";
import UnitsFilter from "./UnitsFilter";
import ActionsDataList, { DataListAction } from "../forms/ActionsDataList";
import { Column } from "primereact/column";
import LoadingProgress from "../common/LoadingProgress";

import "./UnitsDataList.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchUnits } from "./UnitsReduxSlice";
import { NavigationHelper } from "../helpers/NavigationHelper";
import { NetworkManager } from "../network/NetworkManager";

const loginAPI = NetworkManager.getLoginNetworkAPI();


const UnitActions: DataListAction[] = [
    {name: "edit", label: "Detail", className: "p-button-secondary p-button-sm"},
];


const UnitsDataListTable = () => {

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUnits(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const unitsData = useSelector((state: any) => state.units.shownUnits);
    const userType = loginAPI.getLoggedUserType();

    const handleActionClick = (data: {name: string, id: any}) => {        
        switch(data.name) {
            case "edit": NavigationHelper.toAppUrl("/units/edit/" + data.id); break;
        }
    }

    return (
        <ActionsDataList value={unitsData} dataKey="SerialNo" actions={UnitActions} actionColumnWidth="7rem"
                       onAction={handleActionClick} stripedRows
                       virtualScrollerOptions={{itemSize: 85, delay: 0, showLoader: false}}  >
            <Column header="SerialNo" field="SerialNo" sortable style={{maxWidth: "16rem", textAlign: "left"}} />
            <Column header="Integrator SerialNo" field="IntegratorSerialNo" sortable style={{width: "18rem", textAlign: "left"}} />
            { userType === "Admin" ? (
                <Column header="HW Code" field="HardwareCode" sortable style={{maxWidth: "9rem"}} bodyStyle={{justifyContent: "center"}} />)
                : null
            }
            <Column header="Timestamp" field="StatusTimestamp" sortable style={{maxWidth: "15rem", minWidth: "15rem", justifyContent: "center"}} />
            <Column header="Status" field="StatusText" sortable style={{maxWidth: "7rem", minWidth: "7rem", justifyContent: "center"}}  />
            <Column header="Note" field="Note" sortable style={{width: "auto", textAlign: "left"}} />
        </ActionsDataList>
    );
}

const UnitsDataList = () => {
    const isLoading = useSelector((state: any) => state.units.loadingUnits);

    return (
        <div className="UnitDataList LoadingProgress--Wrapper">
            <LoadingProgress show={isLoading} />
            <UnitsFilter />
            <div className="UnitDataListHeader--Separator" />
            <UnitsDataListTable />
        </div>
    );
};

export default UnitsDataList;
