import React, { useState, useEffect } from "react";
import { useField, ErrorMessage } from "formik";
import _ from "lodash";

// Import Helper Components
import { PickList } from "primereact/picklist";
import { SearchBox } from "../../../common/AppSearchBox";
import PlaylistToggleButton from "./PlaylistToggleButton";

// Import FilterHelper
import { FilterHelper } from "../../../helpers/FilterHelper";

// Import Style
import "./PlaylistPickList.scss";

// Helper sound filtering method
const filterSounds = (sounds: any, phrase: string) => {
    return FilterHelper.filterRecordsUsingPhrase(phrase, sounds, ["Description"]);
}

const PlaylistPickList = (props: any) => {
    // Formik field
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [field, meta, helper ] = useField(props.name);   
    // Method tu update Formik State
    const updateFormikState = (playlists: any[]) => {
        helper.setTouched(true);
        helper.setValue(playlists);
    }

    // Define internal states
    const [searchPhrase, setSearchPhrase] = useState("");
    const [sounds, setSounds] = useState(props.sounds);
    const [filteredSounds, setFilteredSounds] = useState(props.sounds);
    const [playlists, setPlaylists] = useState(props.playlists);

    // Sound Filtering    
    const handleFilterSounds = (phrase: string) => {
        const filtSounds = filterSounds(sounds, phrase);
        setFilteredSounds(filtSounds);
        setSearchPhrase(phrase);
    }

    // Method for removal sounds present in playlist
    const removeSoundsPresentInPlaylists = (soundsInPlaylists: any) => {
        if(soundsInPlaylists === undefined) soundsInPlaylists = playlists;
        const soundsNotInPlaylist = props.sounds.filter((sound: any) => {
            return soundsInPlaylists.find((ps: any) => parseInt(ps.id) === parseInt(sound.id)) === undefined
        });
        setSounds(soundsNotInPlaylist);
        const soundsFilteredByPhrase = filterSounds(soundsNotInPlaylist, searchPhrase);
        setFilteredSounds(soundsFilteredByPhrase);
    }

    // Remove sounds in playlists from the source list
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => removeSoundsPresentInPlaylists(undefined), []); 

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => helper.setValue(playlists, false), []);

    // Method handling playlist change 
    const handleSoundPlaylistChanged = (playlistState: any) => {
        const newPlaylistConfig = _.cloneDeep(playlists);
        const soundToChange = newPlaylistConfig.find((p: any) => p.id === playlistState.id);
        soundToChange.InPlaylist1 = playlistState.InPlaylist1;
        soundToChange.InPlaylist2 = playlistState.InPlaylist2;
        setPlaylists(newPlaylistConfig);
        updateFormikState(newPlaylistConfig);
    }

    // Methods for moving sounds to and from playlist
    const addSoundsToPlaylist = (sounds: any[]) => {
        let playlistSounds = [];
        for(const sound of sounds) {
            const playlistSound = {
                id: sound.id,
                FileNo: sound.FileNo,
                FileName: sound.FileName,
                Description: sound.Description,
                InPlaylist1: null,
                InPlaylist2: null
            }
            playlistSounds.push(playlistSound);
        }
        const newPlaylists = playlists.concat(playlistSounds);
        setPlaylists(newPlaylists);
        updateFormikState(newPlaylists);
        removeSoundsPresentInPlaylists(newPlaylists);
    }

    const removeSoundsFromPlaylist = (sounds: any[]) => {
        let newPlaylist = playlists;
        for(const sound of sounds) {
            newPlaylist = newPlaylist.filter((s: any) => s.id !== sound.id);
        }
        setPlaylists(newPlaylist);
        updateFormikState(newPlaylist);
        removeSoundsPresentInPlaylists(newPlaylist);
    }


    // PickList Item and Sound Header SearchBox Template
    const PickListItemTemplate = (item: any) => {
        const AvailableSoundTemplate = (<div> {item.Description}</div>);
        const PlaylistTemplate = (
                <div className="TargetTemplate">
                    <PlaylistToggleButton playlist={item} 
                            onChange={(state: any) => handleSoundPlaylistChanged(state)} /> 
                    {item.Description} 
                </div>
            );

        return item.InPlaylist1 !== undefined || item.InPlaylist2 !== undefined ? 
                PlaylistTemplate : AvailableSoundTemplate;
    }  

    const SoundFilterHeader = () => {
        return (<SearchBox placeholder="Search sounds..." onChange={(value: string) => handleFilterSounds(value)} />);
    }


    return (
        <div className="PlaylistPickList" >
            <div>
                <div className="PlaylistPickList--ControlWrapper" >
                    <PickList showSourceControls={false} showTargetControls={false} id="id" 
                        sourceHeader={SoundFilterHeader} targetHeader="Playlists" 
                        source={filteredSounds} target={playlists} itemTemplate={PickListItemTemplate}  

                        onMoveToSource={(e) => removeSoundsFromPlaylist(e.value)}
                        onMoveAllToSource={(e) => removeSoundsFromPlaylist(e.value)}
                        onMoveToTarget={(e) => addSoundsToPlaylist(e.value)}
                        onMoveAllToTarget={(e) => addSoundsToPlaylist(e.value)} />

                </div>
            </div>
            <div className="Form--FormField--ErrorMessage--Wrapper">
                <div className="Form--FormField--ErrorMessage">
                    <ErrorMessage name={props.name} component="div"  ></ErrorMessage>
                </div>
            </div>
        </div>                
    );
}

export default PlaylistPickList;
