import type { ApiLogFetchOptions, LogsNetworkAPI } from "../NetworkInterfaces";
import { RestrictAccess } from "../NetworkSecurity";
import { CupuNetworkAPIBase } from "./CupuNetworkAPIBase";

export class CupuLogsNetworkAPI extends CupuNetworkAPIBase implements LogsNetworkAPI {

    @RestrictAccess(["Admin"])
    async queryApiLogCount(serialNo: string): Promise<number> {
        const requestData = {};
        const ajaxRequest = super.createAjaxRequest("GET", `/WebApi/apilog/${serialNo}/count`, requestData, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        return parseInt(response.result.api_request);
    }

    @RestrictAccess(["Admin"])
    async fetchApiLogs(serialNo: string, options: ApiLogFetchOptions): Promise<any[]> {
        const requestData = {};
        const url = `/WebApi/apilog/${serialNo}?skip=${options.skip}&take=${options.take}`;
        const ajaxRequest = super.createAjaxRequest("GET", url, requestData, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        return response.result.api_request;
    }

    @RestrictAccess(["Admin"])
    async fetchApiLogsLast30Days(serialNo: string): Promise<any[]> {
        const requestData = {SerialNo: serialNo};
        const ajaxRequest = super.createAjaxRequest("POST", "units/get_api_log_for_last_30_days", requestData, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        if(response.result === "RESPONSE_ERROR") return [];
        else return response.log_data;
    }

    @RestrictAccess(["Admin"])
    async fetchServiceLogs(serialNo: string): Promise<any[]> {
        const requestData = {};
        const url = `/WebApi/servicelog/${serialNo}`;
        const ajaxRequest = super.createAjaxRequest("GET", url, requestData, "json");
        const response = await super.callAjax(ajaxRequest, "Bypass");
        return response.response.service_request;
    }

    @RestrictAccess(["Admin"])
    async saveServiceEvent(data: any): Promise<void> {
        const requestData = {...data};
        const ajaxRequest = super.createAjaxRequest("POST", "units/save_service_event", requestData, "json");
        await super.callAjax(ajaxRequest);
    }
}
