
export function process_log_data_from_server(data_from_server) {
		var number_of_entry = [];
		var categories = [];
		var data_processed = [];

		for (var i=0; i < data_from_server.length; i++) {
            var a_log_entry = data_from_server[i];

			var date = new Date(a_log_entry['Timestamp']);
			date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
			var recordDate = new Date(date);
			
			var z = 1;
			while (i < (data_from_server.length - 1) && recordDate.getDate() == date.getDate() && recordDate.getMonth() == date.getMonth()) {
				i++;
				if (a_log_entry = data_from_server[i]) {
					recordDate = new Date(a_log_entry['Timestamp']);
					if (recordDate.getDate() == date.getDate() && recordDate.getMonth() == date.getMonth()) z++;
					else i--;
				}
			}
			number_of_entry.push(z);
			categories.push(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
        }
        data_processed.push(categories);
        data_processed.push(number_of_entry);
        return data_processed;
};


export function draw_log_chart(grahId, log_data) {
        window.$('#' + grahId).highcharts({
            chart: {
				type: 'column',
                zoomType: 'x'
            },
            title: {
                text: log_data.title_text,
            },
            subtitle: {
                text: log_data.subtitle_text,
            },
            xAxis: {
               title: {
                  text: 'Date',
                  align: 'high'
               },
			   categories: log_data.log_records[0],
               type: 'string',
			   reversed: false
            },
            yAxis: {
              min: 0,
               title: {
                  text: 'Number of log entries',
                  align: 'high'
               },
               labels: {
                  overflow: 'justify'
               }
            },
            tooltip: {
                valueSuffix: ''
            },
            legend: {
                layout: 'vertical',
                align: 'right',
                verticalAlign: 'middle',
                borderWidth: 0
            },
            series: [{
                    name: 'Numbers',
                    data: log_data.log_records[1]
                }
            ],
            plotOptions: {
               bar: {
                  dataLabels: {
                     enabled: true
                  }
               }
            },
        });
};
